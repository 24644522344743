export interface OrderHeaderLoadData {
    orderId: string;
    orderLock: boolean;
    recordCount: number;
    lockedBy: string;
    lockedDateTime: string;
    localLockedDateTime: Date;
    localLockExpiryDateTime: Date
    lockExpiryDateTime: string;
    lockOrganisationName: string;
    orderSalesStatusCode: string;
    orderTypeCode: string;
}