import { OrderCancelItemType } from "..";

export interface OrderCancelItemView {
    display: string;
    orderSalesStatus: string;
    orderPaymentStatus: string;
    orderDeliveryStatus: string;
    currency: string;
    currencyCode?: string;
    salesAmount: string;
    cancellationFeeAmount: string;
    refundableAmount: string;
    nonRefundableAmount: string;
    orderId: string;
    parentOrderId: string;
    icon: string;
    hierarchyId: string;
    parentHierarchyId: string;
    referenceOrderId: string;
    isIndividual: boolean;
    selectable: boolean;
    numberOfUnits: number;
    type: OrderCancelItemType;
    deleteFlag: boolean;
    organisation: string;
    reference: string;
    paymentAmount: string;
}
