export interface ProductRestrictionVehicleViewModel {
    productRestrictionVehicleId?: string;
    excludeFlag?: boolean;
    displaySequence?: number;
    vehicleTypeCode: string;
    vehicleGroupCode: string;
    vehicleCompositionId?: string;
    commitLastname: string;
    commitFirstname: string;
    commitTitle: string;
    commitDateTime: Date;
    hierarchyKey: string;
    inherit: boolean;
}