import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ResizedEvent } from 'angular-resize-event-package';
import { BehaviorSubject } from 'rxjs';
import { OopsComponentFormBase } from 'src/app/core/base/oops-component-form-base';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeGroupReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/attribute-group-reference.model';
import { DimensionUnitReferenceModel } from 'src/app/core/models/reference-model/reference-general-model/dimension-unit-reference.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { AttributeGroupReferenceService } from 'src/app/core/services/system-services/attribute-group-reference.service';
import { AttributeMaintenanceService } from 'src/app/core/services/system-services/attribute-maintenance.service';
import { DimensionUnitReferenceService } from 'src/app/core/services/system-services/dimension-unit-reference.service';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { StringUtils } from 'src/app/shared/utils/string-utils';
import { MapperService } from '../../shared/mapper.service';
import { select2Status } from '../../shared/select2-configuration';
import { AttributeDetailView } from '../../shared/view/attribute-detail-view.view';
import { DomainAttributeView } from '../../shared/view/domain-attribute.view';

@Component({
  selector: 'op-attribute-detail-form',
  templateUrl: './attribute-detail-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
      MapperService
  ]
})
export class AttributeDetailFormComponent extends OopsComponentFormBase implements OnChanges, AfterViewInit, OnDestroy {
    private readonly DASH = '-';
    private readonly CHOICE_DIMENSION_CODE = 'CHOICE';

    @Input() attributeCode: string = "";
    @Input() attributeName: string = "";
    @Input() attributeDetail: AttributeDetailView;
    @Input() statusReference: StatusReferenceModel[];
    @Input() domainAttributeFlag: boolean = false;
    @Input() newAttributeFlag: boolean = true;
    @Input() domainName: string;
    @Input() domainCode: string;
    @Input() domainAttributeList: DomainAttributeView[] = [];
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() priceSelected = false;
    @Input() transportProductSeleted = false;
    @Output() onPanelHeightChange = new EventEmitter<Number>();
    @Output() attributeNameChange = new EventEmitter<string>();
    @Output() dimensionIsChoice = new EventEmitter<boolean>();
    @Output() attributeCodeChange = new EventEmitter<string>();
    
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;
    @ViewChild('attributeDetailPanel') attributeDetailPanel: ElementRef;

    public focused: boolean;
    public statusOption: any;

    public dimensionUnitReference$ = new BehaviorSubject<DimensionUnitReferenceModel[]>(null);
    public attributeGroupReference$ = new BehaviorSubject<AttributeGroupReferenceModel[]>(null);
    public dimensionChoice = false;
    public domainAttributeDetail: DomainAttributeView[] = [];
    public firstSetup = true;
    public newDomainAttributeDetail : DomainAttributeView[] = [];
    public editDomainAttributeDetail : DomainAttributeView[] = [];
    public deleteDomainAttributeDetail : string[] = [];

    constructor(private formBuilder: UntypedFormBuilder,
        private dimensionUnitReferenceService: DimensionUnitReferenceService,
        private attributeGroupReferenceService: AttributeGroupReferenceService,
        private dateConverterService: DateConverterService,
        private mapper: MapperService,
        public focusingService: FocusingService,
        private changeDetectionRef: ChangeDetectorRef,
        private attributeMaintenanceService: AttributeMaintenanceService,
        private stringUtils: StringUtils) {
        super(formBuilder);
        this.setOptionControl();
        this.initForm();
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['attributeDetail']) {
            this.formSetup();
        }
        if (changes['domainCode']) {
            this.diplayDetail(this.domainCode);
        }
        if (changes['domainAttributeList']) {
            this.domainAttributeDetail = this.domainAttributeList ?? [];
        }
        this.disableNameAndCode(this.newAttributeFlag, this.domainAttributeFlag); 
        this.overrideChanged(this.formGroup.controls['override'].value);
        this.checkStatusRequired()
    }

    displayNewDomainAttribute(detailTemp: AttributeDetailView) {
        if (detailTemp != null) {
            this.fillExistDomainAttributeToForm();
        }
        else {
            this.formSetup();
        }
    }
    
    public initForm() {
        this.getDimensionUnitReference();
        this.getAttributeGroupReference();
        this.formSetup();
    }

    ngAfterViewInit(): void {
        this.onPanelHeightChange.emit(this.attributeDetailPanel.nativeElement.offsetHeight);
        this.focusForm();
    }

    private formSetup() {
        this.formGroup = this.formBuilder.group({
            attributeCode: [null, Validators.required],
            attributeName: [null, Validators.required],
            dimensionType: [null],
            override: [null],
            overridable: this.formBuilder.group({
                showOnNew: [false],
                multipleChoice: [false],
                requiredAttribute: [false],
                dimensionAttribute: [false],
                startAttribute: [false],
                endAttribute: [false],
                productAttribute: [false],
                scheduleAttribute: [false],
                operationAttribute: [false],
                searchFlag: [false],
                minimumValue: [null],
                maximumValue: [null],
                defaultValue: [null]
            }),
            transportProductSelected: this.formBuilder.group({
                startAttribute: [false],
                endAttribute: [false],
                productAttribute: [false],
                scheduleAttribute: [false],
                operationAttribute: [false],
            }),
            attributeGroupCode: [null],
            statusCode: ['A', Validators.required],
            commitDateTime: [{value: null, disabled: true}],
            commitByName: [{value: null, disabled: true}],
            readOnlyFlag: [null]
        })
        if (!this.attributeDetail) {
            return;
        }
        else {
            this.fillViewToForm(this.attributeDetail);
        }
    }

    fillViewToForm(view: AttributeDetailView) {
        if (view) {
            this.formGroup.patchValue({
                attributeCode: this.attributeCode ?? view.attributeCode,
                attributeName: this.attributeName ?? view.attributeName,
                dimensionType: view?.dimensionUnitCode ?? null,
                override: view?.override ?? false,
                overridable: {
                    showOnNew: view?.showOnNew ?? false,
                    multipleChoice: view?.multipleChoice ?? false,
                    requiredAttribute: view?.requiredAttribute ?? false,
                    dimensionAttribute: view?.dimensionAttribute ?? false,
                    searchFlag: view?.searchFlag ?? false,
                    minimumValue: view?.minimumValue ?? null,
                    maximumValue: view?.maximumValue ?? null,
                    defaultValue: view?.defaultValue ?? null,
                },
                transportProductSelected : {
                    startAttribute: view?.startAttribute ?? false,
                    endAttribute: view?.endAttribute ?? false,
                    productAttribute: view?.productAttribute ?? false,
                    scheduleAttribute: view?.scheduleAttribute ?? false,
                    operationAttribute: view?.operationAttribute ?? false,         
                },
                attributeGroupCode: view?.attributeGroupCode ?? null,
                statusCode: view?.statusCode,
                commitDateTime: this.dateConverterService.convertDate(view?.commitDateTime),
                commitByName: view?.commitByName,
                readOnlyFlag: view.readOnlyFlag ?? false
            })
            this.changeDetectionRef.detectChanges();
        }
    }

    private getDimensionUnitReference() {
        this.dimensionUnitReferenceService.getDimensionUnitReference()
        .subscribe(
            (response: DimensionUnitReferenceModel[]) => {
            this.dimensionUnitReference$.next(response);
            }
        );
    }

    private getAttributeGroupReference() {
        this.attributeGroupReferenceService.getAttributeGroupReference()
        .subscribe(
            (response: AttributeGroupReferenceModel[]) => {
            this.attributeGroupReference$.next(response);
            }
        )
    }

    public onAttributeNameChange(event) {
        this.attributeNameChange.emit(event);
    }

    public onAttributeCodeChange(event) {
        let cursorPosition = event.target.selectionStart;
        let newCode: string = this.formGroup.get('attributeCode').value.replace(/\s/g, '').toUpperCase();
        if (newCode != null) {
            this.formGroup.get('attributeCode').patchValue(newCode);
            this.checkDuplicateCode(newCode);
            this.attributeCodeChange.emit(newCode);
        }
        this.assignCursorPosition(event, cursorPosition);
        this.checkAttributeCodeError(newCode);
        this.changeDetectionRef.detectChanges();
    }

    private assignCursorPosition(event, cursorPosition: number) {
        if (event.keyCode == 32) {
            event.target.selectionEnd = cursorPosition - 1;
            return;
        }
        event.target.selectionEnd = cursorPosition;
    }

    private checkAttributeCodeError(newCode: string) {
        if (this.stringUtils.validateSpecialCharacterExcept(newCode, '-')) {
            this.formGroup.controls['attributeCode'].setErrors({specialCharacter: true});
        } else if (newCode.startsWith(this.DASH) || newCode.endsWith(this.DASH)) {
            this.formGroup.controls['attributeCode'].setErrors({dash: true});
        }
    }

    public valueChange(value, name) {
        this.formGroup.get(name).setValue(value);
        if (name == "dimensionType") {
            this.onDimensionTypeChange(value);
        } 
    }

    private onDimensionTypeChange(value: string) {
        if (value == this.CHOICE_DIMENSION_CODE) {
            this.dimensionChoice = true;
        } else {
            this.dimensionChoice = false;
        }
        this.dimensionIsChoice.emit(this.dimensionChoice);
    }

    private setOptionControl() {
        this.statusOption = select2Status;
    }

    public checkValid(): boolean {
        this.startProcessing();
        if (!this.validForm()) {
            this.focusForm();
            return false;
        }
        this.completeProcessing();
        return true;
        
    }

    public fillDetailToAttributeDetailView(): AttributeDetailView {
        let formValue = this.formGroup.getRawValue();
        let view: AttributeDetailView = {
            attributeCode: formValue.attributeCode ?? this.attributeCode,
            attributeName: formValue.attributeName ?? this.attributeName,
            dimensionUnitCode: formValue.dimensionType ?? null,
            attributeGroupCode: formValue.attributeGroupCode ?? null,
            override: formValue.override ?? false,
            showOnNew: this.formGroup.get('overridable.showOnNew').value ?? false,
            multipleChoice: this.formGroup.get('overridable.multipleChoice').value ?? false,
            requiredAttribute: this.formGroup.get('overridable.requiredAttribute').value ?? false,
            dimensionAttribute: this.formGroup.get('overridable.dimensionAttribute').value ?? false,
            startAttribute: this.formGroup.get('transportProductSelected.startAttribute').value ?? false,
            endAttribute: this.formGroup.get('transportProductSelected.endAttribute').value ?? false,
            productAttribute: this.formGroup.get('transportProductSelected.productAttribute').value ?? false,
            scheduleAttribute: this.formGroup.get('transportProductSelected.scheduleAttribute').value ?? false,
            operationAttribute: this.formGroup.get('transportProductSelected.operationAttribute').value ?? false,
            searchFlag: this.formGroup.get('overridable.searchFlag').value ?? false,
            minimumValue: this.formGroup.get('overridable.minimumValue').value ?? null,
            maximumValue: this.formGroup.get('overridable.maximumValue').value ?? null,
            defaultValue: this.formGroup.get('overridable.defaultValue').value ?? null,
            commitBy: null,
            commitByName: formValue.commitByName,
            commitDateTime: new Date(formValue.commitDateTime),
            domainCode: this.domainCode,
            domainTypeCode: null,
            sortSequence: null,
            statusCode: formValue.statusCode ?? null,
            readOnlyFlag: formValue.readOnlyFlag ?? false
        }
        return view;
    }

    overrideChanged(checked: boolean = false) {
        if (checked == false && this.domainAttributeFlag == true) {
            this.overrideIsUnChecked();
        }
        else {
            this.overrideIsChecked();
        }
        this.updateCheckBoxDisable();
        this.updateDimensionCheckbox(checked);
    }

    overrideIsChecked() {
        this.formGroup.controls['overridable'].enable();
    }

    overrideIsUnChecked() {
        this.formGroup.controls['overridable'].disable();
        this.clearOverride();
    }

    clearOverride() {
        this.formGroup.controls['overridable'].patchValue({
            showOnNew: false,
            multipleChoice: false,
            requiredAttribute: false,
            dimensionAttribute: false,
            searchFlag: false,
            minimumValue: null,
            maximumValue: null,
            defaultValue: null
        });
    }

    disableNameAndCode(newAttributeFlag: boolean, domainAttributeFlag: boolean) {
        this.formGroup.controls['attributeName'].enable();
        this.formGroup.controls['attributeCode'].enable();

        if (newAttributeFlag == false) {
            if (domainAttributeFlag == true) {
                this.formGroup.controls['attributeName'].disable();
                this.formGroup.controls['attributeCode'].disable();
            }
            else if (domainAttributeFlag == false) {
                this.formGroup.controls['attributeCode'].disable();
            }
        }
        else {
            if (domainAttributeFlag == true) {
                this.formGroup.controls['attributeName'].disable();
                this.formGroup.controls['attributeCode'].disable();
            }
        }
    }

    public clearFormForDomainAttribute() {
        this.formGroup.patchValue({
            override: false,
            commitByName: null,
            commitDateTime: null
        });
        this.clearOverride();
    }

    public focusForm() {
        this.attributeDetailPanel.nativeElement.focus();
        this.focusingService.focus(this.focusingDirective);
    }

    public addDomainAttribute(domainAttribute: AttributeDetailView) {
        let domainAttributeTemp = this.mapper.AttributeDetailViewToDomainAttribute(domainAttribute);
        this.addToNewOrEdit(domainAttributeTemp);
        let domainIndex = this.domainAttributeDetail?.findIndex(item => item.domainCode == domainAttribute.domainCode);
        if (domainIndex == -1) {
            this.domainAttributeDetail.push(domainAttributeTemp);
        }
        else {
            this.domainAttributeDetail[domainIndex] = domainAttributeTemp;
        }
        
    }

    public addToNewOrEdit(domainAttribute: DomainAttributeView) {
        let index = this.domainAttributeList?.findIndex(item => item.domainCode == domainAttribute.domainCode) ?? -1;
        if (index == -1) {
            this.addNewDomainAttribute(domainAttribute);
        } else {
            this.addEditDomainAttribute(domainAttribute);
        }
    }

    public addNewDomainAttribute(domainAttribute: DomainAttributeView) {
        let domainIndex = this.newDomainAttributeDetail.findIndex(item => item.domainCode == domainAttribute.domainCode);
        if (domainIndex > -1) {
            this.newDomainAttributeDetail[domainIndex] = domainAttribute;
        } else {
            this.newDomainAttributeDetail.push(domainAttribute);
        }
    }

    public addEditDomainAttribute(domainAttribute: DomainAttributeView) {
        let domainIndex = this.editDomainAttributeDetail.findIndex(item => item.domainCode == domainAttribute.domainCode);
        if (domainIndex > -1) {
            this.editDomainAttributeDetail[domainIndex] = domainAttribute; 
        } else {
            this.editDomainAttributeDetail.push(domainAttribute);  
        }
    }

    public fillExistDomainAttributeToForm() {
        let domainIndex = this.domainAttributeDetail.findIndex(item => item.domainCode == this.domainCode);
        if (this.checkDomainAttributeAlreadyExist(this.domainCode)) {
            let domainAttributeTemp = this.mapper.domainAttributeViewToAttributeDetailView(this.domainAttributeDetail[domainIndex]);
            this.fillViewToForm(domainAttributeTemp);
        }
        else {
            this.fillViewToForm(this.attributeDetail);
            this.clearFormForDomainAttribute();
        }
    }

    checkDomainAttributeAlreadyExist(domainCode: string) {
        return this.domainAttributeDetail.findIndex(item => item.domainCode == domainCode) != -1;
    }

    public updateAttributeDetail(newAttributeDetail: AttributeDetailView) {
        this.attributeDetail = newAttributeDetail;
        this.attributeCode = newAttributeDetail.attributeCode;
        this.attributeName = newAttributeDetail.attributeName;
    }

    public checkDomainAttribute() {
        if (this.domainCode == "") {
            this.domainAttributeFlag = false;
        }
        else {
            this.domainAttributeFlag = true;
        }
    }

    displayAttributeDetail() {
        this.domainCode = '';
        this.domainName = ''; 
    }

    getEditedDetail(): DomainAttributeView[] {
        return this.editDomainAttributeDetail;
    }

    getNewDetail(): DomainAttributeView[] {
        return this.newDomainAttributeDetail;
    }

    getAttrubuteTypeDetail(): AttributeDetailView {
        return this.attributeDetail;
    }

    checkAttributeCodeAndName(): boolean {
        if (this.formGroup.controls['attributeName'].value == null || this.formGroup.controls['attributeCode'].value == null) {
            return false;
        }
        return true;
    }

    onResized(event: ResizedEvent) {
        this.onPanelHeightChange.emit(event.newRect.height);
    }
    
    clearPendingData() {
        this.editDomainAttributeDetail = [];
        this.newDomainAttributeDetail = [];
        this.domainAttributeList = this.domainAttributeDetail;
        this.newAttributeFlag = false;
    }
    
    checkValue() {
        let minValue = this.formGroup.get('overridable.minimumValue').value;
        let maxValue = this.formGroup.get('overridable.maximumValue').value;
        let defaultValue = this.formGroup.get('overridable.defaultValue').value;

        if (minValue != null) {
            this.checkMinValue(minValue, maxValue);
        }
        if (maxValue != null) {
            this.checkMaxValue(minValue, maxValue);
        }
        if (defaultValue != null) {
            this.checkDefaultValue(minValue, maxValue, defaultValue);
        }
        
    }

    checkMinValue(minValue: number, maxValue: number) {
        if (maxValue < minValue && maxValue != null) {
            this.formGroup.get('overridable.minimumValue').setErrors({higherThanMax: true})
        } else {
            this.formGroup.get('overridable.minimumValue').setErrors(null)
        }
    }

    checkMaxValue(minValue: number, maxValue: number) {
        if (maxValue < minValue && minValue != null) {
            this.formGroup.get('overridable.maximumValue').setErrors({lowerThanMin: true})
        } else {
            this.formGroup.get('overridable.maximumValue').setErrors(null)
        }
    }

    checkDefaultValue(minValue: number, maxValue: number, defaultValue: number) {       
        if ((maxValue < defaultValue || minValue > defaultValue) && (minValue != null || maxValue != null)) {
            this.formGroup.get('overridable.defaultValue').setErrors({notInRange: true});
        } else {
            this.formGroup.get('overridable.defaultValue').setErrors(null);
        }
    }

    detechChange() {
        this.changeDetectionRef.detectChanges();
    }

    checkDuplicateCode(code: string) {
        this.attributeMaintenanceService.getAttributeByCode(code)
            .subscribe(
                (response) => {
                    if (response) {
                        this.formGroup.get('attributeCode').setErrors({duplicate: true});
                        this.formGroup.get('attributeCode').markAsTouched();
                        this.changeDetectionRef.detectChanges();
                    }
                }
            )
    }

    updateCheckBoxDisable() {
        if (this.transportProductSeleted == true) {
            this.formGroup.controls['transportProductSelected'].enable();
        } else {
            this.formGroup.controls['transportProductSelected'].disable();
        }
    }

    diplayDetail(domainCode: string) {
        this.checkStatusRequired();
        if (domainCode != ""){
            this.fillExistDomainAttributeToForm();
        }
        else {
            this.fillViewToForm(this.attributeDetail);
        }  
    }

    checkStatusRequired() {
        if (this.domainAttributeFlag == true) {
            this.formGroup.controls['statusCode'].clearValidators();
        } else {
            this.formGroup.controls['statusCode'].setValidators([Validators.required]);
        }
        this.formGroup.controls['statusCode'].updateValueAndValidity();
    }

    updateDimensionCheckbox(override: boolean) {
        if (this.priceSelected == true && override == true) {
            this.formGroup.get('overridable.dimensionAttribute').enable();
        } else {
            this.formGroup.get('overridable.dimensionAttribute').disable();
        }
    }

    checkDimensionChoiceDisable(dimensionTypeCode: string) {
        if (dimensionTypeCode != this.CHOICE_DIMENSION_CODE) {
            this.formGroup.controls['dimensionType'].enable();
            return;
        }
        this.formGroup.controls['dimensionType'].disable();
    }

    resetForm() {
        this.formGroup.controls['attributeName'].enable();
        this.formGroup.controls['attributeCode'].enable();
        this.formGroup.controls['dimensionType'].enable()

        this.formGroup.controls['attributeCode'].setErrors(null);
        this.formGroup.controls['attributeName'].setErrors(null);
        this.formGroup.controls['statusCode'].setErrors(null);
        
        this.resetToDefaultForm();
    }

    resetToDefaultForm() {
        this.formGroup.reset();
    }

    ngOnDestroy(): void {
        this.resetToDefaultForm();
    }

}
