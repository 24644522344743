import { Component, Input, OnInit } from '@angular/core';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { ActionService } from 'src/app/core/utils/action.service';

@Component({
    selector: 'op-news-tabs',
    templateUrl: './news-tabs.component.html',
})
export class NewsTabsComponent implements OnInit {
    @Input() selectedTab: string;
    @Input() userSecurity: SecurityGroupSecurityModel;

    constructor(private actionService: ActionService) { }

    ngOnInit(): void {
        this.addAction(this.selectedTab);
    }

    onActiveIdChange(activeId: string) {
        this.addAction(activeId);
    }

    addAction(actionCode: string) {
        if (actionCode != 'CATEGORY') {
            this.actionService.add(actionCode, null, null);
        } 
    }

}
