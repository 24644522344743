
export interface PaymentSearchCriteria {
    organisationId?: string
    paymentNumber?: string
    documentNumber?: string
    formOfPaymentTypeCode?: string
    formOfPaymentCode?: string
    paymentCurrencyCode?: string
    paymentDateFrom?: string
    paymentDateTo?: string
    paymentStatus?: string
    includeLedger: boolean
    topNumber?: string
}

export interface PaymentSearchCriteria2 {
    paymentOrganisationId?: string
    orderNumber?: string
    paymentIndividualName?: string
    paymentIndividualMail?: string
    voidDateFrom?: string
    voidDateTo?: string
    issuedDateFrom?: string
    issuedDateTo?: string
    payment?: string
    balance?: string
    void?: string
}

export interface PaymentSearch {
    paymentId: string
    paymentOrganisationId?: string
    paymentIndividualId?: string
    paymentNumberDisplay: string
    organisationId: string
    organisationCallName: string
    orderNumber: string
    formOfPaymentTypeName: string
    formOfPaymentName: string
    documentNumberDisplay: string
    externalReference: string
    paymentDateTime?: string
    issueDateTime?: string
    voidDateTime?: string
    transactionCurrencyCode: string
    transactionAmount: number
    paymentStatusCode: string
    paymentStatusName: string
    ledgerTransactionCode: string
    orderAmount: number
    balanceAmount: number
    isDocumentInvoicePayment: boolean
    isCreditCardPayment: boolean
    isCreditAccountPayment: boolean
    isOrganisationPayment: boolean
    isIndividualPayment: boolean
    paymentActions: PaymentActions
    paymentSearchLedgers: PaymentSearchLedger[]
}

export interface PaymentActions {
    canPay: boolean
    canVoid: boolean
    canCancel: boolean
    canReverse: boolean
    canUndo: boolean
}

export interface PaymentSearchLedger {
    paymentId: string
    ledgerId: string
    referenceOrderId: string
    productCategoryName: string
    productGroupName: string
    productTypeName: string
    orderNumber: string
    transactionCurrencyCode: string
    ledgerTransactionCode: string
    transactionAmount: number
    salesCurrencyCode: string
    salesAmount: number
    isCreditBalance: boolean
    isDebitBalance: boolean
    transactionDateTime?: string
    voidDateTime?: string
}

export interface OrganisationPayment {
    organisationId: string
    organisationCallName: string
}
