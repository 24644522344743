import { OperatorModel } from './operator.model';
import { LocationPointModel } from './locationpoint.model';
import { AreaModel } from './area.model';
import { ODModel } from './od.model';
import { OrgTypeModel } from './orgtype.model';

export interface TypeModel {
    typeCode: string;
    typeName: string;
    operators: OperatorModel[];
    locationPoint?: LocationPointModel[];
    areas?: AreaModel[];
    ods?: ODModel[];
    orgType?: OrgTypeModel[];
}