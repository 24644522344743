import { Directive, OnInit, AfterViewInit, ElementRef, Input, Output, EventEmitter, OnChanges, SimpleChange, ChangeDetectorRef, HostListener } from '@angular/core';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import * as moment from 'moment';

declare var $: any;

@Directive({
    selector: '[daterangepicker]'
})
export class DaterangepickerDirective implements OnInit, AfterViewInit, OnChanges {

    private readonly daterangepickerName = 'daterangepicker';
    private readonly date = 'DATE';
    private readonly month = 'MONTH';
    private readonly MODULE_SHOPPING = 'SHOPPING';
    private readonly MODULE_PAYMENT = 'PAYMENT';

    private _dateTimeFormat: string = "YYYY-MM-DD hh:mm A";
    private _option: any;
    private _startDateTime: string;
    private _endDateTime: string;
    private _showTimeOnly: boolean = false;

    @Input()
    set startDateTime(value: string) {
        this._startDateTime = value;
        this.setSelectedValue(this._startDateTime, this._endDateTime);
    }

    @Output()
    startDateTimeChange: EventEmitter<any> = new EventEmitter();

    @Input()
    set endDateTime(value: string) {
        this._endDateTime = value;
        this.setSelectedValue(this._startDateTime, this._endDateTime);
    }

    @Output()
    endDateTimeChange: EventEmitter<any> = new EventEmitter();

    @Input()
    set option(value: any) {
        this._option = value;
        if (this._option != null) {
            this._dateTimeFormat = this._option.locale.format;
        }
    }

    @Input()
    set showTimeOnly(value: any) {
        this._showTimeOnly = value;
    }

    @Input()
    set dateTimeFormat(value: any) {
        this._dateTimeFormat = value
    }

    @Input() module: string;
    @Input() calendarMidRange: boolean = false;
    @Input() openToDate: Date;
    @Output() calendarPageChange = new EventEmitter<Date>();

    constructor(private element: ElementRef,
        private cdr: ChangeDetectorRef,
        private dateConverterService: DateConverterService) {

    }

    ngAfterViewInit(): void {
        const applyPickerEventName: string = "apply.daterangepicker";
        const cancelPicketEventName: string = "cancel.daterangepicker"

        $(this.element.nativeElement).on(applyPickerEventName, (ev, picker) => {
            this.startDateTimeChange.emit(picker.startDate.format(this._dateTimeFormat));
            this.endDateTimeChange.emit(picker.endDate.format(this._dateTimeFormat))
        });
        $(this.element.nativeElement).on(cancelPicketEventName, (ev, picker) => {
            $(this.element.nativeElement).val('');
            this.startDateTimeChange.emit(null);
            this.endDateTimeChange.emit(null);
        });
        $(this.element.nativeElement).on('hide.daterangepicker', (ev, picker) => {
            this.startDateTimeChange.emit(picker.startDate.format(this._dateTimeFormat));
            this.endDateTimeChange.emit(picker.endDate.format(this._dateTimeFormat))
        })
        this.setSelectedValue(this._startDateTime, this._endDateTime);
    }

    ngOnInit(): void {
        this.initializeDateRangePicker(this._option);
    }

    ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
        if (changes['option']) {
            this.initializeDateRangePicker(this._option);
        }
        if (this._option) {
            if (this._option.minDate) {
                this.initializeDateRangePicker(this._option);
            }
        }
    }

    private initializeDateRangePicker(option: any): void {
        $(this.element.nativeElement).daterangepicker(option);
        this.setShowTimeOnly();
    }

    private setSelectedValue(startDateTime: string, endDateTime: string) {

        if ((!startDateTime || startDateTime == null)
            && (!endDateTime || endDateTime == null)) {
            $(this.element.nativeElement).val('');
            this.defaultTodayDate();
            return;
        }

        if (startDateTime != null && startDateTime.length > 0) {
            this.setStartDateTime(startDateTime);
        }

        this.setEndDateTime(endDateTime);
        if (endDateTime != null && endDateTime.length > 0) {
            this.setEndDateTime(endDateTime);
        }
    }

    private setStartDateTime(value: string) {
        let dataElement = $(this.element.nativeElement).data(this.daterangepickerName)
        if (!value || !dataElement) {
            return;
        }
        dataElement.setStartDate(value);
    }

    private setEndDateTime(value: string) {
        let dataElement = $(this.element.nativeElement).data(this.daterangepickerName)
        if (!value || !dataElement) {
            return;
        }
        dataElement.setEndDate(value);
    }

    private setShowTimeOnly() {
        const showPickerEventName: string = "show.daterangepicker";

        const applyPickerEventName: string = "apply.daterangepicker";
        const cancelPicketEventName: string = "cancel.daterangepicker"

        $(this.element.nativeElement).on(applyPickerEventName, (ev, picker) => {
            this.startDateTimeChange.emit(picker.startDate.format(this._dateTimeFormat));
            this.endDateTimeChange.emit(picker.endDate.format(this._dateTimeFormat))
        });
        $(this.element.nativeElement).on(cancelPicketEventName, (ev, picker) => {
            $(this.element.nativeElement).val('');
            this.startDateTimeChange.emit(null);
            this.endDateTimeChange.emit(null);
        });
        this.setSelectedValue(this._startDateTime, this._endDateTime);

        if (this._showTimeOnly) {
            $(this.element.nativeElement).on(showPickerEventName, function (ev, picker) {
                picker.container.find(".calendar-table").hide();
            }).on('showCalendar.daterangepicker', function (ev, picker) {
                picker.container.find('.calendar-table').remove();
            });
        }

        $(this.element.nativeElement).on("keydown", (ev, picker) => {
            if (ev.keyCode == 8 || ev.keyCode == 46) {
                $(this.element.nativeElement).val('');
                this.startDateTimeChange.emit(null);
                this.endDateTimeChange.emit(null);
                this.initializeDateRangePicker(this._option);
            }
        });

        $(this.element.nativeElement).on('showCalendar.daterangepicker', (ev, picker) => {
            const monthselect = $(picker.container).find('.left').find('.monthselect');
            const yearselect = $(picker.container).find('.left').find('.yearselect');
            this.calendarPageChange.emit(new Date(Date.UTC(yearselect.val(), monthselect.val(), 1)))
        })
    }

    onInputChange(event) {
        if (!event) {
            $(this.element.nativeElement).val(event);
            return;
        }
        let newVal = event.replace(/\D/g, '');
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 4) {
            newVal = newVal.replace(/^(\d{1,4})/, '$1');
        } else if (newVal.length <= 6) {
            newVal = this.checkFormatDate(newVal);
            newVal = newVal.replace(/^(\d{0,4})(\d{0,2})/, '$1-$2');
        } else if (newVal.length <= 8) {
            newVal = this.checkFormatDate(newVal);
            newVal = newVal.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3');
        } else {
            newVal = this.checkFormatDate(newVal);
            newVal = newVal.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3');
        }
        newVal = newVal.replace(/\d$/, '');
        $(this.element.nativeElement).val(newVal);
    }

    checkFormatDate(newVal) {
        let checkFormatDate = false;
        if (newVal.length == 5 || newVal.length == 6) {
            let val = newVal.replace(/^(\d{0,4})(\d{0,2})/, '$1-$2');
            checkFormatDate = this.dateConverterService.checkDateFormat(val, this.month);
            if (!checkFormatDate) {
                newVal = newVal.substring(0, 4)
            }

        }

        if (newVal.length >= 8) {
            newVal = (newVal.length > 8) ? newVal.substring(0, 8) : newVal;
            let val = newVal.replace(/^(\d{0,4})(\d{0,2})(\d{0,2})/, '$1-$2-$3');
            checkFormatDate = this.dateConverterService.checkDateFormat(val, this.date);
            if (!checkFormatDate) {
                newVal = newVal.substring(0, 6)
            }
        }
        return newVal;
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(e) {
        if (!(e.charCode >= 48 && e.charCode <= 57)) {
            e.preventDefault();
            let today = this.dateConverterService.toDateFormat(new Date());
            this.setStartDateTime(today);
        }
        let value = e.target.value + e.key;
        this.onInputChange(value);
    }

    private defaultTodayDate() {
        if (this.openToDate) {
            const date = this.dateConverterService.toDateFormat(this.openToDate);
            this.setStartDateTime(date);
            this.setEndDateTime(date);
            return;
        }
        if (this.calendarMidRange) {
            this.openCalendarMidRange();
            return;
        }
        if (this.module != this.MODULE_SHOPPING && this.module != this.MODULE_PAYMENT) {
            let today = this.dateConverterService.toDateFormat(new Date());
            this.setStartDateTime(today);
            this.setEndDateTime(today);
        }
    }

    private openCalendarMidRange() {
        if (!this.calendarMidRange) return;
        
        const min = moment(this._option?.minDate)
        const max = moment(this._option?.maxDate)

        if (!min.isValid() || !max.isValid()) return;

        const maxYear = max.year();
        const minYear = min.year();
        const midYear = Math.floor((maxYear + minYear) / 2);

        const mid = moment();
        mid.set('year', midYear);

        this.setStartDateTime(mid.format(this._dateTimeFormat));
        this.setEndDateTime(mid.format(this._dateTimeFormat));
    }
}