export const PAGE_TITLE = {
    settings: 'Settings Management',
    vehicle: 'Vehicle Management',
    vehicle_configuration: 'Vehicle Configuration Management',
    attribute: 'Attribute Management',
    vehicle_composition: 'Vehicle Composition Management',
    security: 'Security Management',
    exchange_rate: 'Exchange Rate Management',
    exchange_currency: 'Exchange Currency',
    exchange_rate_amount: 'Exchange Rates',
    geography: 'Geography Management',
    location_group_management: 'Location Group Management',
    location_management: 'Location Management',
    timezone_management: 'Time Zone Management',
    document_distribution: 'Document Distribution',
    news: 'News'
}

export const MODULE_NAME = {
    vehicle: 'vehicle',
    vehicle_type: 'vehicle type',
    vehicle_composition: 'vehicle composition',
    vehicle_config: 'vehicle configuration',
    seatmap: 'seat map',
    attribute: 'attribute',
    security: 'security',
    exchange_rate: 'exchange rate',
    exchange_currency: 'exchange currency',
    exchange_rate_amount: 'exchange rates',
    geography: 'geography',
    document_distribution: 'document distribution',
    news: 'news'
}
