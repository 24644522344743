import { AttributeChoiceModel } from './attribute-choice.model';

export interface DomainAttributeModel {
    domainAttributeId: string;
    domainCode: string;
    attributeGroupCode: string;
    attributeTypeCode: string;
    attributeTypeName: string;
    dimensionUnitCode: string;
    minimumValue: number;
    maximumValue: number;
    defaultValue: number;
    showOnNewFlag: boolean;
    dimensionFlag: boolean;
    startFlag: boolean;
    endFlag: boolean;
    productFlag: boolean;
    requiredFlag: boolean;
    multipleChoiceFlag: boolean;
    searchFlag: boolean;
    sortSequence: number;
    statusCode: string;
    scheduleProductFlag: boolean;
    operatingProductFlag: boolean;
    attributeChoices: AttributeChoiceModel[];
}