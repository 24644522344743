export interface ServiceTimeViewModel {
    productDateTimeId: string;
    productId: string;
    operatingProductId?: string;
    dateTimeCode: string;
    displaySequence: number;
    productInventoryId?: string;
    localDateTime: Date;
    utcDateTime?: Date;
    serviceTimeHierarchyKey: string;
    locationId: string;
}