import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { DatatableComponentBase } from 'src/app/core/base/datatable-component-base';
import { InvoiceOrganisationSearchModel } from 'src/app/core/models/organisation-model/invoice-organisation-search.model';
import { InvoiceOrganisationService } from 'src/app/core/services/organisation-services/invoice-organisation.service';
import { NavigationService } from 'src/app/shared/utils/navigation';

@Component({
    selector: 'op-invoice-organisation-search-result',
    templateUrl: './invoice-organisation-search-result.component.html'
})
export class InvoiceOrganisationSearchResultComponent extends DatatableComponentBase implements AfterViewInit {
    public focused = false;
    public searchResults: InvoiceOrganisationSearchModel[];
    public rows: number;
    public exportData: any;
    public header: string[];

    public selectedItem: any;

    @Output() onSelectOrganisation = new EventEmitter<InvoiceOrganisationSearchModel>();

    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    constructor(private navigationService: NavigationService,
        private invoiceOrganisationService: InvoiceOrganisationService,
        private changeDetector: ChangeDetectorRef) { 
        super()
    }

    ngAfterViewInit(): void {
        this.getDataGridHeader();
    }

    public cleanDatatable() {
        this.destroyDatatable();
    }

    public getNumberOfRows(e) {
        this.rows = e.component.totalCount();
        this.getExportData()?.done((filteredData: any) => {
            this.exportData = filteredData.map((data) => {
                return this.mapData(data);
            })
        });
    }

    private getExportData() {
        let filterExpr = this.dataGrid.instance.getCombinedFilter();
        let gridDataSource = this.dataGrid.instance.getDataSource();
        return gridDataSource?.store()?.load({
            filter: filterExpr,
        });
    }

    private mapData(data: any): any {
        return {
            No: data.no,
            Code: data.organisationCode,
            Name: data.organisationCallName,
            Address: data.address
        };
    }

    private getDataGridHeader() {
        this.header = this.dataGrid.instance.getVisibleColumns().map(item => item.caption);
    }

    doubleClick(event) {
        this.selectedItem = event.data;
        this.onSelectOrganisation.emit(this.selectedItem);
    }

    onRowSelected(event) {
        this.selectedItem = event.data;
    }

    get hideExport(): boolean {
        return this.rows <= 0;
    }

    new() {
        const queryParams = {
            selectedTab: 'SEARCH',
        }
        this.navigationService.navigate("main/organisation/" + Date.now().toString(), "Organisation", true, queryParams);
    }

    public searchOrganisation(condition: InvoiceOrganisationSearchModel) {
        this.invoiceOrganisationService.searchInvoiceOrganisation(condition)
            .subscribe(
                (responses: InvoiceOrganisationSearchModel[]) => {
                    var dynamicDataArray = new Array();
                    var index = 1;

                    responses.forEach(item => {
                        var orgnisationTemps = {};
                        orgnisationTemps["organisationId"] = item.organisationId;
                        orgnisationTemps["no"] = index;
                        orgnisationTemps["organisationCode"] = item.organisationCode;
                        orgnisationTemps["organisationCallName"] = item.organisationCallName;
                        orgnisationTemps["organisationAddress"] = item.organisationAddress;
                        orgnisationTemps["organisationAddressId"] = item.organisationAddressId
                        dynamicDataArray.push(orgnisationTemps);
                        index += 1;
                    });
                    this.searchResults = dynamicDataArray;
                    this.rows = this.searchResults.length;
                    this.changeDetector.detectChanges();
                }
            )
    }
}
