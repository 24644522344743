import { OrderDetailHeaderLockView } from './order-detail-header-lock.view';
import { OrderDetailHeaderPointOfSaleView } from './order-detail-header-pointOfSale.view';

export interface OrderDetailHeaderView {
    orderLock: boolean;
    orderSalesStatusCode: string;
    orderSalesStatus: string;
    orderDeliveryStatusCode: string;
    orderDeliveryStatus: string;
    orderPaymentStatusCode: string;
    orderPaymentStatus: string;
    currency: string;
    totalAmount: string;
    paidAmount: string;
    outStandingBalance: string;
    orderTypeName: string;
    orderTypeCode: string;
    orderDateTime: string;
    numberOfUnits: string;
    commitDateTime: string;
    commitByName: string;
    pointOfSale: OrderDetailHeaderPointOfSaleView[];
    orderLockDetail: OrderDetailHeaderLockView;
    showDetail: boolean;
}