import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { UiModule } from "src/app/shared/ui/ui.module";
import { NewsComponent } from "./news.component";
import { NewsContentModule } from "./news-content/news-content.module";

@NgModule({
    declarations: [
        NewsComponent
    ],    
    imports: [
        CommonModule,
        NewsContentModule,
        UiModule
    ],
    exports: [
        NewsComponent
    ]
})

export class NewsModule {}
