export interface OrderDetailIndividualDocumentView {
    identityDocumentId: string;
    identityDocumentCode: string;
    identityDocumentName: string;
    identityDocumentNumber: string;
    countryCode: string;
    countryName: string;
    expiryDate: string;
    lastName: string;
    firstName: string;
    title: string;
    allowedEdit: boolean;
    showDetail: boolean;
    individualId: string;
    orderId: string;
    partnerOrderId: string;
    issueDate: string;
    placeOfIssue: string;
    orderSalesStatusCode: string;
}