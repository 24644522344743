export interface InvoiceOrganisationSearchModel {
    organisationId?: string,
    organisationAddressId?: string,
    organisationMailId?: string,
    organisationPhoneId?: string,
    organisationLanguageCode?: string,
    organisationCurrencyCode?: string,
    organisationTypeCode?: string,
    organisationCallName?: string,
    organisationLegalName?: string,
    organisationCode?: string,
    mailAddress?: string,
    phoneNumber?: string,
    addressLine1?: string,
    addressLine2?: string,
    postalCode?: string,
    city?: string,
    countryCode?: string,
    countryName?: string,
    organisationAddress?: string,
}