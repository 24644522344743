import { AreaModel } from './area.model';
import { OperatorModel } from './operator.model';
import { ODModel } from './od.model';

export interface LocationPointModel {
    locationPointCode:string;
    locationPointName:string;
    areas:AreaModel[];
    operators:OperatorModel[];
    ods:ODModel[];
}