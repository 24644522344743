import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, TemplateRef, AfterViewInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from "rxjs";

import { IndividualTitleModel } from "src/app/core/models/user-model/individual-title.model";
import { IndividualTitleReferenceService } from "src/app/core/services/user-account-services/individual-title.service";
import { IndividualTitleCommandModel } from '../../models/user-model/individual-title-command.model';

const TITLE_OPTION = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

@Component({
    selector: 'op-individual-title-reference-selection',
    templateUrl: './individual-title-reference-selection.component.html',
    providers: [
        IndividualTitleReferenceService
    ]
})
export class IndividualTitleReferenceSelectionComponent implements OnInit, AfterViewInit, OnChanges {

    public _value: string | string[];

    public individualTitleReference$ = new BehaviorSubject<IndividualTitleModel[]>(null);
    public individualTitleReference: IndividualTitleModel[] = [];
    public titleOption = TITLE_OPTION;
    public loadSuccess: boolean = false;

    @Input()
    set data(value: string) {
        this._value = value;
    }
    @Input('disabled') disableFlag: boolean = false;
    @Input('loadDefaultTitle') loadDefaultTitle: boolean = false;
    @Input('individualTitleCommand') searchCommand: IndividualTitleCommandModel = null;
    @Input('tabIndexNo') tabIndexNo: number = null;
    @Output()
    dataChange: EventEmitter<string> = new EventEmitter();

    @ViewChild('titleContainer', { read: ViewContainerRef }) container: ViewContainerRef;
    @ViewChild('titleTemplate', { read: TemplateRef }) template: TemplateRef<any>;

    constructor(private titleReferenceService: IndividualTitleReferenceService,
        private changeDetectorRef: ChangeDetectorRef) {
    }
    ngOnInit(): void {
        this.loadIndividualTitle();
    }

    ngAfterViewInit(): void {
        this.insertChildView();
        if (this.loadDefaultTitle) {
            this.loadIndividualTitleByCodeReference(this.loadDefaultTitle);
        }
        this.changeTitleByAgeGroupCode();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['searchCommand']) {
            this.loadIndividualTitle();
        }
    }

    loadIndividualTitle() {
        if (this.searchCommand?.individualAgeGroupCode || this.searchCommand?.individualTitleCode) {
            this.loadIndividualTitleByCodeReference();
        } else {
            this.loadIndividualTitleReference();
        }
    }

    insertChildView() {
        this.container?.insert(this.template.createEmbeddedView(null));
        this.onChange(this._value);
    }

    removeChildView() {
        this.container?.clear();
    }

    reloadChildView() {
        this.removeChildView();
        this.insertChildView();
        this.changeDetectorRef.detectChanges();
    }

    public loadIndividualTitleByCodeReference(loadDefault: boolean = false) {
        this.loadSuccess = false;
        let searchCommand: IndividualTitleCommandModel = {
            individualAgeGroupCode: null,
            individualTitleCode: null
        }
        let command = loadDefault ? searchCommand : this.searchCommand;
        this.titleReferenceService.getIndividualTitleByCode(command)
            .subscribe(
                (responses: any) => {
                    this.loadResponses(responses);
                    if (loadDefault) {
                        this.individualTitleReference = responses;
                    }
                }
            )
    }

    private loadIndividualTitleReference() {
        this.loadSuccess = false;
        this.titleReferenceService.getIndividualTitle()
            .subscribe(
                (responses: any) => {
                    this.loadResponses(responses);
                }
            )
    }

    private loadResponses(responses: any) {
        this.individualTitleReference$.next(responses);
        this.onChange(this._value)
        this.reloadChildView();
        this.loadSuccess = true;
        if (this.loadDefaultTitle) {
            this.changeTitleByAgeGroupCode();
        }
        this.changeDetectorRef.detectChanges();
    }

    public changeTitleByAgeGroupCode() {
        if (this.searchCommand?.individualAgeGroupCode) {
            let titles = this.individualTitleReference?.filter(x => x.individualAgeGroupCode == this.searchCommand.individualAgeGroupCode);
            this.individualTitleReference$.next(titles);
            this.onChange(this._value)
            this.reloadChildView();
            this.changeDetectorRef.detectChanges();
        }
    }

    public onChange($event) {
        this._value = $event;
        this.dataChange.emit($event);
    }

    get disableTitle() {
        return (!this.loadSuccess) || this.disableFlag
    }

    public getSelectedText() {
        return this.individualTitleReference$.value
            ?.find(item => item.individualTitleCode == this._value)
            ?.individualTitleName;
    }
}
