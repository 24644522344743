import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { AttributeTreeNode } from 'src/app/core/models/reference-model/reference-general-model/attribute-tree-node.model';
import { AttributeTree } from 'src/app/core/models/reference-model/reference-general-model/attribute-tree.model';
import { AttributeTypeReference } from 'src/app/core/models/reference-model/reference-general-model/attribute-type-reference.model';
import { StatusReferenceService } from 'src/app/core/services/system-services';
import { AttributeMaintenanceService } from 'src/app/core/services/system-services/attribute-maintenance.service';
import { PAGE_TITLE } from 'src/app/modules/settings-management/settings-management-content/shared/title-constant';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { ActionBarHandlerModel, ActionbarService, ACTION_STATUS } from 'src/app/shared/ui/actionbar';
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel, SaveButtonModel } from 'src/app/shared/ui/actionbar/models';
import { FocusingService } from 'src/app/shared/ui/forms/inputs/focusing.service';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { MapperService } from '../shared/mapper.service';
import { AttributeChoiceView } from '../shared/view/attribute-choice.view';
import { AttributeDetailView } from '../shared/view/attribute-detail-view.view';
import { AttributeTypeCommandView } from '../shared/view/attribute-type-command.view';
import { DomainAttributeView } from '../shared/view/domain-attribute.view';
import { AttributeAssignmentComponent } from './attribute-assignment/attribute-assignment.component';
import { AttributeChoiceComponent } from './attribute-choice/attribute-choice.component';
import { AttributeDetailFormComponent } from './attribute-detail-form/attribute-detail-form.component';
import { cloneDeep } from "lodash";
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { PagingDataView } from 'src/app/core/views/pagging-data.view';
import { ActionService } from 'src/app/core/utils/action.service';
import { FavoriteConstant } from 'src/app/modules/favorite/shared/favorite.constant';
import { AttributeSearchConditionView } from '../shared/view/attribute-search-condition.view';
import { StringHelperService } from 'src/app/core/utils/string-helper.service';
import { OopsCategory } from 'src/app/core/components/categories';

@Component({
    selector: 'op-attribute-detail',
    templateUrl: './attribute-detail.component.html',
    providers: [
        MapperService,
        ActionbarService
    ]
})
export class AttributeDetailComponent implements OnInit, AfterViewInit, OnDestroy {
    private readonly detailTabId = 'detail';
    private readonly choiceTabId = 'choice';
    private readonly PRICE_DOMAIN_CODE = 'PRICE';
    private readonly PRODUCT_DOMAIN_CODE = 'PRODUCT';
    private readonly TRANSPORT_CODE = 'TRANSPORT';
    private readonly CHOICE_DIMENSION_CODE = 'CHOICE';

    public attributeCode: string;
    public attributeName: string;
    public newAttributeFlag: boolean = true;
    public attributeTree$ = new BehaviorSubject<AttributeTreeNode[]>(null)
    public heightPanelGeneral = 485;
    private unsubscribe$ = new Subject();
    private previousTitle: string = PAGE_TITLE.settings;
    public copyFlag: boolean = false;

    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public attributeTypeRef$ = new BehaviorSubject<AttributeDetailView>(null);
    public domainAttributeList$ = new BehaviorSubject<DomainAttributeView[]>(null);
    public attributeChoiceList$ = new BehaviorSubject<AttributeChoiceView[]>(null);
    public attributeTypeReferenceDetail: AttributeTypeReference;
    public nodeSelected: boolean = false;
    public showReadMode: boolean = false;
    public isSaving = false;
    public detailPanelHeaderName = "";
    public domainAttributeFlag = false;
    public dimensionIsChoice = false;
    public selectedDomainAttribute: AttributeTreeNode[];
    public editedAttributeDetailList: DomainAttributeView[] = [];
    public newAttributeDetailList: DomainAttributeView[] = [];
    public unmapDomainTypeList: AttributeTreeNode[] = [];
    public unmapDomainList: string[] = [];
    public domainName = ""
    public domainCode = "";
    public userSecurity: SecurityGroupSecurityModel;
    public paggingView: PagingDataView[];
    public currentIndex: number;
    public priceSelected = false;
    public transportProductSelected = false;
    public searchCondition: AttributeSearchConditionView;
    public selectedCategory: OopsCategory;

    actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new SaveButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    )

    private _navigationSubscription: Subscription;
    @ViewChild(AttributeAssignmentComponent) assignmentTree: AttributeAssignmentComponent;
    @ViewChild(AttributeDetailFormComponent) attributeDetailFormComponent: AttributeDetailFormComponent;
    @ViewChild(AttributeChoiceComponent) attributeChoiceComponent: AttributeChoiceComponent;

    constructor(private attributeMaintenanceService: AttributeMaintenanceService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private mapper: MapperService,
        private statusReferenceService: StatusReferenceService,
        private changeDetectionRef: ChangeDetectorRef,
        private loadingSpinnerService: LoadingSpinnerService,
        private focusingService: FocusingService,
        private actionService: ActionService,
        private stringHelperService: StringHelperService) { }

    ngOnInit() {  
        this.loadAttributeTree();
        this.getStatusReference();
        this.getParam();
    }

    ngAfterViewInit(): void {
        this.setupAttributeDetail();
        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.toolbarClicked(actionId);
            }
        )
        this.scrollToTop();
    }

    private getParam() {
        let param = this.navigationService.getParams();
        this.newAttributeFlag = param?.newFlag;
        this.attributeCode = param?.id ?? "";
        this.attributeName = param?.attributeName ?? "";
        this.userSecurity = param?.userSecurity;
        this.copyFlag = param?.copyFlag;
        this.paggingView = param?.paggingView ?? [];
        this.currentIndex = param?.currentIndex;
        this.searchCondition = param?.searchCondition;
        this.selectedCategory = param?.selectedCategory;
        this.addAction();
    }

    setupAttributeDetail() {
        if (this.newAttributeFlag === false) {
            this.getAttribute(this.attributeCode);
            this.getDomainAttribute(this.attributeCode);
        }
        if (this.userSecurity != null) {
            this.setupSecurity();
        }
    }

    getAttribute(attributeCode: string) {
        this.loadingSpinnerService.show();
        this.domainName = "";
        this.domainCode = "";
        this.attributeMaintenanceService.getAttributeByCode(attributeCode)
            .subscribe(
                (response: AttributeTypeReference) => {
                    this.processAttributeDetail(response);
                    this.loadingSpinnerService.hide();
                })
    }

    private processAttributeDetail(attribute: AttributeTypeReference) {
        this.domainAttributeFlag = false;
        let view = this.mapper.attributeTypeToAttributeDetailView(attribute);
        if (attribute.dimensionUnitCode == this.CHOICE_DIMENSION_CODE) {
            this.dimensionIsChoice = true;
            this.getAttributeChoice(attribute.attributeCode);
            this.attributeDetailFormComponent.checkDimensionChoiceDisable(attribute.dimensionUnitCode)
        }
        this.attributeTypeRef$.next(view);
        this.attributeDetailFormComponent.attributeDetail = view;
        this.attributeName = view.attributeName;
        if (this.copyFlag == true) {
            this.copy();
        }
        this.addAction();
    }

    getAttributeChoice(attributeCode: string) {
        this.attributeMaintenanceService.getAttributeChoiceByAttributeTypeCode(attributeCode)
        .subscribe(
            (response: AttributeChoiceView[]) => {
                this.attributeChoiceList$.next(response);
                this.attributeChoiceComponent.fillModelToForm(response);
            }
        )
    }

    getDomainAttribute(attributeCode: string) {
        this.attributeMaintenanceService.getDomainAttributeByAttributeType(attributeCode)
        .subscribe(
            (responses: DomainAttributeView[]) => {
                this.domainAttributeList$.next(responses);
                if (this.copyFlag == true) {
                    this.attributeDetailFormComponent.newDomainAttributeDetail = cloneDeep(responses);
                }
            }
        )
    }

    private loadAttributeTree() {
        this.attributeMaintenanceService.getAttributesTree()
        .subscribe(
            (response) => {
                this.attributeTree$.next(this.setUpTree(response));
                this.assignmentTree.selectSelectedTreeNodes(response);
                this.showDefaultAttribute();
            }
        );
    }

    private setUpTree(tree: AttributeTree): AttributeTreeNode[]{
        tree.children.forEach(item => {
            item.expanded = true;
            item.domainCode = item.code;
            this.addDomainCodeAndName(item, item.code, item.name);
        });
        return tree.children;
    }

    private getStatusReference() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses);
                }
            )
    }

    private addDomainCodeAndName(item: AttributeTreeNode, domainCode: string, domainName: string) {
        item.domainCode = domainCode;
        item.domainName = domainName;

        if (item.children.length > 0) {
            item.children.forEach(node => {
                return this.addDomainCodeAndName(node, domainCode, domainName);
            })
        }
    }

    clickDomain(domainTreeNode: AttributeTreeNode) {
        if (this.attributeDetailFormComponent.checkDomainAttributeAlreadyExist(domainTreeNode.domainCode)) {
            this.displayAttributeDomainDetail(domainTreeNode);
        }
    }

    displayAttributeDomainDetail(treeNode: AttributeTreeNode) {
        if (this.attributeDetailFormComponent.checkValid() == true && treeNode.domainCode != null) {
            this.updateDomainAttributeDetail();
            this.domainCode = treeNode.domainCode;
            this.domainName = treeNode.domainName;
            this.domainAttributeFlag = true;
            this.attributeDetailFormComponent.diplayDetail(treeNode.domainCode)
            this.enableCheckBox(treeNode);
        }
        else {
            this.assignmentTree.unselectChangedNode();
            this.attributeDetailFormComponent.checkValid();
        }
    }

    updateDomainAttributeDetail() {
        let detailTemp = this.attributeDetailFormComponent.fillDetailToAttributeDetailView();
        if (detailTemp.domainCode == "") {
            this.attributeDetailFormComponent.updateAttributeDetail(detailTemp);
        } else {
            this.attributeDetailFormComponent.addDomainAttribute(detailTemp);
        }
    }
    
    toolbarClicked(e: string) {
        if (e == ACTION_STATUS.back) {
            this.back();
        } else if (e == ACTION_STATUS.save) {
            this.save();
        } else if (e == ACTION_STATUS.copy) {
            this.copy();
        } else if (e == ACTION_STATUS.cancel) {
            this.cancel();
        } else if (e == ACTION_STATUS.new) {
            this.new();
        } else if (e == ACTION_STATUS.refresh) {
            this.refresh();
        }
    }

    back() {
        let param = {
            activeId: 'SEARCH',
            searchCondition: this.searchCondition,
            selectedCategory: 'attribute',
            selectedCategoryItem: this.selectedCategory
        }
        this.navigationService.navigate('/main/settings/home', null, false, param);
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    getHeightPanelGeneral(height): Number {
        return this.heightPanelGeneral = height;
    }

    onAttributeNameChange(attributeName: string) {
        this.attributeName = attributeName;
    }

    onAttributeCodeChange(attributeCode: string) {
        this.attributeCode = attributeCode;
    }

    save() {
        if (this.checkValid()) {
            var command = this.fillToCommand();
            if (this.newAttributeFlag == true) {
                this.addAttribute(command);
            }
            else {
                this.editAttribute(command);
            }
            this.changeDetectionRef.detectChanges;
        }    
    }

    addAttribute(command: AttributeTypeCommandView) {
        this.loadingSpinnerService.showSaving();
        this.attributeMaintenanceService.addAttributeTypeReference(command)
            .subscribe(
                (response: AttributeTypeReference) => {
                    this.clearPendingData();
                    this.processAttributeDetail(response);
                    this.attributeDetailFormComponent.checkDimensionChoiceDisable(command.attributeTypeReference.dimensionUnitCode);
                    this.setupSecurity();
                    this.loadingSpinnerService.saveComplete();
                    this.changeDetectionRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                    this.changeDetectionRef.detectChanges();
                }
            );
    }

    editAttribute(command: AttributeTypeCommandView) {
        this.loadingSpinnerService.showSaving();
        this.attributeMaintenanceService.editAttributeTypeReference(command)
            .subscribe(
                (response: AttributeTypeReference) => {
                    this.clearPendingData();
                    this.processAttributeDetail(response);
                    this.attributeDetailFormComponent.checkDimensionChoiceDisable(command.attributeTypeReference.dimensionUnitCode);
                    this.setupSecurity();
                    this.loadingSpinnerService.saveComplete();
                    this.changeDetectionRef.detectChanges();
                },
                () => {
                    this.loadingSpinnerService.hide();
                    this.changeDetectionRef.detectChanges();
                }
            );
    }

    private fillToCommand() {
        this.updateDomainAttributeDetail();
        this.selectedDomainAttribute = this.removeDuplicateSelectedDomainAttribute(this.getSelectDomainAttribute());
        this.unmapDomainList = this.assignmentTree.getUnmapDomainList();
        this.unmapDomainTypeList = this.assignmentTree.getUnmapDomainTypeList();
        this.editedAttributeDetailList = this.attributeDetailFormComponent.getEditedDetail();
        this.newAttributeDetailList = this.attributeDetailFormComponent.getNewDetail();
        let attributeDetail = this.mapper.attributeDetailViewToAttributeTypeReference(this.attributeDetailFormComponent.getAttrubuteTypeDetail());

        if (this.unmapDomainList.length > 0) {
            this.removeUnmapDomainAttributeDetail();
        }

        if (this.unmapDomainTypeList.length > 0) {
            this.removeDuplicateOfUnmapDomainType();
        }

        var command: AttributeTypeCommandView = {
            attributeCode: attributeDetail.attributeCode,
            editDomainAttributes: this.correctAttributeCodeInDomainAttribute(this.editedAttributeDetailList) ?? [],
            newDomainAttributes: this.correctAttributeCodeInDomainAttribute(this.newAttributeDetailList) ?? [],
            attributeTypeReference: attributeDetail,
            domainTypeMap: this.selectedDomainAttribute ?? [],
            domainTypeUnmap: this.unmapDomainTypeList ?? [],
            domainUnmap: this.unmapDomainList ?? [],
            attributeChoices: this.attributeChoiceComponent.editAttributeChoices ?? [],
            newAttributeChoices: this.attributeChoiceComponent.newAttributeChoices ?? [],
            deleteAttributeChoices: this.attributeChoiceComponent.deleteAttributeChoices ?? []
        }
        
        return command;
    }

    removeUnmapDomainAttributeDetail() {
        this.unmapDomainList.forEach(item => {
            this.removeUnmapEditDomainAttribute(item);
            this.removeUnmapNewDomainAttribute(item);
        })
    }

    removeUnmapEditDomainAttribute(domainCode: string) {
        let index = this.editedAttributeDetailList.findIndex(domainDetail => domainDetail.domainCode == domainCode);
        if (index != -1) {    
            this.editedAttributeDetailList.splice(index, 1);
        } 
    }

    removeUnmapNewDomainAttribute(domainCode: string) {
        let index = this.newAttributeDetailList.findIndex(domainDetail => domainDetail.domainCode == domainCode);
        if (index != -1) {
            this.newAttributeDetailList.splice(index, 1);
        }    
    }

    removeDuplicateOfUnmapDomainType() {
        this.unmapDomainTypeList.forEach(item => {
            let index = this.selectedDomainAttribute.findIndex(node => node.code == item.code && item.domainCode == item.domainCode);
            if (index != -1) {
                this.selectedDomainAttribute.splice(index, 1);
            }
        })
    }

    removeDuplicateSelectedDomainAttribute(selectedDomainAttribute: AttributeTreeNode[]) {
        let filteredList = new Array<AttributeTreeNode>();
        selectedDomainAttribute.forEach(item => {
            if (filteredList.findIndex(node => node.code == item.code && node.domainCode == item.domainCode) == -1) {
                filteredList.push(item);
            }
        })
        return filteredList;
    }

    showDefaultAttribute() {
        this.updateDomainAttributeDetail();
        this.domainCode = "";
        this.domainName = "";
        this.domainAttributeFlag = false;
        this.resetCheckBox();
    }

    clearPendingData() {
        this.unmapDomainTypeList = [];
        this.unmapDomainList = [];
        this.newAttributeFlag = false;
        this.attributeDetailFormComponent.clearPendingData();
        this.assignmentTree.clearPendingData();
        this.attributeChoiceComponent.clearPendingData();
    }

    getSelectDomainAttribute(): AttributeTreeNode[] {
        var selectedDomainTemp = this.assignmentTree.selectedDomainAttribute ?? [];
        var selectedDomain = [];
        if (selectedDomainTemp.length > 0) {
            selectedDomainTemp.forEach(item => {
                if (item.children.length == 0 && item.code != item.domainCode) {
                    selectedDomain.push(item);
                }
            })
        }  
        return selectedDomain;
    }

    onDimensionTypeChange(isChoice: boolean) {
        this.dimensionIsChoice = isChoice;
        this.attributeChoiceComponent.fillModelToForm([]);
    }

    onActiveIdChange(activeId: string) {
        if (activeId == this.detailTabId) {
            this.focusingService.focus(this.attributeDetailFormComponent?.focusingDirective);
        }
        if (activeId == this.choiceTabId) {
            this.focusingService.focus(this.attributeChoiceComponent?.focusingDirective);
        }
    }

    copy() {
        let copyDomainAttribute = this.manangeCopyDomainAttribute(cloneDeep(this.attributeDetailFormComponent.domainAttributeDetail));
        let copyAttributeDetail = this.setupCopyAttributeDetail(this.attributeDetailFormComponent.getAttrubuteTypeDetail());
        let copyAttributeChoice = this.manageCopyAttributeChoice(cloneDeep(this.attributeChoiceComponent.attributeChoiceList));

        this.clearPendingData();

        this.attributeTypeRef$.next(copyAttributeDetail);
        this.domainAttributeList$.next(copyDomainAttribute);
        this.attributeChoiceList$.next(copyAttributeChoice);

        this.attributeCode = copyAttributeDetail.attributeCode;
        this.attributeDetailFormComponent.newDomainAttributeDetail = copyDomainAttribute;
        this.attributeChoiceComponent.newAttributeChoices = copyAttributeChoice;
        this.attributeDetailFormComponent.resetForm();
        this.onAttributeNameChange(copyAttributeDetail.attributeName);
        this.newAttributeFlag = true;
        this.assignmentTree.focusTreeHeader();
        this.attributeDetailFormComponent.checkDuplicateCode(copyAttributeDetail.attributeCode);
        this.setupSecurity();
        this.attributeDetailFormComponent.detechChange();
    }

    manangeCopyDomainAttribute(domainAttributeList: DomainAttributeView[]) {
        domainAttributeList?.forEach(item => {
            item.domainAttributeId = '';
        })
        return domainAttributeList;
    }

    setupCopyAttributeDetail(attributeDetail: AttributeDetailView) {
        attributeDetail.attributeName = attributeDetail?.attributeName+  ' - copy'; 
        return attributeDetail;
    }

    manageCopyAttributeChoice(attributeChoiceList: AttributeChoiceView[]) {
        attributeChoiceList?.forEach(item => {
            item.attributeChoiceId = this.stringHelperService.NewGuid();
        })
        return attributeChoiceList;
    }

    correctAttributeCodeInDomainAttribute(domainAttributes: DomainAttributeView[]): DomainAttributeView[] {
        domainAttributes?.forEach(item => {
            item.attributeTypeCode = this.attributeCode;
        })
        return domainAttributes;
    }

    private setupSecurity() {
        this.resetActionBarState();
        if (!this.userSecurity?.newFlag) {
            this.actionBarHandler.get(ACTION_STATUS.new).disable();
        }
        if (!this.userSecurity?.copyFlag) {
            this.actionBarHandler.get(ACTION_STATUS.copy).disable();
        }
        if ((this.newAttributeFlag == true && !this.userSecurity?.newFlag) || (this.newAttributeFlag == false && !this.userSecurity?.editFlag)) {
            this.actionBarHandler.get(ACTION_STATUS.save).disable(); 
        }
    }

    private checkValid() {
        return (this.attributeDetailFormComponent.checkValid() === true && this.domainAttributeFlag === false)
            || this.domainAttributeFlag === true
    }

    private cancel() {
        let param = {
            redirectToSetting: true
        }
        this.navigationService.navigate('/main/settings/home', this.previousTitle, false, param);
    }

    private new() {
        this.clearPendingData();
        this.newAttributeFlag = true;
        this.domainAttributeFlag = false;
        this.setupNewDetail();
        this.setupNewForTree();
        this.attributeChoiceComponent.clearForNewAttribute();
        this.setupSecurity();
        
        this.paggingView = [];
        this.currentIndex = null;
        this.changeDetectionRef.detectChanges();
    }

    private setupNewDetail() {
        this.attributeCode = '';
        this.attributeName = '';
        this.attributeTypeRef$.next(null);
        this.setupNewForm();
        this.onDimensionTypeChange(false);
        this.changeDetectionRef.detectChanges();
    }

    private setupNewForm() {
        this.attributeDetailFormComponent.attributeDetail = null
        this.attributeDetailFormComponent.resetForm();
        this.attributeDetailFormComponent.initForm();
        this.attributeDetailFormComponent.dimensionChoice = false;
        this.attributeDetailFormComponent.domainAttributeList = [];
        this.attributeDetailFormComponent.domainAttributeDetail = [];
    }

    private setupNewForTree() {
        this.onAttributeNameChange('[new]');
        this.assignmentTree.clearSelected();
        this.assignmentTree.selectTreeHeader();
    }

    private refresh() {
        this.domainAttributeFlag = false;
        this.getAttribute(this.attributeCode);
        this.getDomainAttribute(this.attributeCode);
    }

    public onPageChange(id: string): void {
        this.setupNewDetail();
        this.setupNewForTree();
        this.attributeChoiceComponent.clearForNewAttribute();
        this.clearPendingData();
        this.attributeCode = id;
        this.loadAttributeTree();
        this.getAttribute(id);
        this.getDomainAttribute(id);
    }

    private enableCheckBox(treeNode){
        this.priceSelected = false;
        this.transportProductSelected = false;

        if (treeNode.domainCode == this.PRICE_DOMAIN_CODE) {
            this.priceSelected = true;
        }

        if (treeNode.domainCode == this.PRODUCT_DOMAIN_CODE) {
            if (this.checkTransportProductSelected() == true) {
                this.transportProductSelected = true;
            }   
        }

        this.attributeDetailFormComponent.updateCheckBoxDisable();
    }

    private checkTransportProductSelected(): boolean {
        let selectedProducts = this.assignmentTree.treeView.instance.getSelectedNodes();
        selectedProducts = selectedProducts.filter(item => item.itemData.domainCode == this.PRODUCT_DOMAIN_CODE);
        for (let product of selectedProducts) {
            let productCategoryNode = this.getProductCategoryNode(product);
            if (productCategoryNode.itemData.code == this.TRANSPORT_CODE || productCategoryNode.itemData.code == this.PRODUCT_DOMAIN_CODE) {
                
                return true;
            }
        }
        return false;
    }

    private getProductCategoryNode(node) {
        if (node.parent?.itemData?.code == this.PRODUCT_DOMAIN_CODE || node.itemData.code == this.PRODUCT_DOMAIN_CODE) {
            return node;
        }
        return this.getProductCategoryNode(node.parent);
    }

    private resetCheckBox() {
        this.priceSelected = false;
        this.transportProductSelected = false;
        this.attributeDetailFormComponent.updateCheckBoxDisable();
    }

    private scrollToTop() {
        window.scrollTo({top: 0, behavior:'smooth'});
    }

    public addAction() {
        if (this.attributeCode) {
            this.actionService.add(FavoriteConstant.DETAIL_ACTION, this.attributeCode, this.attributeName);
        } else {
            this.actionService.add(FavoriteConstant.NEW_ACTION, null, null);
        }
    }

    private resetActionBarState() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable();
        this.actionBarHandler.get(ACTION_STATUS.copy).enable();
        this.actionBarHandler.get(ACTION_STATUS.save).enable();
    }
}

