import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InvoiceOrganisationSearchModel } from 'src/app/core/models/organisation-model/invoice-organisation-search.model';
import { StatusReferenceModel } from 'src/app/core/models/reference-model/reference-general-model';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';
import { StatusConstant } from 'src/app/shared/ui/forms/inputs/status-color-dropdown/shared/constants/status.constant';

const select2WithoutSearchOption = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

@Component({
    selector: 'op-invoice-organisation-search-condition',
    templateUrl: './invoice-organisation-search-condition.component.html'
})
export class InvoiceOrganisationSearchConditionComponent implements OnInit {
    private readonly defaultCondition: InvoiceOrganisationSearchModel = {
        organisationCode: null,
        organisationCallName: null,
        organisationTypeCode: 'CUSTOMER',
        city: null,
        countryCode: null
    }
    public focused = false;
    public condition: InvoiceOrganisationSearchModel;
    public select2WithoutSearchOption: any;
    public defaultStatus: string = StatusConstant.ACTIVE;

    @Input() statusReferences: StatusReferenceModel[]
    @Output() onSearch = new EventEmitter<InvoiceOrganisationSearchModel>();
    @Output() onClear = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.select2WithoutSearchOption = select2WithoutSearchOption;
        this.setDefaultCondition();
    }

    public trimInput(input) {
        input.target.value = input.target.value.trim();
    }

    public searchClick() {
        this.onSearch.emit(this.condition);
    }

    public clearCondition() {
        this.setDefaultCondition();
        this.onClear.emit();
    }

    setDefaultCondition() {
        this.condition = Object.assign({}, this.defaultCondition);
        this.changeDetector.detectChanges();
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchClick();
                break;
            default:
                break;
        }
    }

}
