export interface OrderDetailIndividualAddressView {
    orderAddressRoleCode: string;
    addressTypeCode: string;
    addressTypeName: string;
    addressLine1: string;
    addressLine2: string;
    state: string;
    province: string;
    city: string;
    postalCode: string;
    countryCode: string;
    countryName: string;
    orderId: string;
    addressId: string;
    primaryFlag: boolean;
    orderSalesStatusCode: string;
}