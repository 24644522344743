import { PriceAmountModel } from ".";

export interface PriceIndividualModel {
    priceIndividualId: string;
    referencePriceIndividualId: string;
    individualAgeGroupCode: string;
    inRelationToIndividualAgeGroupCode: string;
    individualSocialTypeCode: string;
    arithmeticOperator: string;
    amount: PriceAmountModel;
}