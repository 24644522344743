export interface OrderCancelPriceDetailView {
    priceGroupCode: string;
    priceGroupName: string;
    priceName: string;
    priceCurrency: string;
    priceAmount: string;
    salesCurrency: string;
    salesAmount: string;
    exchangeRate: string;
    priceRuleId: string;
    displaySequence: number;
    groupIndex: number;
}