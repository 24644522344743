import { SecurityGroupSecurityModel } from "../../models/security-model/security-group-security.model";
import { OopsCategoryContextMenu } from "./context-menu/categories-context-menu.model";

export interface OopsCategory {
    name: string;
    url: string;
    modifiedDateTime: string;
    modifiedBy: string;
    security?: SecurityGroupSecurityModel;
    contextMenus?: OopsCategoryContextMenu[];
    productCount: number;
}