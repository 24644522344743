import { Injectable } from '@angular/core'
import {
    OrderHeaderModel,
    OrderDetailAttributeChoiceModel,
    OrderDetailAttributeModel,
    OrderDetailCommentModel,
    OrderDetailIndividualAddressModel,
    OrderDetailIndividualMembershipModel,
    OrderDetailIndividualModel,
    OrderDetailIndividualPhoneModel,
    OrderDetailPaymentModel,
    OrderDetailServiceModel,
    OrderIndividualAccountableDocumentCouponModel,
    OrderIndividualAccountableDocumentModel,
    OrderIndividualCommentModel,
    OrderIndividualDocumentModel,

    OrderProductCommentModel,
    OrderProductModel,
    OrderDetailIndividualAccountingModel,
    OrderDetailPartnerModel,
    OrderDetailPartnerPhoneModel,
    OrderDetailPartnerAddressModel,
    OrderDetailPartnerMembershipModel,
    OrderPartnerCommentModel,
    OrderHeaderLockModel,
    OrderDetailIndividualCommunicationOtherModel,
    OrderHeaderPointOfSaleModel,
    OrderDetailProductVoucherModel,
} from '../../../core/models/order-model';
import {
    TransportViewModel,
    ProductPointViewModel
} from '../../../core/models/product-model/transport-model';
import { ProductIrregularityViewModel } from '../../../core/models/product-model/operating-product-model';
import { WeatherForecastModel } from '../../../core/models/weather-forecast-model';

import { OrderAdvanceSearchCriteria, OrderDetailHeaderLockView, OrderDetailIndividualCommunicationOtherView, OrderDetailPartnerCommentView, OrderDetailPartnerMembershipView, OrderDetailPartnerPhoneView, OrderDetailPartnerView, OrderSearchCriteria, OrderSearchView, flightSelectionCalendarOption } from '.';
import { StringHelperService } from '../../../core/utils/string-helper.service';
import {
    OrderDetailAttributeView,
    OrderDetailHeaderView,
    OrderDetailCommentView,
    OrderDetailIndividualAddressView,
    OrderDetailIndividualMembershipView,
    OrderDetailIndividualPhoneView,
    OrderDetailPaymentView,
    OrderDetailServiceView,
    OrderDetailIndividualAccountableDocumentCouponView,
    OrderIndividualAccountableDocumentView,
    OrderDetailIndividualView,
    OrderDetailIndividualDocumentView,
    OrderDetailHeaderPointOfSaleView,
    OrderDetailProductCommentView,
    OrderDetailProductDetailView,
    OrderDetailProductListView,
    OrderDetailProductDetailIrregularityView,
    OrderDetailIndividualAccountingView,
} from '.';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';
import { Select2Data } from '../../../shared/ui/forms/inputs/oops-select2';
import { OopsChoiceValueConstant } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.constant';

import { OrderDetailPartnerAddressView } from './views/order-detail-partner-address.view';
import { OrderSalesStatusCode } from 'src/app/core/constants/order-constants';
import { cloneDeep } from "lodash";
import { OrderCommandModel } from 'src/app/core/models/order-model/order';
import { OrderSearchFilterCommand } from 'src/app/core/models/order-model/order-filter';
import { OrderDetailProductVoucherView } from './views/order-detail-product-voucher.view';

const marketingProductNumberTypeCode: string = "MARKETING";
const operatingProductNumberTypeCode: string = "OPERATING";
const departureTerminalCode: string = "DEPTTERMINAL";
const departureGateCode: string = "DEPTGATE";
const arrivalTerminalCode: string = "ARRTERMINAL";
const arrivalGateCode: string = "ARRGATE";

@Injectable()
export class OrdersMapperService {

    constructor(private stringUtils: StringHelperService,
        private dateConversion: DateConverterService) {

    }

    public toOrderDetailHeaderView(orderHeaderModel: OrderHeaderModel): OrderDetailHeaderView {
        return {
            orderLock: orderHeaderModel.orderLock,
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderSalesStatusCode),
            orderSalesStatus: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderSalesStatus),
            orderDeliveryStatusCode: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderDeliveryStatusCode),
            orderDeliveryStatus: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderDeliveryStatus),
            orderPaymentStatusCode: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderPaymentStatusCode),
            orderPaymentStatus: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderPaymentStatus),
            currency: this.stringUtils.undefinedToEmpty(orderHeaderModel.currency),
            totalAmount: this.stringUtils.validateNullAmountDecimal(orderHeaderModel.totalAmount),
            paidAmount: this.stringUtils.validateNullAmountDecimal(orderHeaderModel.paidAmount),
            outStandingBalance: this.stringUtils.validateNullAmountDecimal(orderHeaderModel.outStandingBalance),
            orderTypeName: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderTypeName),
            orderTypeCode: this.stringUtils.undefinedToEmpty(orderHeaderModel.orderTypeCode),
            orderDateTime: this.dateConversion.convertDateTime24(orderHeaderModel.orderDateTime),
            numberOfUnits: this.stringUtils.validateNullAmount(orderHeaderModel.numberOfUnits),
            commitDateTime: this.dateConversion.convertDateTime24UTC(orderHeaderModel.commitDateTime),
            commitByName: this.stringUtils.undefinedToEmpty(orderHeaderModel.commitByName),
            pointOfSale: this.toOrderDetailHeaderPointOfSale(orderHeaderModel),
            orderLockDetail: this.toOrderDetailHeaderLockView(orderHeaderModel.orderLockDetail),
            showDetail: false
        };
    }

    public toOrderDetailHeaderLockView(model: OrderHeaderLockModel): OrderDetailHeaderLockView {
        if (!model) {
            return null;
        }
        return {
            lockedBy: model.lockedBy,
            lockedDateTime: this.dateConversion.convertDateTime24(model.lockedDateTime),
            expiryDateTime: this.dateConversion.convertDateTime24(model.expiryDateTime),
            organisationCallName: model.organisationCallName,
            localLockedDateTime: model.localLockedDateTime,
            localExpiryDateTime: model.localExpiryDateTime
        }
    }

    public toOrderDetailIndividualViews(orderIndividualModels: Array<OrderDetailIndividualModel>,
        onlyConfirm: boolean = false): OrderDetailIndividualView[] {

        const statusToFilter: string[] = [OrderSalesStatusCode.Confirm];
        if (!orderIndividualModels || orderIndividualModels.length === 0) {
            return null;
        }
        
        let individualOrderFilter: OrderDetailIndividualModel[] = orderIndividualModels;
        if (onlyConfirm) {
            individualOrderFilter = orderIndividualModels.filter(o => statusToFilter.includes(o.orderSalesStatusCode));
        }
        
        let individuals = new Array<OrderDetailIndividualView>();
        for (let individual of individualOrderFilter) {
            individuals.push(this.toOrderDetailIndividualView(individual, onlyConfirm));
        }
        return individuals;
    }
    public toOrderDetailPartnerViews(orderIndividualModels: Array<OrderDetailPartnerModel>): OrderDetailPartnerView[] {
        if (!orderIndividualModels || orderIndividualModels.length === 0) {
            return null;
        }
        let partners = new Array<OrderDetailPartnerView>();
        for (let partner of orderIndividualModels) {
            partners.push(this.toOrderDetailPartnerView(partner));
        }
        return partners;
    }

    public toOrderDetailPartnerView(orderIndividualModel: OrderDetailPartnerModel): OrderDetailPartnerView {
        return {
            individualId: this.stringUtils.undefinedToEmpty(orderIndividualModel.individualId),
            orderId: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderId),
            individualNameId: this.stringUtils.undefinedToEmpty(orderIndividualModel.individualNameId),
            lastName: this.stringUtils.undefinedToEmpty(orderIndividualModel.lastName),
            firstName: this.stringUtils.undefinedToEmpty(orderIndividualModel.firstName),
            title: this.stringUtils.undefinedToEmpty(orderIndividualModel.title),
            ageGroup: this.stringUtils.undefinedToEmpty(orderIndividualModel.ageGroup),
            dateOfBirth: this.dateConversion.toDateFormat(orderIndividualModel.dateOfBirth),
            genderName: this.stringUtils.undefinedToEmpty(orderIndividualModel.genderName),
            languageCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.languageCode),
            languageName: this.stringUtils.undefinedToEmpty(orderIndividualModel.languageName),
            nationalityCountryCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.nationalityCountryCode),
            mailAddress: this.stringUtils.undefinedToEmpty(orderIndividualModel.mailAddress),
            phoneNumber: this.stringUtils.undefinedToEmpty(orderIndividualModel.phoneNumber),
            phoneType: this.stringUtils.undefinedToEmpty(orderIndividualModel.phoneType),
            membershipLevelCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.membershipLevelCode),
            membershipLevelName: this.stringUtils.undefinedToEmpty(orderIndividualModel.membershipLevelName),
            comments: this.toOrderDetailPartnerCommentViews(orderIndividualModel.comments),
            countryPhonePrefix: this.stringUtils.undefinedToEmpty(orderIndividualModel.countryPhonePrefix),
            organisationCallName: this.stringUtils.undefinedToEmpty(orderIndividualModel.organisationCallName),
            orderPartnerRoleName: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderPartnerRoleName),
            orderIndividualPhones: this.toOrderDetailPartnerPhones(orderIndividualModel.orderIndividualPhones),
            orderIndividualAddresses: this.toOrderDetailPartnerAddresses(orderIndividualModel.orderIndividualAddresses),
            orderIndividualMemberships: this.toOrderDetailPartnerMemberships(orderIndividualModel.orderIndividualMemberships),
            showDetail: false
        };
    }

    public toOrderDetailIndividualView(orderIndividualModel: OrderDetailIndividualModel,
        onlyConfirm: boolean): OrderDetailIndividualView {
        return {
            individualId: this.stringUtils.undefinedToEmpty(orderIndividualModel.individualId),
            orderId: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderId),
            headerOrderId: this.stringUtils.undefinedToEmpty(orderIndividualModel.headerOrderId),
            individualNameId: this.stringUtils.undefinedToEmpty(orderIndividualModel.individualNameId),
            lastName: this.stringUtils.undefinedToEmpty(orderIndividualModel.lastName),
            firstName: this.stringUtils.undefinedToEmpty(orderIndividualModel.firstName),
            titleName: this.stringUtils.undefinedToEmpty(orderIndividualModel.titleName),
            titleCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.titleCode),
            ageGroupName: this.stringUtils.undefinedToEmpty(orderIndividualModel.ageGroupName),
            ageGroupCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.ageGroupCode),
            dateOfBirth: this.dateConversion.toDateFormat(orderIndividualModel.dateOfBirth),
            genderName: this.stringUtils.undefinedToEmpty(orderIndividualModel.genderName),
            genderCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.genderCode),
            languageCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.languageCode),
            languageName: this.stringUtils.undefinedToEmpty(orderIndividualModel.languageName),
            nationalityCountryCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.nationalityCountryCode),
            mailAddress: this.stringUtils.undefinedToEmpty(orderIndividualModel.mailAddress),
            phoneNumber: this.stringUtils.undefinedToEmpty(orderIndividualModel.phoneNumber),
            phoneTypeName: this.stringUtils.undefinedToEmpty(orderIndividualModel.phoneTypeName),
            phoneTypeCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.phoneTypeCode),
            membershipLevelCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.membershipLevelCode),
            membershipLevelName: this.stringUtils.undefinedToEmpty(orderIndividualModel.membershipLevelName),
            comments: this.toOrderDetailIndividualCommentViews(orderIndividualModel.comments),
            countryPhonePrefix: this.stringUtils.undefinedToEmpty(orderIndividualModel.countryPhonePrefix),
            organisationCallName: this.stringUtils.undefinedToEmpty(orderIndividualModel.organisationCallName),
            orderPartnerRoleName: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderPartnerRoleName),
            disablePartner: orderIndividualModel.disablePartner,
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderSalesStatusCode),
            orderSalesStatusName: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderSalesStatusName),
            orderDeliveryStatusCode: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderDeliveryStatusCode),
            orderDeliveryStatusName: this.stringUtils.undefinedToEmpty(orderIndividualModel.orderDeliveryStatusName),
            orderIndividualPhones: this.toOrderDetailIndividualPhones(orderIndividualModel.orderIndividualPhones,
                onlyConfirm),
            orderIndividualAddresses: this.toOrderDetailIndividualAddresses(orderIndividualModel.orderIndividualAddresses,
                onlyConfirm),
            orderIndividualMemberships: this.toOrderDetailIndividualMemberships(orderIndividualModel.orderIndividualMemberships),
            orderIndividualCommunicationOthers: this.toOrderDetailIndividualCommunicationOthers(orderIndividualModel.orderIndividualCommunicationOthers,
                onlyConfirm),
            showDetail: false,
            showChangeSeat: false
        };
    }
    private toOrderDetailIndividualCommunicationOthers(communicationOtherModels: OrderDetailIndividualCommunicationOtherModel[],
        onlyConfirm: boolean = false): OrderDetailIndividualCommunicationOtherView[] {

        if (!communicationOtherModels || communicationOtherModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualCommunicationOtherModel>()
        for (let model of communicationOtherModels) {
            if (onlyConfirm &&
                (this.stringUtils.undefinedToEmpty(model.orderSalesStatusCode) != "" &&
                    model.orderSalesStatusCode == OrderSalesStatusCode.Cancel)) continue;
            views.push(this.toOrderDetailIndividualCommunicationOther(model));
        }
        return views;
    }
    private toOrderDetailIndividualCommunicationOther(communicationOtherModel: OrderDetailIndividualCommunicationOtherModel)
        : OrderDetailIndividualCommunicationOtherView {

        return {
            orderId: this.stringUtils.undefinedToEmpty(communicationOtherModel.orderId),
            communicationOtherId: this.stringUtils.undefinedToEmpty(communicationOtherModel.communicationOtherId),
            communicationOtherTypeCode: this.stringUtils.undefinedToEmpty(communicationOtherModel.communicationOtherTypeCode),
            communicationOtherTypeName: this.stringUtils.undefinedToEmpty(communicationOtherModel.communicationOtherTypeName),
            mailAddress: this.stringUtils.undefinedToEmpty(communicationOtherModel.mailAddress),
            primaryFlag: communicationOtherModel.primaryFlag,
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(communicationOtherModel.orderSalesStatusCode)
        };
    }
    private toOrderDetailIndividualMemberships(membershipModels: OrderDetailIndividualMembershipModel[])
        : OrderDetailIndividualMembershipView[] {

        if (!membershipModels || membershipModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualMembershipView>()
        for (let phone of membershipModels) {
            views.push(this.toOrderDetailIndividualMembership(phone));
        }
        return views;
    }

    private toOrderDetailPartnerMemberships(membershipModels: OrderDetailPartnerMembershipModel[])
        : OrderDetailPartnerMembershipView[] {

        if (!membershipModels || membershipModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailPartnerMembershipView>()
        for (let phone of membershipModels) {
            views.push(this.toOrderDetailPartnerMembership(phone));
        }
        return views;
    }

    private toOrderDetailIndividualMembership(membershipModel: OrderDetailIndividualMembershipModel)
        : OrderDetailIndividualMembershipView {

        return {
            providerCode: this.stringUtils.undefinedToEmpty(membershipModel.providerCode),
            membershipLevelCode: this.stringUtils.undefinedToEmpty(membershipModel.membershipLevelCode),
            membershipLevelName: this.stringUtils.undefinedToEmpty(membershipModel.membershipLevelName),
            memberNumber: this.stringUtils.undefinedToEmpty(membershipModel.memberNumber),
            providerId: this.stringUtils.undefinedToEmpty(membershipModel.providerId)
        };
    }
    private toOrderDetailPartnerMembership(membershipModel: OrderDetailPartnerMembershipModel)
        : OrderDetailPartnerMembershipView {

        return {
            providerCode: this.stringUtils.undefinedToEmpty(membershipModel.providerCode),
            membershipLevelCode: this.stringUtils.undefinedToEmpty(membershipModel.membershipLevelCode),
            membershipLevelName: this.stringUtils.undefinedToEmpty(membershipModel.membershipLevelName),
            memberNumber: this.stringUtils.undefinedToEmpty(membershipModel.memberNumber),
            providerId: this.stringUtils.undefinedToEmpty(membershipModel.providerId)
        };
    }
    private toOrderDetailIndividualAddresses(addressModels: OrderDetailIndividualAddressModel[],
        onlyConfirm: boolean = false)
        : OrderDetailIndividualAddressView[] {

        if (!addressModels || addressModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualAddressView>()
        for (let address of addressModels) {
            if (onlyConfirm &&
                (this.stringUtils.undefinedToEmpty(address.orderSalesStatusCode) != "" &&
                    address.orderSalesStatusCode == OrderSalesStatusCode.Cancel)) continue;

            views.push(this.toOrderDetailIndividualAddresss(address));
        }
        return views;
    }

    private toOrderDetailPartnerAddresses(phoneModels: OrderDetailPartnerAddressModel[])
        : OrderDetailPartnerAddressView[] {

        if (!phoneModels || phoneModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailPartnerAddressView>()
        for (let phone of phoneModels) {
            views.push(this.toOrderDetailPartnerAddresss(phone));
        }
        return views;
    }
    private toOrderDetailPartnerAddresss(addressModel: OrderDetailPartnerAddressModel)
        : OrderDetailPartnerAddressView {

        return {
            orderAddressRoleCode: this.stringUtils.undefinedToEmpty(addressModel.orderAddressRoleCode),
            addressTypeCode: this.stringUtils.undefinedToEmpty(addressModel.addressTypeCode),
            addressTypeName: this.stringUtils.undefinedToEmpty(addressModel.addressTypeName),
            addressLine1: this.stringUtils.undefinedToEmpty(addressModel.addressLine1),
            addressLine2: this.stringUtils.undefinedToEmpty(addressModel.addressLine2),
            state: this.stringUtils.undefinedToEmpty(addressModel.state),
            province: this.stringUtils.undefinedToEmpty(addressModel.province),
            city: this.stringUtils.undefinedToEmpty(addressModel.city),
            postalCode: this.stringUtils.undefinedToEmpty(addressModel.postalCode),
            countryCode: this.stringUtils.undefinedToEmpty(addressModel.countryCode),
            countryName: this.stringUtils.undefinedToEmpty(addressModel.countryName),
            orderId: this.stringUtils.undefinedToEmpty(addressModel.orderId),
            addressId: this.stringUtils.undefinedToEmpty(addressModel.addressId),
            primaryFlag: addressModel.primaryFlag,
            orderSalesStatusCode: addressModel.orderSalesStatusCode
        };
    }
    private toOrderDetailIndividualAddresss(addressModel: OrderDetailIndividualAddressModel)
        : OrderDetailIndividualAddressView {

        return {
            orderAddressRoleCode: this.stringUtils.undefinedToEmpty(addressModel.orderAddressRoleCode),
            addressTypeCode: this.stringUtils.undefinedToEmpty(addressModel.addressTypeCode),
            addressTypeName: this.stringUtils.undefinedToEmpty(addressModel.addressTypeName),
            addressLine1: this.stringUtils.undefinedToEmpty(addressModel.addressLine1),
            addressLine2: this.stringUtils.undefinedToEmpty(addressModel.addressLine2),
            state: this.stringUtils.undefinedToEmpty(addressModel.state),
            province: this.stringUtils.undefinedToEmpty(addressModel.province),
            city: this.stringUtils.undefinedToEmpty(addressModel.city),
            postalCode: this.stringUtils.undefinedToEmpty(addressModel.postalCode),
            countryCode: this.stringUtils.undefinedToEmpty(addressModel.countryCode),
            countryName: this.stringUtils.undefinedToEmpty(addressModel.countryName),
            orderId: this.stringUtils.undefinedToEmpty(addressModel.orderId),
            addressId: this.stringUtils.undefinedToEmpty(addressModel.addressId),
            primaryFlag: addressModel.primaryFlag,
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(addressModel.orderSalesStatusCode)
        };
    }

    private toOrderDetailIndividualPhones(phoneModels: OrderDetailIndividualPhoneModel[],
        onlyConfirm: boolean = false)
        : OrderDetailIndividualPhoneView[] {

        if (!phoneModels || phoneModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualPhoneView>()
        for (let phone of phoneModels) {
            if (onlyConfirm &&
                (this.stringUtils.undefinedToEmpty(phone.orderSalesStatusCode) != "" &&
                    phone.orderSalesStatusCode == OrderSalesStatusCode.Cancel)) continue;

            views.push(this.toOrderDetailIndividualPhone(phone));
        }
        if (views?.length == 1) views[0].primaryFlag = true;
        return views;
    }

    private toOrderDetailPartnerPhones(phoneModels: OrderDetailPartnerPhoneModel[])
        : OrderDetailPartnerPhoneView[] {

        if (!phoneModels || phoneModels.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailPartnerPhoneView>()
        for (let phone of phoneModels) {
            views.push(this.toOrderDetailPartnerPhone(phone));
        }
        return views;
    }
    private toOrderDetailPartnerPhone(phoneModel: OrderDetailPartnerPhoneModel)
        : OrderDetailPartnerPhoneView {
        return {
            orderId: this.stringUtils.undefinedToEmpty(phoneModel.orderId),
            countryPhonePrefix: this.stringUtils.undefinedToEmpty(phoneModel.countryPhonePrefix),
            communicationPhoneTypeCode: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneTypeCode),
            communicationPhoneTypeName: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneTypeName),
            phoneNumber: this.stringUtils.undefinedToEmpty(phoneModel.phoneNumber),
            primaryFlag: phoneModel.primaryFlag,
            communicationPhoneId: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneId),
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(phoneModel.orderSalesStatusCode)
        };
    }
    private toOrderDetailIndividualPhone(phoneModel: OrderDetailIndividualPhoneModel)
        : OrderDetailIndividualPhoneView {
        return {
            orderId: this.stringUtils.undefinedToEmpty(phoneModel.orderId),
            countryPhonePrefix: this.stringUtils.undefinedToEmpty(phoneModel.countryPhonePrefix),
            communicationPhoneTypeCode: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneTypeCode),
            communicationPhoneTypeName: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneTypeName),
            phoneNumber: this.stringUtils.undefinedToEmpty(phoneModel.phoneNumber),
            primaryFlag: phoneModel.primaryFlag,
            communicationPhoneId: this.stringUtils.undefinedToEmpty(phoneModel.communicationPhoneId),
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(phoneModel.orderSalesStatusCode)
        };
    }

    public toOrderDetailIndividualCommentViews(individualComments: OrderIndividualCommentModel[]): OrderDetailCommentView[] {
        if (!individualComments || individualComments.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailCommentView>()
        for (let comment of individualComments) {
            views.push(this.toOrderDetailIndividualCommentView(comment));
        }
        return views;
    }

    public toOrderDetailPartnerCommentViews(individualComments: OrderPartnerCommentModel[])
        : OrderDetailPartnerCommentView[] {

        if (!individualComments || individualComments.length === 0) {
            return null;
        }
        let views = new Array<OrderDetailPartnerCommentView>()
        for (let comment of individualComments) {
            views.push(this.toOrderDetailPartnerCommentView(comment));
        }
        return views;
    }

    public toOrderDetailPartnerCommentView(individualComment: OrderPartnerCommentModel): OrderDetailPartnerCommentView {

        return {
            commentTypeCode: this.stringUtils.undefinedToEmpty(individualComment.commentTypeCode),
            commentTypeName: this.stringUtils.undefinedToEmpty(individualComment.commentTypeName),
            commentClassificationCode: this.stringUtils.undefinedToEmpty(individualComment.commentClassificationCode),
            commentClassificationName: this.stringUtils.undefinedToEmpty(individualComment.commentClassificationName),
            commentText: this.stringUtils.undefinedToEmpty(individualComment.commentText),
            commentDate: null,
            commentDateTime: null,
            commentByName: null,
            showDetail: null,
            parentOrderId: null,
            individualId: null,
            orderId: null,
            commentId: null,
            orderCommentId: null
        }
    }

    public toOrderDetailIndividualCommentView(individualComment: OrderIndividualCommentModel): OrderDetailCommentView {
        return {
            commentTypeCode: this.stringUtils.undefinedToEmpty(individualComment.commentTypeCode),
            commentTypeName: this.stringUtils.undefinedToEmpty(individualComment.commentTypeName),
            commentClassificationCode: this.stringUtils.undefinedToEmpty(individualComment.commentClassificationCode),
            commentClassificationName: this.stringUtils.undefinedToEmpty(individualComment.commentClassificationName),
            commentText: this.stringUtils.undefinedToEmpty(individualComment.commentText),
            commentDate: this.dateConversion.toDateFormat(individualComment.commitDateTime),
            commentDateTime: this.dateConversion.convertDate(individualComment.commitDateTime),
            commentByName: this.stringUtils.undefinedToEmpty(individualComment.commitBy),
            showDetail: null,
            parentOrderId: this.stringUtils.undefinedToEmpty(individualComment.parentOrderId),
            individualId: this.stringUtils.undefinedToEmpty(individualComment.individualId),
            orderId: this.stringUtils.undefinedToEmpty(individualComment.orderId),
            commentId: this.stringUtils.undefinedToEmpty(individualComment.commentId),
            orderCommentId: this.stringUtils.undefinedToEmpty(individualComment.orderCommentId)
        }
    }
    private toOrderDetailHeaderPointOfSale(orderHeaderModel: OrderHeaderModel): OrderDetailHeaderPointOfSaleView[] {

        let views = new Array<OrderDetailHeaderPointOfSaleView>();
        if (!orderHeaderModel.pointOfSale) {
            return null;
        }
        let pointOfSalesSortByCondition = new Array<OrderHeaderPointOfSaleModel>();
        this.addPOSOrderNumberTypeCodeORDERAndRLOC(pointOfSalesSortByCondition, orderHeaderModel);
        this.addPOSOrderNumberTypeCodeRLOCSameOrganisationHeader(pointOfSalesSortByCondition, orderHeaderModel);
        this.addPOSOthers(pointOfSalesSortByCondition, orderHeaderModel);
        for (let pointOfSale of pointOfSalesSortByCondition) {
            views.push(this.createOrderDetailHeaderPointOfSale(pointOfSale));
        }
        return views;
    }
	
    private addPOSOrderNumberTypeCodeORDERAndRLOC(pointOfSalesSortByCondition: OrderHeaderPointOfSaleModel[], orderHeaderModel: OrderHeaderModel) {
        let posOrderNumberTypeCodeORDER = orderHeaderModel.pointOfSale.filter(x => x.orderNumberTypeCode == "ORDER");
        if (posOrderNumberTypeCodeORDER?.length) {
            pointOfSalesSortByCondition.push(...posOrderNumberTypeCodeORDER);
            let posNumberOrderTypeCodeRLOC = orderHeaderModel.pointOfSale.filter(x => x.orderNumberTypeCode == "RLOC" && x.organisationId == posOrderNumberTypeCodeORDER[0].organisationId);
            if (posNumberOrderTypeCodeRLOC?.length) {
                pointOfSalesSortByCondition.push(...posNumberOrderTypeCodeRLOC);
            }
        }
    }

    private addPOSOrderNumberTypeCodeRLOCSameOrganisationHeader(pointOfSalesSortByCondition: OrderHeaderPointOfSaleModel[], orderHeaderModel: OrderHeaderModel) {
        let posNumberOrderTypeCodeRLOC = orderHeaderModel.pointOfSale.filter(x => x.orderNumberTypeCode == "RLOC" && x.organisationId == orderHeaderModel.organisationId &&
            !pointOfSalesSortByCondition.includes(x));
        if (posNumberOrderTypeCodeRLOC?.length) {
            pointOfSalesSortByCondition.push(...posNumberOrderTypeCodeRLOC);
        }
    }

    private addPOSOthers(pointOfSalesSortByCondition: OrderHeaderPointOfSaleModel[], orderHeaderModel: OrderHeaderModel) {
        let pointOfSalesOthers = orderHeaderModel.pointOfSale.filter(x => !pointOfSalesSortByCondition.includes(x));
        if (pointOfSalesOthers?.length) {
            pointOfSalesSortByCondition.push(...pointOfSalesOthers);
        }
    }

    private createOrderDetailHeaderPointOfSale(model: OrderHeaderPointOfSaleModel): OrderDetailHeaderPointOfSaleView {
        let view = {} as OrderDetailHeaderPointOfSaleView;
        view.orderPointOfSalesId = model.orderPointOfSalesId;
        view.bookingReference = this.stringUtils.undefinedToEmpty(model.bookingReference);
        view.orderDateTime = this.dateConversion.toDateFormat(model.orderDateTime);
        view.bookingOffice = this.stringUtils.undefinedToEmpty(model.bookingOffice);
        view.officeCode = this.stringUtils.undefinedToEmpty(model.officeCode);
        view.iataCode = this.stringUtils.undefinedToEmpty(model.iataCode);
        view.cityCode = this.stringUtils.undefinedToEmpty(model.cityCode);
        view.provider = this.stringUtils.undefinedToEmpty(model.provider);
        view.userType = this.stringUtils.undefinedToEmpty(model.userType);
        view.countryCode = this.stringUtils.undefinedToEmpty(model.countryCode);
        view.pointOfSaleCurrencyCode = this.stringUtils.undefinedToEmpty(model.pointOfSaleCurrencyCode);
        view.dutyCode = this.stringUtils.undefinedToEmpty(model.dutyCode);
        view.orderNumberTypeCode = this.stringUtils.undefinedToEmpty(model.orderNumberTypeCode);
        return view;
    }

    public toOrderDetailProductListViews(models: OrderProductModel[])
        : OrderDetailProductListView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailProductListView>();
        for (let model of models) {
            views.push(this.toOrderDetailProductListView(model));
        }
        return views;

    }


    private toOrderDetailProductListView(model: OrderProductModel)
        : OrderDetailProductListView {
        return {
            orderId: this.stringUtils.undefinedToEmpty(model.orderId),
            productId: this.stringUtils.undefinedToEmpty(this.getProductId(model)),
            productCategoryCode: this.stringUtils.undefinedToEmpty(model.productCategoryCode),
            productCategoryName: this.stringUtils.undefinedToEmpty(model.productCategoryName),
            productGroupCode: this.stringUtils.undefinedToEmpty(model.productGroupCode),
            productGroupName: this.stringUtils.undefinedToEmpty(model.productGroupName),
            orderSalesStatusCode: model.orderSalesStatusCode,
            orderSalesStatusName: this.stringUtils.undefinedToEmpty(model.orderSalesStatusName),
            orderDeliveryStatusCode: model.orderDeliveryStatusCode,
            orderDeliveryStatusName: this.stringUtils.undefinedToEmpty(model.orderDeliveryStatusName),
            orderPaymentStatusCode: this.stringUtils.undefinedToEmpty(model.orderPaymentStatusCode),
            orderPaymentStatusName: this.stringUtils.undefinedToEmpty(model.orderPaymentStatusName),
            serviceCategoryCode: this.stringUtils.undefinedToEmpty(model.serviceCategoryCode),
            serviceCategoryName: this.stringUtils.undefinedToEmpty(model.serviceCategoryName),
            currencyCode: this.validateAndFillCurrencyCode(this.stringUtils.undefinedToEmpty(model.currencyCode), model.productAmount),
            ownerProviderCode: this.stringUtils.undefinedToEmpty(model.ownerProviderCode),
            ownerProviderNumber: this.stringUtils.undefinedToEmpty(model.ownerProviderNumber),
            providerCode: this.stringUtils.undefinedToEmpty(model.providerCode),
            providerNumber: this.stringUtils.undefinedToEmpty(model.providerNumber),
            date: this.dateConversion.toDateFormat(this.dateConversion.convertToUtcDate(model.std?.toString())),
            std: this.dateConversion.convertTime24UTC(model.std),
            sta: this.dateConversion.convertTime24UTC(model.sta),
            dayChange: this.getChangeDay(model.std, model.sta),
            departureCode: this.stringUtils.undefinedToEmpty(model.departureCode),
            departureName: this.stringUtils.undefinedToEmpty(model.departureName),
            arrivalCode: this.stringUtils.undefinedToEmpty(model.arrivalCode),
            arrivalName: this.stringUtils.undefinedToEmpty(model.arrivalName),
            comments: this.toOrderDetailProductCommentViews(model.comments),
            productAmount: this.stringUtils.validateNullAmountDecimal(model.productAmount),
            paymentAmount: this.stringUtils.validateNullAmountDecimal(model.paymentAmount),
            productItemOutstandingBalance: this.stringUtils.validateNullAmountDecimal(model.productAmount - model.paymentAmount),
            outstandingBalance: this.stringUtils.validateNullAmountDecimal(model.outstandingBalance),
            freetext: this.stringUtils.undefinedToEmpty(model.freetext),
            productNumber: this.stringUtils.undefinedToEmpty(model.productNumber),
            productName: this.stringUtils.undefinedToEmpty(model.productName),
            productTypeCode: this.stringUtils.undefinedToEmpty(model.productTypeCode),
            productTypeName: this.stringUtils.undefinedToEmpty(model.productTypeName),
            showDetail: false,
            seatNumber: this.stringUtils.undefinedToEmpty(model.seatNumber),
            commitByName: model.commitByName,
            commitDateTime: this.dateConversion.convertDateTime24UTC(model.commitDateTime),
            noneTransportValue: this.showTransportValue(model.noneTransportValuePercentage, model.noneTransportValue),
            noneTransportValuePercentage: model.noneTransportValuePercentage,
            noneTransportValueCurrencyCode: this.stringUtils.undefinedToEmpty(model.noneTransportValueCurrencyCode),
            noneTransportValueCurrencyName: this.stringUtils.undefinedToEmpty(model.noneTransportValueCurrencyName),
            orderProductText: this.stringUtils.undefinedToEmpty(model.orderProductText),
            numberOfUnits: this.stringUtils.validateNullAmount(model.numberOfUnits),
            productChangeData: {
                showChangeForm: false,
                departureAirportCode: "",
                arrivalAirportCode: "",
                departureDate: "",
                serviceCategoryCode: "",
                nextSearchDate: null,
                calendarOption: cloneDeep(flightSelectionCalendarOption),
                showError: false,
                showChangeSeat: false
            },
            individualIds: model.individualIds,
            productOptionFlag: model.productOptionFlag,
            optionProductId: model.optionProductId,
            vouchers: this.getVouchers(model.vouchers),
            priceRuleDisplayName: model.priceRuleDisplayName
        };
    }

    private getVouchers(vouchers: OrderDetailProductVoucherModel[]): OrderDetailProductVoucherView[] {
        if (!vouchers) return null;

        let series = new Array<OrderDetailProductVoucherView>();

        for (let voucher of vouchers) {
            let serie = {} as OrderDetailProductVoucherView; 
            serie.voucherNumber = voucher.voucherNumber;
            serie.value = voucher.value;
            serie.voucherStatusName = voucher.voucherStatusName;
            serie.expiryDate = this.dateConversion.toDateFormat(voucher.expiryDate);
            serie.validFromDate = this.dateConversion.toDateFormat(voucher.validFromDate);
            serie.validToDate = this.dateConversion.toDateFormat(voucher.validToDate);
            series.push(serie);
        }

        return series;
    }

    private showTransportValue(percentage: boolean, amount: number): string {
        if (percentage) {
            return this.stringUtils.validateNullAmount(amount);
        }
        return this.stringUtils.validateNullAmountDecimal(amount)
    }
    private validateAndFillCurrencyCode(currencyCode: string, amount: number) {
        if (!amount || amount == 0) {
            return null;
        }
        return currencyCode;
    }

    private getChangeDay(std: Date, sta: Date): number {
        const milisecInADay = 86400000;

        if (!std || !sta) {
            return null;
        }
        let stdDate = this.dateConversion.convertToUtcDate(std.toString());
        let staDate = this.dateConversion.convertToUtcDate(sta.toString());

        let startDate = new Date(stdDate.getFullYear(), stdDate.getMonth(), stdDate.getDate());
        let endDate = new Date(staDate.getFullYear(), staDate.getMonth(), staDate.getDate());

        let difference = Math.floor((endDate.getTime() - startDate.getTime()) / milisecInADay);
        if (difference == 0) {
            return null;
        }
        return difference
    }

    private getProductId(model: OrderProductModel) {
        if (model.operatingProductId) {
            return model.operatingProductId;
        }
        return model.productId;
    }
    private toOrderDetailProductCommentViews(models: OrderProductCommentModel[])
        : OrderDetailProductCommentView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailProductCommentView>();
        for (let model of models) {
            views.push(this.toOrderDetailProductCommentView(model));
        }
        return views;
    }

    private toOrderDetailProductCommentView(model: OrderProductCommentModel)
        : OrderDetailProductCommentView {
        return {
            commentTypeCode: this.stringUtils.undefinedToEmpty(model.commentTypeCode),
            commentTypeName: this.stringUtils.undefinedToEmpty(model.commentTypeName),
            commentClassificationCode: this.stringUtils.undefinedToEmpty(model.commentClassificationCode),
            commentClassificationName: this.stringUtils.undefinedToEmpty(model.commentClassificationName),
            commentText: this.stringUtils.undefinedToEmpty(model.commentText)
        };
    }

    public toIndividualDocumentViews(models: OrderIndividualDocumentModel[],
        onlyConfirm: boolean = false
    )
        : OrderDetailIndividualDocumentView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualDocumentView>();
        for (let model of models) {
            
            if (onlyConfirm &&
                (this.stringUtils.undefinedToEmpty(model.orderSalesStatusCode) != "" &&
                model.orderSalesStatusCode == OrderSalesStatusCode.Cancel)) {
                    continue;
                }

            views.push(this.toIndividualDocumentView(model));
        }
        return views;

    }
    private toIndividualDocumentView(model: OrderIndividualDocumentModel)
        : OrderDetailIndividualDocumentView {
        return {
            identityDocumentId: this.stringUtils.undefinedToEmpty(model.identityDocumentId),
            identityDocumentCode: this.stringUtils.undefinedToEmpty(model.identityDocumentCode),
            identityDocumentName: this.stringUtils.undefinedToEmpty(model.identityDocumentName),
            identityDocumentNumber: this.stringUtils.undefinedToEmpty(model.identityDocumentNumber),
            countryCode: this.stringUtils.undefinedToEmpty(model.countryCode),
            countryName: this.stringUtils.undefinedToEmpty(model.countryName),
            expiryDate: this.dateConversion.toDateFormat(model.expiryDate),
            lastName: this.stringUtils.undefinedToEmpty(model.lastName),
            firstName: this.stringUtils.undefinedToEmpty(model.firstName),
            title: this.stringUtils.undefinedToEmpty(model.title),
            allowedEdit: model.allowedEdit,
            showDetail: false,
            individualId: this.stringUtils.undefinedToEmpty(model.individualId),
            orderId: this.stringUtils.undefinedToEmpty(model.orderId),
            partnerOrderId: this.stringUtils.undefinedToEmpty(model.partnerOrderId),
            issueDate: this.dateConversion.toDateFormat(model.issueDate),
            placeOfIssue: this.stringUtils.undefinedToEmpty(model.placeOfIssue),
            orderSalesStatusCode: this.stringUtils.undefinedToEmpty(model.orderSalesStatusCode)
        };
    }

    public toIndividualAccountableDocumentViews(models: OrderIndividualAccountableDocumentModel[])
        : OrderIndividualAccountableDocumentView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderIndividualAccountableDocumentView>();
        for (let model of models) {
            views.push(this.toIndividualAccountableDocumentView(model));
        }
        return views;

    }
    private toIndividualAccountableDocumentView(model: OrderIndividualAccountableDocumentModel)
        : OrderIndividualAccountableDocumentView {
        return {
            orderDocumentId: this.stringUtils.undefinedToEmpty(model.orderDocumentId),
            accountableDocumentTypeCode: this.stringUtils.undefinedToEmpty(model.accountableDocumentTypeCode),
            accountableDocumentNumber: this.stringUtils.undefinedToEmpty(model.accountableDocumentNumber),
            accountableDocumentSeriesNumber: this.stringUtils.undefinedToEmpty(model.accountableDocumentSeriesNumber),
            accountableDocumentCouponNumber: this.stringUtils.undefinedToEmpty(model.accountableDocumentCouponNumber),
            accountableDocumentTypeName: this.stringUtils.undefinedToEmpty(model.accountableDocumentTypeName),
            lastName: this.stringUtils.undefinedToEmpty(model.lastName),
            firstName: this.stringUtils.undefinedToEmpty(model.firstName),
            title: this.stringUtils.undefinedToEmpty(model.title),
            individualId: this.stringUtils.undefinedToEmpty(model.individualId),
            showDetail: false,
            dateOfIssue: this.dateConversion.toDateFormat(model.dateOfIssue),
            currencyCode: this.stringUtils.undefinedToEmpty(model.currencyCode),
            amount: this.stringUtils.validateNullAmountDecimal(model.amount),
            departure: this.stringUtils.undefinedToEmpty(model.departure),
            arrival: this.stringUtils.undefinedToEmpty(model.arrival),
            associateDocumentNumber: this.stringUtils.undefinedToEmpty(model.associateDocumentNumber),
            numberOfBooklets: this.stringUtils.undefinedToEmpty(model.numberOfBooklets),
            originator: this.stringUtils.undefinedToEmpty(model.originator),
            originatorCity: this.stringUtils.undefinedToEmpty(model.originatorCity),
            agencyIataNumber: this.stringUtils.undefinedToEmpty(model.agencyIataNumber),
            agencyPseudo: this.stringUtils.undefinedToEmpty(model.agencyPseudo),
            agencyCity: this.stringUtils.undefinedToEmpty(model.agencyCity),
            agencyCountry: this.stringUtils.undefinedToEmpty(model.agencyCountry),
            agencyCurrency: this.stringUtils.undefinedToEmpty(model.agencyCurrency),
            tourCode: this.stringUtils.undefinedToEmpty(model.tourCode),
            rfic: this.stringUtils.undefinedToEmpty(model.rfic),
            fareLine: this.stringUtils.undefinedToEmpty(model.fareLine),
            orderId: this.stringUtils.undefinedToEmpty(model.orderId),
            partnerOrderId: this.stringUtils.undefinedToEmpty(model.partnerOrderId),
            coupons: this.toAccountableDocumentCoupons(model.coupons)
        };
    }

    private toAccountableDocumentCoupons(coupons: OrderIndividualAccountableDocumentCouponModel[])
        : OrderDetailIndividualAccountableDocumentCouponView[] {

        if (!coupons || coupons.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualAccountableDocumentCouponView>()
        for (let coupon of coupons) {
            views.push(this.toAccountableDocumentCoupon(coupon));
        }
        return views;
    }

    private toAccountableDocumentCoupon(coupon: OrderIndividualAccountableDocumentCouponModel)
        : OrderDetailIndividualAccountableDocumentCouponView {
        return {
            couponNumber: coupon.couponNumber,
            couponStatus: this.stringUtils.undefinedToEmpty(coupon.couponStatus),
            serviceDate: this.dateConversion.toDateFormat(coupon.serviceDate),
            associatedCouponNumber: coupon.associatedCouponNumber,
            operatingAirlineNumber: this.stringUtils.undefinedToEmpty(coupon.operatingAirlineNumber),
            marketingAirlineNumber: this.stringUtils.undefinedToEmpty(coupon.marketingAirlineNumber),
            departure: this.stringUtils.undefinedToEmpty(coupon.departure),
            arrival: this.stringUtils.undefinedToEmpty(coupon.arrival),
            requestedStatus: this.stringUtils.undefinedToEmpty(coupon.requestedStatus),
            inventoryClass: this.stringUtils.undefinedToEmpty(coupon.inventoryClass),
            serviceClass: this.stringUtils.undefinedToEmpty(coupon.serviceClass),
            fareBasis: this.stringUtils.undefinedToEmpty(coupon.fareBasis),
            couponAmount: this.stringUtils.validateNullAmountDecimal(coupon.couponAmount),
            notValidBefore: this.dateConversion.toDateFormat(coupon.notValidBefore),
            notValidAfter: this.dateConversion.toDateFormat(coupon.notValidAfter),
            baggageAllowance: this.stringUtils.undefinedToEmpty(coupon.baggageAllowance),
            rfisc: this.stringUtils.undefinedToEmpty(coupon.rfisc),
            settlementCode: this.stringUtils.undefinedToEmpty(coupon.settlementCode)
        };
    }

    public toOrderDetailServiceViews(models: OrderDetailServiceModel[])
        : OrderDetailServiceView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailServiceView>();
        for (let model of models) {
            views.push(this.toOrderDetailServiceView(model));
        }
        return views;

    }
    private toOrderDetailServiceView(model: OrderDetailServiceModel)
        : OrderDetailServiceView {
        return {
            providerCode: this.stringUtils.undefinedToEmpty(model.providerCode),
            providerName: this.stringUtils.undefinedToEmpty(model.providerName),
            serviceCode: this.stringUtils.undefinedToEmpty(model.serviceCode),
            serviceName: this.stringUtils.undefinedToEmpty(model.serviceName),
            serviceText: this.stringUtils.undefinedToEmpty(model.serviceText),
            lastName: this.stringUtils.undefinedToEmpty(model.lastName),
            firstName: this.stringUtils.undefinedToEmpty(model.firstName),
            title: this.stringUtils.undefinedToEmpty(model.title),
            orderServiceId: this.stringUtils.undefinedToEmpty(model.orderServiceId),
            serviceOrderId: this.stringUtils.undefinedToEmpty(model.serviceOrderId),
            partnerOrderId: this.stringUtils.undefinedToEmpty(model.partnerOrderId),
            individualId: this.stringUtils.undefinedToEmpty(model.individualId),
            showDetail: false
        };
    }
    public toOrderDetailPaymentViews(models: OrderDetailPaymentModel[], datetimeFormat: string)
        : OrderDetailPaymentView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailPaymentView>();
        for (let model of models) {
            views.push(this.toOrderDetailPaymentView(model, datetimeFormat));
        }
        return views;

    }
    private toOrderDetailPaymentView(model: OrderDetailPaymentModel, datetimeFormat: string)
        : OrderDetailPaymentView {
        return {
            ledgerId: this.stringUtils.undefinedToEmpty(model.ledgerId),
            paymentId: this.stringUtils.undefinedToEmpty(model.paymentId),
            formOfPaymentTypeCode: this.stringUtils.undefinedToEmpty(model.formOfPaymentTypeCode),
            formOfPaymentTypeName: this.stringUtils.undefinedToEmpty(model.formOfPaymentTypeName),
            formOfPaymentCode: this.stringUtils.undefinedToEmpty(model.formOfPaymentCode),
            formOfPaymentName: this.stringUtils.undefinedToEmpty(model.formOfPaymentName),
            ledgerTransactionCode: this.stringUtils.undefinedToEmpty(model.ledgerTransactionCode),
            transactionCurrencyCode: this.stringUtils.undefinedToEmpty(model.transactionCurrencyCode),
            exchangeRate: model.exchangeRate == 0 ? "" : this.stringUtils.validateNullAmount(model.exchangeRate),
            ledgerAmount: this.stringUtils.validateNullAmount(model.ledgerAmount),
            balanceAmount: model.balanceAmount == 0 ? "" : this.stringUtils.validateNullAmount(model.balanceAmount),
            documentNumber: this.stringUtils.undefinedToEmpty(model.documentNumber),
            paymentDate: this.dateConversion.toDateFormat(model.paymentDate),
            paymentDateTime: this.dateConversion.convertDateTime24(model.paymentDateTime),
            paymentStatusCode: this.stringUtils.undefinedToEmpty(model.paymentStatusCode),
            paymentStatusName: this.stringUtils.undefinedToEmpty(model.paymentStatusName),
            paymentPartnerOrderId: this.stringUtils.undefinedToEmpty(model.paymentPartnerOrderId),
            lastName: this.stringUtils.undefinedToEmpty(model.lastName),
            firstName: this.stringUtils.undefinedToEmpty(model.firstName),
            organisationCallName: this.stringUtils.undefinedToEmpty(model.organisationCallName),
            paymentOrganisationCallName: this.stringUtils.undefinedToEmpty(model.paymentOrganisationCallName),
            transactionReference: this.stringUtils.undefinedToEmpty(model.transactionReference),
            paymentText: this.stringUtils.undefinedToEmpty(model.paymentText),
            showDetail: false,
            paymentNumberDisplay: this.stringUtils.undefinedToEmpty(model.paymentNumberDisplay),
            issueDateTime: this.dateConversion.convertDateTime24(model.issueDateTime),
            voidDateTime: this.dateConversion.convertDateTime24(model.voidDateTime),
            commitName: this.stringUtils.undefinedToEmpty(model.commitName),
            individualTitleName: this.stringUtils.undefinedToEmpty(model.individualTitleName),
            addressLine1: this.stringUtils.undefinedToEmpty(model.addressLine1),
            addressLine2: this.stringUtils.undefinedToEmpty(model.addressLine2),
            postalCode: this.stringUtils.undefinedToEmpty(model.postalCode),
            state: this.stringUtils.undefinedToEmpty(model.state),
            province: this.stringUtils.undefinedToEmpty(model.province),
            city: this.stringUtils.undefinedToEmpty(model.city),
            countryName: this.stringUtils.undefinedToEmpty(model.countryName),
            showDocumentPayment: false,
            currencyCode: this.stringUtils.undefinedToEmpty(model.currencyCode),
            productTypeName: this.stringUtils.undefinedToEmpty(model.productTypeName),
            validFromDate: this.dateConversion.toDateFormat(model.validFromDate),
            validToDate: this.dateConversion.toDateFormat(model.validToDate),
            voucherNumberDisplay: this.stringUtils.undefinedToEmpty(model.voucherNumberDisplay),
            voucherAmount: model.voucherAmount == 0 ? "" : this.stringUtils.validateNullAmount(model.voucherAmount),
            voucherPercent: model.voucherPercent == 0 ? "" : this.stringUtils.validateNullAmount(model.voucherPercent),
        };
    }

    public toOrderDetailCommentViews(models: OrderDetailCommentModel[])
        : OrderDetailCommentView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailCommentView>();
        for (let model of models) {
            views.push(this.toOrderDetailCommentView(model));
        }
        return views;

    }

    private toOrderDetailCommentView(model: OrderDetailCommentModel): OrderDetailCommentView {
        return {
            orderCommentId: this.stringUtils.undefinedToEmpty(model.orderCommentId),
            commentTypeCode: this.stringUtils.undefinedToEmpty(model.commentTypeCode),
            commentTypeName: this.stringUtils.undefinedToEmpty(model.commentTypeName),
            commentClassificationCode: this.stringUtils.undefinedToEmpty(model.commentClassificationCode),
            commentClassificationName: this.stringUtils.undefinedToEmpty(model.commentClassificationName),
            commentText: this.stringUtils.undefinedToEmpty(model.commentText),
            commentDate: this.dateConversion.toDateFormat(model.commentDate),
            commentDateTime: this.dateConversion.convertDate(model.commentDate),
            commentByName: this.stringUtils.undefinedToEmpty(model.commentByName),
            parentOrderId: this.stringUtils.undefinedToEmpty(model.parentOrderId),
            individualId: this.stringUtils.undefinedToEmpty(model.individualId),
            orderId: this.stringUtils.undefinedToEmpty(model.orderId),
            commentId: this.stringUtils.undefinedToEmpty(model.commentId),
            showDetail: false
        };
    }

    public toOrderDetailAttributeViews(models: OrderDetailAttributeModel[]): OrderDetailAttributeView[] {
        if (!models) {
            return null;
        }
        let views = new Array<OrderDetailAttributeView>();
        for (let model of models) {
            views.push(this.toOrderDetailAttributeView(model));
        }
        return views;
    }

    private toOrderDetailAttributeView(model: OrderDetailAttributeModel): OrderDetailAttributeView {

        let attributeText = this.getAttributeText(model.dimensionUnitCode,
            model.attributeText,
            model.attributeChoices,
            model.attributeDateTime,
            model.attributeValue);
        return {
            orderAttributeId: model.orderAttributeId,
            orderId: model.orderId,
            attributeTypeCode: model.attributeTypeCode,
            attributeTypeName: model.attributeTypeName,
            dimensionUnitCode: model.dimensionUnitCode,
            attributeText,
            multipleChoiceFlag: model.multipleChoiceFlag,
            showOnNewFlag: model.showOnNewFlag,
            requiredFlag: model.requiredFlag,
            overrideFlag: model.overrideFlag,
            readOnlyFlag: model.readOnlyFlag,
            attributeChoices: this.toOrderDetailAttributeChoiceViews(model.attributeChoices)
        };
    }

    private getAttributeText(dimensionUnitCode: string,
        attributeText: string,
        attributeChoices: OrderDetailAttributeChoiceModel[],
        attributeDateTime: Date,
        attributeValue: number): string | string[] {

        if (OopsChoiceValueConstant.CHOICE === dimensionUnitCode) {
            let values = new Array<string>();
            for (let choice of attributeChoices) {
                values.push(choice.attributeChoiceId);
            }
            return values;
        } else if (OopsChoiceValueConstant.DATETIME === dimensionUnitCode || OopsChoiceValueConstant.DATE === dimensionUnitCode) {
            attributeText = this.dateConversion.toDateFormat(attributeDateTime);
        } else if (OopsChoiceValueConstant.NUMBER === dimensionUnitCode) {
            attributeText = attributeValue?.toString();
        }
        return attributeText;
    }

    private toOrderDetailAttributeChoiceViews(models: OrderDetailAttributeChoiceModel[]): Select2Data[] {
        if (!models) {
            return null;
        }
        let views = new Array<Select2Data>();
        for (let model of models) {
            views.push(this.toOrderDetailAttributeChoiceView(model));
        }
        return views;
    }

    private toOrderDetailAttributeChoiceView(model: OrderDetailAttributeChoiceModel): Select2Data {
        return new Select2Data(model.attributeChoiceId, model.attributeChoiceName, true);
    }
    public toAccountingViews(models: OrderDetailIndividualAccountingModel[])
        : OrderDetailIndividualAccountingView[] {

        if (!models || models.length == 0) {
            return null;
        }
        let views = new Array<OrderDetailIndividualAccountingView>();
        for (let model of models) {
            views.push(this.toAccountingView(model));
        }
        return views;
    }

    private toAccountingView(model: OrderDetailIndividualAccountingModel)
        : OrderDetailIndividualAccountingView {

        return {
            commitDate: this.dateConversion.toDateFormat(model.commitDateTime),
            amount: this.stringUtils.validateNullAmountDecimal(model.transactionAmount),
            currencyCode: model.transactionCurrencyCode,
            productDescription: this.getAccountingDescription(model)
        };
    }
    private getAccountingDescription(model: OrderDetailIndividualAccountingModel): string {
        if (model.creditTransaction) {
            return model.productDescription;
        }
        return model.productDescription + " " +
            this.dateConversion.convertToSystemShortDate(model.flightDateTime) + " " +
            model.lastName + " " +
            model.firstName + " " +
            model.title;
    }

    public toProductDetailView(model: TransportViewModel): OrderDetailProductDetailView {

        if (!model) {
            return null;
        }
        let departure = this.filterDepartureProductPoint(model);
        let arrival = this.filterArrivalProductPoint(model);
        return {
            aircraftType: this.filterAircraftType(model),
            aircraftConfiguration: this.filterAircraftConfiguration(model),
            marketingProductNumber: this.filterProductNumber(model, marketingProductNumberTypeCode),
            operatingProductNumber: this.filterProductNumber(model, operatingProductNumberTypeCode),
            departureAirportCode: departure?.locationCode,
            departureAirportName: departure?.locationName,
            arrivalAirportCode: arrival?.locationCode,
            arrivalAirportName: arrival?.locationName,
            irregularity: "",
            originWeather: "",
            destinationWeather: "",
            parentProductId: model.parentProductId,
            departureTerminal: this.filterAttributeText(model, departureTerminalCode),
            arrivalTerminal: this.filterAttributeText(model, arrivalTerminalCode),
            departureGate: this.filterAttributeText(model, departureGateCode),
            arrivalGate: this.filterAttributeText(model, arrivalGateCode),
            irregularities: null,
            productCategoryCode: null
        };
    }
    public getWeatherForcast(departureDate: string,
        weatherModels: WeatherForecastModel[]): string {

        if (!weatherModels || weatherModels?.length == 0) {
            return "";
        }
        for (let model of weatherModels) {
            if (departureDate
                === this.dateConversion.toDateFormat(model.utcDateTime)) {
                return model.temperature.toFixed(1) + 'c ' + model.description;
            }
        }
    }
    public fillIrregularities(schedule: OrderDetailProductDetailView, models: ProductIrregularityViewModel[]) {
        if (!models || models.length == 0) {
            return;
        }
        schedule.irregularities = new Array<OrderDetailProductDetailIrregularityView>();
        for (let model of models) {
            schedule.irregularities.push(this.createIrregularity(model));
        }
    }
    private createIrregularity(model: ProductIrregularityViewModel): OrderDetailProductDetailIrregularityView {
        return {
            productIrregularityCode: this.stringUtils.undefinedToEmpty(model.productIrregularityCode),
            irregularityDuration: this.formatIrregularityDuration(model.irregularityDuration),
            productIrregularityName: this.stringUtils.undefinedToEmpty(model.productIrregularityName)
        };
    }
    private formatIrregularityDuration(duration: Date): string {
        var timestring = this.dateConversion.convertTime24(duration);
        var splitResult = timestring.split(':');
        if (!splitResult || splitResult.length < 1) {
            return "";
        }
        return splitResult[0] + splitResult[1];
    }
    public fillMissingProductDetailView(schedule: OrderDetailProductDetailView, root: TransportViewModel) {
        if (!schedule) {
            return;
        }
        if (schedule.marketingProductNumber.length == 0) {
            schedule.marketingProductNumber = this.filterProductNumber(root, marketingProductNumberTypeCode);
        }
        if (schedule.operatingProductNumber.length == 0) {
            schedule.operatingProductNumber = this.filterProductNumber(root, operatingProductNumberTypeCode);
        }
        if (!schedule.arrivalGate || schedule.arrivalGate.length == 0) {
            schedule.arrivalGate = this.filterAttributeText(root, arrivalGateCode);
        }
        if (!schedule.arrivalTerminal || schedule.arrivalTerminal.length == 0) {
            schedule.arrivalTerminal = this.filterAttributeText(root, arrivalTerminalCode);
        }
        if (!schedule.departureGate || schedule.departureGate.length == 0) {
            schedule.departureGate = this.filterAttributeText(root, departureGateCode);
        }
        if (!schedule.departureTerminal || schedule.departureTerminal.length == 0) {
            schedule.departureTerminal = this.filterAttributeText(root, departureTerminalCode);
        }
    }
    private filterAircraftType(model: TransportViewModel) {
        if (!model.vehicleTypeName || model.vehicleTypeName.length == 0) {
            return model.vehicleTypeNameInherit;
        }
        return model.vehicleTypeName;
    }
    private filterAircraftConfiguration(model: TransportViewModel) {
        if (!model.vehicleConfigurationName || model.vehicleConfigurationName.length == 0) {
            return model.vehicleConfigurationNameInherit;
        }
        return model.vehicleConfigurationName;
    }
    private filterProductNumber(model: TransportViewModel, productNumberType: string): string {
        let productNumber = model.productNumbers
            .find(p => p.productNumberTypeCode === productNumberType);
        if (!productNumber) {
            return "";
        }

        if (productNumberType === operatingProductNumberTypeCode) {
            return productNumber.providerIataCode + " " + productNumber.productNumber;
        }

        return productNumber.providerIataCode + " " + productNumber.productNumber;
    }
    public filterDepartureProductPoint(model: TransportViewModel): ProductPointViewModel {
        if (!model?.productPoints || model?.productPoints?.length == 0) {
            return null;
        }
        return model.productPoints[0];
    }
    public filterArrivalProductPoint(model: TransportViewModel): ProductPointViewModel {
        if (!model?.productPoints || model?.productPoints?.length == 0) {
            return null;
        }
        return model.productPoints[model.productPoints.length - 1];
    }
    private filterAttributeText(model: TransportViewModel, attributeTypeCode: string): string {
        if (!model?.originDestinationAttributes || model?.originDestinationAttributes?.length == 0) {
            return "";
        }
        let attributeText = model.originDestinationAttributes
            .find(a => a.attributeTypeCode === attributeTypeCode)?.attributeText;
        if (!attributeText) {
            return "";
        }
        return attributeText;
    }

    public toOrderFilterCommand(searchCriteria: OrderSearchCriteria): OrderSearchFilterCommand{
        let command = {} as OrderSearchFilterCommand;

        command.orderNumber = searchCriteria.orderNumber;
        command.orderDateFrom = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(searchCriteria.orderDateFrom));
        command.serviceDateFrom = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(searchCriteria.serviceDateFrom));
        command.originLocationId = this.stringUtils.nullIfEmpty(searchCriteria.originLocationId);
        command.destinationLocationId = this.stringUtils.nullIfEmpty(searchCriteria.destinationLocationId);
        command.productNumber = searchCriteria.productNumber;
        command.individual = searchCriteria.individual;
        command.organisationId = this.stringUtils.nullIfEmpty(searchCriteria.organisationId);
        command.groupName = searchCriteria.groupName;
        command.orderSalesStatusCode = searchCriteria.orderSalesStatusCode;
        command.orderPaymentStatusCode = searchCriteria.orderPaymentStatusCode;

        command.orderAdvanceSearchCriteria = this.toOrderAdvanceSearchCreteria(searchCriteria.advanceSearch as OrderAdvanceSearchCriteria);;

        return command;
    }

    private toOrderAdvanceSearchCreteria(advanceSearch: OrderAdvanceSearchCriteria): OrderAdvanceSearchCriteria{
        if (!advanceSearch) {
            return null;
        }

        let advanceSearchCriteria = {} as OrderAdvanceSearchCriteria;
        advanceSearchCriteria = advanceSearch;

        // Order
        advanceSearchCriteria.orderTypeCode = advanceSearch.orderTypeCode;
        advanceSearchCriteria.orderBy = advanceSearch.orderBy;
        advanceSearchCriteria.orderDateFrom = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.orderDateFrom));
        advanceSearchCriteria.orderDateTo = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.orderDateTo));
        advanceSearchCriteria.orderDateType = advanceSearch.orderDateType;
        advanceSearchCriteria.orderDeliveryStatusCode = advanceSearch.orderDeliveryStatusCode;
        advanceSearchCriteria.salesCurrencyCode = advanceSearch.salesCurrencyCode;
        advanceSearchCriteria.numberOfUnitsFrom = advanceSearch.numberOfUnitsFrom;
        advanceSearchCriteria.numberOfUnitsTo = advanceSearch.numberOfUnitsTo;
        advanceSearchCriteria.accountableDocumentNumber = advanceSearch.accountableDocumentNumber;
        advanceSearchCriteria.supplierSystemCode = advanceSearch.supplierSystemCode;
        advanceSearchCriteria.supplierSystemCountry = advanceSearch.supplierSystemCountry;
        advanceSearchCriteria.orderTypeCode = advanceSearch.orderTypeCode;
        advanceSearchCriteria.searchUsageTypeCode = advanceSearch.searchUsageTypeCode;
        advanceSearchCriteria.orderDateCondition = advanceSearch.orderDateCondition;
        advanceSearchCriteria.numberOfUnitsCondition = advanceSearch.numberOfUnitsCondition;

        //Product
        advanceSearchCriteria.productCategoryCode = advanceSearch.productCategoryCode;
        advanceSearchCriteria.productGroupCode= advanceSearch.productGroupCode;
        advanceSearchCriteria.productTypeCode= advanceSearch.productTypeCode;
        advanceSearchCriteria.productName= advanceSearch.productName;
        advanceSearchCriteria.serviceDateFrom = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.serviceDateFrom));
        advanceSearchCriteria.serviceDateTo = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.serviceDateTo));
        advanceSearchCriteria.productDateType = advanceSearch.productDateType;
        advanceSearchCriteria.productDateCondition = advanceSearch.productDateCondition;
        advanceSearchCriteria.productSalesStatusCode = advanceSearch.productSalesStatusCode;
        advanceSearchCriteria.productDeliveryStatusCode = advanceSearch.productDeliveryStatusCode;
        advanceSearchCriteria.productPaymentStatusCode = advanceSearch.productPaymentStatusCode;
        advanceSearchCriteria.productBookingStatusCode = advanceSearch.productBookingStatusCode;
        advanceSearchCriteria.productProviderId = advanceSearch.productProviderId;
        advanceSearchCriteria.productSupplierId = advanceSearch.productSupplierId;

        advanceSearchCriteria.paymentDateFrom = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.paymentDateFrom));
        advanceSearchCriteria.paymentDateTo = this.dateConversion.toDateFormatOrNull(this.stringUtils.nullIfEmpty(advanceSearch.paymentDateTo));

        return advanceSearchCriteria;
    }

    public toOrderSearchCriteria(filterCommand: OrderSearchFilterCommand): OrderSearchCriteria {
        let orderSearch = {} as OrderSearchCriteria;
        
        orderSearch.orderNumber = filterCommand.orderNumber ?? '';
        orderSearch.productNumber = filterCommand.productNumber ?? '';
        orderSearch.productNumber = filterCommand.productNumber ?? '';
        orderSearch.orderDateFrom = filterCommand.orderDateFrom ? this.dateConversion.toDateFormatFromString(filterCommand.orderDateFrom) : '';
        orderSearch.serviceDateFrom = filterCommand.serviceDateFrom ? this.dateConversion.toDateFormatFromString(filterCommand.serviceDateFrom) : '';
        orderSearch.groupName = filterCommand.groupName ?? '';
        orderSearch.orderSalesStatusCode = filterCommand.orderSalesStatusCode ?? '';
        orderSearch.orderPaymentStatusCode = filterCommand.orderPaymentStatusCode ?? '';
        orderSearch.individual = filterCommand.individual ?? '';
        orderSearch.organisationId = filterCommand.organisationId ?? '';
        orderSearch.originLocationId = filterCommand.originLocationId ?? '';
        orderSearch.originLocationName = filterCommand.originLocationName ?? '';
        orderSearch.originLocationSave =  this.getLocationSave(filterCommand.originLocationId, filterCommand.originLocationName) ?? null;
        orderSearch.destinationLocationId = filterCommand.destinationLocationId ?? '';
        orderSearch.destinationLocationName = filterCommand.destinationLocationName ?? '';
        orderSearch.destinationLocationSave =  this.getLocationSave(filterCommand.destinationLocationId, filterCommand.destinationLocationName) ?? null;
        orderSearch.searchFilter = filterCommand.filterName ?? '';
        orderSearch.publicFilterFlag = filterCommand.publicFilterFlag ?? null;

        this.setOrderSearchOrderCreteria(filterCommand, orderSearch);
        return orderSearch;
    }

    public getLocationSave(locationId: string, locationName: string) : Select2Data[] {
        let valueReturen:Select2Data[] = new Array();
        if (locationId) {
            let data = new Select2Data(locationId, locationName);
            valueReturen.push(data);
            return valueReturen;
        }

        return valueReturen;
    }

    private setOrderSearchOrderCreteria(filterCommand: OrderSearchFilterCommand, orderSearch: OrderSearchCriteria) {
        orderSearch.advanceSearch = (orderSearch.advanceSearch ?? {} as OrderAdvanceSearchCriteria) as OrderAdvanceSearchCriteria;

        orderSearch.advanceSearch = filterCommand.orderAdvanceSearchCriteria as OrderAdvanceSearchCriteria;

        orderSearch.advanceSearch.orderDateFrom = filterCommand.orderAdvanceSearchCriteria?.orderDateFrom ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.orderDateFrom)) : null;
        orderSearch.advanceSearch.orderDateTo = filterCommand.orderAdvanceSearchCriteria?.orderDateTo ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.orderDateTo)) : null;
        orderSearch.advanceSearch.serviceDateFrom = filterCommand.orderAdvanceSearchCriteria?.serviceDateFrom ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.serviceDateFrom)) : null;
        orderSearch.advanceSearch.serviceDateTo = filterCommand.orderAdvanceSearchCriteria?.serviceDateTo ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.serviceDateTo)) : null;
        orderSearch.advanceSearch.paymentDateFrom = filterCommand.orderAdvanceSearchCriteria?.paymentDateFrom ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.paymentDateFrom)) : null;
        orderSearch.advanceSearch.paymentDateTo = filterCommand.orderAdvanceSearchCriteria?.paymentDateTo ? this.dateConversion.toDateFormatYearMonthDate(new Date(filterCommand.orderAdvanceSearchCriteria?.paymentDateTo)) : null;   
    }
}
