export interface AttributeChoiceModel {
    attributeChoiceId: string;
    serviceCategoryCode: string;
    serviceCategoryName: string;
    salesBucketCode: string;
    serviceCode: string;
    serviceName: string;
    attributeChoiceValue: string;
    attributeChoiceCode: string;
    attributeChoiceName: string;
    defaultFlag: boolean;
    sortSequence: number;
}
