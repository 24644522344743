import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { NewsListCommandModel } from "../../models/news-model/news-list-command.model";
import { NewsListViewModel } from "../../models/news-model/news-list-view.model";
import { NewsSearchCommandModel } from "../../models/news-model/news-search-command.model";
import { NewsSearchViewModel } from "../../models/news-model/news-search-view.model";
import { NewsCommandModel } from "../../models/news-model/news-command.model";
import { NewsViewModel } from "../../models/news-model/news-view.model";

@Injectable({
    providedIn: 'root'
})
export class NewsService extends ApiServiceBase {
    private readonly PATH: string = '/system/news';

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService
      ) {
        super(httpClient, authService);
      }

    public getNewsList(command: NewsListCommandModel): Observable<NewsListViewModel[]> {
        return this.httpPost<NewsListViewModel[]>(this.PATH + '/newslist', command)
          .pipe(
            map(res => res.body)
          );
    }

    public search(command: NewsSearchCommandModel): Observable<NewsSearchViewModel[]> {
      return this.httpPost<NewsSearchViewModel[]>(this.PATH + '/search', command)
        .pipe(
          map(res => res.body)
        );
    }    

    public updateStatus(newsId: string, statusCode: string) {
      return this.httpPut(this.PATH + "/status/" + newsId + '?statusCode=' + statusCode, null)
        .pipe(
          map(response => {
            return response.body;
          })
        );
    }

  public add(command: NewsCommandModel): Observable<NewsViewModel> {
     return this.httpPost<NewsViewModel>(this.PATH + "/", command)
       .pipe(
         map(response => {
           return response.body;
         })
       );
   }
 
   public edit(command: NewsCommandModel, newsId: string): Observable<NewsViewModel> {
     return this.httpPut<NewsViewModel>(this.PATH + "/" + newsId, command)
       .pipe(
         map(response => {
           return response.body;
         })
       );
   }
   
   public getNews(newsId: string): Observable<NewsViewModel> {
     return this.httpGet<NewsViewModel>(this.PATH + "/" + newsId, null)
       .pipe(
         map(response => response.body)
       )
   }
    
  }