import { Injectable } from '@angular/core';
import { AddressModel } from 'src/app/core/components/address/shared/address.model';
import { InvoiceOrganisationDetailModel, InvoiceOrganisationSearchModel, OrganisationModel } from 'src/app/core/models/organisation-model';
import { DateConverterService } from 'src/app/core/utils/date-converter.service';

@Injectable()
export class InvoiceOrganisationDetailMapperService {

    constructor(private dateConverterService: DateConverterService) { }

    public organisationModelToInvoiceOrganisationDetailModel(organisation: OrganisationModel, searchOrganisation: InvoiceOrganisationSearchModel): InvoiceOrganisationDetailModel {
        let invoiceOrg: InvoiceOrganisationDetailModel = {
            organisationId: organisation?.organisationId,
            organisationCode: organisation?.organisationCode,
            organisationCallName: organisation?.organisationCallName,
            organisationLegalName: organisation?.organisationLegalName,
            languageTranslationSetId: organisation?.languageTranslationSetId,
            providerAccountNumber: organisation?.providerAccountNumber,
            taxId: organisation?.taxId,
            providerIataCode: organisation?.providerIataCode,
            providerIcaoCode: organisation?.providerIcaoCode,
            passwordExpiryDays: organisation?.passwordExpiryDays,
            passwordReuseCycle: organisation?.passwordReuseCycle,
            teletypeAddress: organisation?.teletypeAddress,
            websiteAddress: organisation?.websiteAddress,
            locationId: organisation?.locationId,
            locationName: organisation?.locationName,
            usageTypeCode: organisation?.usageTypeCode,
            usageTypeName: organisation?.usageTypeName,
            commitBy: organisation?.commitFirstname,
            commitDateTime: this.dateConverterService.convertDate(organisation?.commitDateTime),
            statusCode: organisation.statusCode,
            organisationAddress: this.getInvoiceAddress(organisation.organisationAddresses, searchOrganisation?.organisationAddressId)
        }

        return invoiceOrg;
    }

    private getInvoiceAddress(organisationAddress: AddressModel[], addressId: string): AddressModel {
        return organisationAddress.find(address => address.addressId === addressId);
    }
}
