export interface OrderIndividualView {
    individualId: string;
    firstName: string;
    lastName: string;
    title: string;
    phoneType: string;
    phonePrefix: string;
    phoneNumber: string;
    email: string;
    organisationId: string;
    organisationName: string;
    languageCode: string;
}