export interface PriceRuleLocationView {
    priceRuleLocationId: string;
    priceRuleLocationTypeCode: string;
    priceRuleLocationPointCode: string;
    locationId: string;
    locationGroupId: string;
    regionCode: string;
    countryCode: string;
    excludeFlag: boolean;
    displaySequence: number;
}