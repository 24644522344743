import { 
    OrderDetailCommentView,
    OrderDetailIndividualAddressView,
    OrderDetailIndividualMembershipView,
    OrderDetailIndividualPhoneView,
    OrderDetailIndividualCommunicationOtherView
} from '../';

export interface OrderDetailIndividualView {
    individualId: string;
    orderId: string;
    headerOrderId: string;
    individualNameId: string;
    lastName: string;
    firstName: string;
    titleName: string;
    titleCode: string;
    ageGroupName: string;
    ageGroupCode: string;
    dateOfBirth: string;
    genderName: string;
    genderCode: string;
    languageCode: string;
    languageName: string;
    nationalityCountryCode: string;
    mailAddress: string;
    phoneNumber: string;
    phoneTypeName: string;
    phoneTypeCode: string;
    membershipLevelCode: string;
    membershipLevelName: string;
    comments: OrderDetailCommentView[];
    countryPhonePrefix: string;
    orderPartnerRoleName: string;
    organisationCallName: string;
    disablePartner: boolean;
    orderSalesStatusCode: string;
    orderSalesStatusName: string;
    orderDeliveryStatusCode: string;
    orderDeliveryStatusName: string;
    orderIndividualPhones: OrderDetailIndividualPhoneView[];
    orderIndividualAddresses: OrderDetailIndividualAddressView[];
    orderIndividualMemberships: OrderDetailIndividualMembershipView[];
    orderIndividualCommunicationOthers: OrderDetailIndividualCommunicationOtherView[];
    showDetail: boolean;
    showChangeSeat: boolean;
}