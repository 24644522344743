export interface OrderDetailPartnerCommentView {
    orderCommentId: string;
    commentTypeCode: string;
    commentTypeName: string;
    commentClassificationCode: string;
    commentClassificationName: string;
    commentText: string;
    commentDate: string;
    commentDateTime: string;
    commentByName: string;
    showDetail: boolean;
    parentOrderId: string;
    individualId: string;
    orderId: string;
    commentId: string;
}