export interface RouteViewModel {
    productId: string;
    productName: string;
    parentProductId: string;
    locationId: string;
    locationName: string;
    hierarchyKey: string;
    hierarchyLength: number;
    time: Date;
    depTime: Date;
    arrTime: Date;
    dayChangeProductAttributeId: string;
    dayChange: number;
    terminalProductAttributeId: string;
    terminal: string;
    gateProductAttributeId: string;
    gate: string;
    productDateTimeId: string;    
    type: string;
}