declare var moment: any;

export const select2SearchNewsType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchStatusType = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const select2SearchNewsPriority = {
    placeholder: "",
    allowClear: true,
    minimumResultsForSearch: -1
}

export const dateOption = {
    singleDatePicker: true,
    showDropdowns: true,
    autoApply: true,
    autoUpdateInput: false,
    startDate: moment(),
    endDate: moment(),
    locale: {
        cancelLabel: 'Clear',
        format: 'YYYY-MM-DD'
    }
}