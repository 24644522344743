import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { InvoiceIndividualSearchModel } from 'src/app/core/models/individual-model';
import { AgeGroupCodeFull } from 'src/app/modules/order/shared';
import { FocusingDirective } from 'src/app/shared/ui/forms/inputs/focusing.directive';

@Component({
    selector: 'op-invoice-individual-search-condition',
    templateUrl: './invoice-individual-search-condition.component.html'
})
export class InvoiceIndividualSearchConditionComponent implements OnInit {
    private readonly defaultCondition: InvoiceIndividualSearchModel = {
        firstName: null,
        lastName: null,
        mailAddress: null,
        phoneNumber: null,
        individualAgeGroupCode: AgeGroupCodeFull.Adult
    }

    public condition: InvoiceIndividualSearchModel;
    public focused = false;

    @Output() onSearch = new EventEmitter<InvoiceIndividualSearchModel>();
    @Output() onClear = new EventEmitter();
    @ViewChild(FocusingDirective) focusingDirective: FocusingDirective;

    constructor(private changeDetector: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.condition = Object.assign({}, this.defaultCondition);
    }

    public trimInput(input) {
        input.target.value = input.target.value.trim();
    }

    public searchClick() {
        this.onSearch.emit(this.condition);
    }

    public clearCondition() {
        this.condition = Object.assign({}, this.defaultCondition);
        this.changeDetector.detectChanges();
        this.onClear.emit();
    }

    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.searchClick();
                break;
            default:
                break;
        }
    }
    
}
