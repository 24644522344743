import { OrderDetailIndividualAccountableDocumentCouponView } from './order-detail-individual-accountable-document-coupon.view';

export interface OrderIndividualAccountableDocumentView {
    orderDocumentId: string;
    accountableDocumentTypeCode: string;
    accountableDocumentNumber: string;
    accountableDocumentSeriesNumber: string;
    accountableDocumentCouponNumber: string;
    accountableDocumentTypeName: string;
    lastName: string;
    firstName: string;
    title: string;
    individualId: string;
    showDetail: boolean;
    dateOfIssue: string;
    currencyCode: string;
    amount: string;
    departure: string;
    arrival: string;
    associateDocumentNumber: string;
    numberOfBooklets: string;
    originator: string;
    originatorCity: string;
    agencyIataNumber: string;
    agencyPseudo: string;
    agencyCity: string;
    agencyCountry: string;
    agencyCurrency: string;
    tourCode: string;
    rfic: string;
    fareLine: string;
    orderId: string;
    partnerOrderId: string;
    coupons: OrderDetailIndividualAccountableDocumentCouponView[];
}