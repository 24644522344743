import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnDestroy,
    Output
} from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';

import { filter } from "rxjs/internal/operators";
import { Subscription } from 'rxjs';

import { NavigationModel } from './navigation.model';
import { NavigationService } from '.';
import { PricingCategoryUrl, ProductCategoryUrl, TransportCategoryUrl } from 'src/app/modules/favorite/shared/favorite.constant';

@Component({
    selector: 'op-dynamic-router',
    template: `<ng-container [ngComponentOutlet]="navigateComponent"></ng-container>`
})
export class DynamicRoutingComponent implements OnDestroy {
    private readonly FAVORITE_URL = 'favorite';
    private readonly ATTRIBUTE_URL = 'attribute';
    private readonly SECURITY_URL = 'security';
    private readonly VEHICLE_URL = 'vehicle';
    private readonly MERCHANDISE_URL = 'merchandises';
    private readonly PRODUCT_URL = 'product';
    private readonly PRICING_URL = 'pricing';
    private readonly TRANSPORT_URL = 'transport';
    private readonly ORDER_URL = 'orders';
    private readonly SHOPPING_URL = 'shopping';
    private readonly DOCUMENTDISTRIBUTION_URL = 'document-distribution';
    private readonly NEWS_URL = 'news';
    private readonly GROUPCODE_INDEX = 3;
    private readonly DOMAINCODE_INDEX = 1;

    @Output("onNavigate") navigateSuccess = new EventEmitter();

    navigateComponent;
    private navigationSubScription: Subscription
    private currentPathStructure: string[];

    constructor(private changeDetection: ChangeDetectorRef,
        private router: Router,
        private navigationService: NavigationService) {

        this.navigationSubScription = this.router.events
            .pipe(filter(event => event instanceof RoutesRecognized))
            .subscribe((event: RoutesRecognized) => {
                const navigation = this.router.getCurrentNavigation();
                const url = navigation.extras?.state ? navigation.extras?.state?.url : "";
                const snapshot = this.navigationService.getRouteConfigSnapShot(event.state.root.children)

                let pathStructure = this.navigationService.cleanPath(url.split('/'));
                let navigationResult: NavigationModel = {
                    component: this.navigationService.getComponent(pathStructure, event.state.root.children),
                    name: navigation.extras.state ? navigation.extras.state.name : "",
                    url,
                    tab: navigation.extras.state ? navigation.extras.state.tab : false,
                    tabId: snapshot?.tabId
                };
                if (pathStructure.length == 0) {
                    return;
                }
                if (this.currentPathStructure != null && !this.allowedToChangePath(this.currentPathStructure, pathStructure)) {
                    return;
                }
                this.currentPathStructure = pathStructure;
                this.navigateComponent = navigationResult.component
                this.changeDetection.detectChanges();
                this.navigateSuccess.emit();
            });
    }

    private allowedToChangePath(currentPathStructure: string[], newPathStructure: string[]) {
        if (newPathStructure.length > currentPathStructure.length && this.allowChangePathWhenNewPathIsLonger(currentPathStructure, newPathStructure)) {
            return currentPathStructure.every((path, index) => path == newPathStructure[index]);
        }

        const positionOfBeforeLastIndex: number = 1
        let lastPosition = currentPathStructure.length - positionOfBeforeLastIndex;

        let currentTabId = currentPathStructure[2];
        let newTabId = newPathStructure[2];
        for (let i = 0; i < lastPosition; i++) {
            if (currentPathStructure[i] !== newPathStructure[i]) {
                if (this.allowToChangePathWhenPathsNotMatch(currentPathStructure[i], newPathStructure[i]) && currentTabId == newTabId) {
                    return true;
                }
                return false;
            }
        }
        return !this.lastLayerMatch(currentPathStructure[lastPosition], newPathStructure[lastPosition]);
    }

    private lastLayerMatch(lastCurrentPathStructure: string, lastNewPathStructure) {
        if (lastCurrentPathStructure !== lastNewPathStructure || lastCurrentPathStructure.startsWith(this.SECURITY_URL) || this.isFromProduct(lastCurrentPathStructure) || this.isNavigateTransportToScheduleChange(lastCurrentPathStructure)) {
            return false;
        }
        return true;
    }
    ngOnDestroy(): void {
        this.navigationSubScription.unsubscribe();
    }

    allowChangePathWhenNewPathIsLonger(currentPathStructure: string[], newPathStructure: string[]) {
        let currentDomainCode = currentPathStructure[this.DOMAINCODE_INDEX];
        let newGroupCode = newPathStructure[this.GROUPCODE_INDEX];
        return (currentDomainCode != this.FAVORITE_URL && newGroupCode != this.ATTRIBUTE_URL && newGroupCode != this.DOCUMENTDISTRIBUTION_URL &&  newGroupCode != this.NEWS_URL&&  !newGroupCode.startsWith(this.VEHICLE_URL)) &&
                (!newGroupCode.startsWith(this.SECURITY_URL) && currentDomainCode != this.PRODUCT_URL && currentDomainCode != this.PRICING_URL && currentDomainCode != this.TRANSPORT_URL &&
                currentDomainCode != this.ORDER_URL);
    }

    allowToChangePathWhenPathsNotMatch(currentPath: string, newPath: string) {
        return ((currentPath == this.SHOPPING_URL || currentPath == this.ORDER_URL || currentPath == this.FAVORITE_URL || currentPath == this.ATTRIBUTE_URL || currentPath == this.DOCUMENTDISTRIBUTION_URL || currentPath == this.NEWS_URL || currentPath?.includes(this.MERCHANDISE_URL) || this.isFromProduct(currentPath) || this.isFromPricing(currentPath) || this.isFromTransport(currentPath)) ||
            (newPath == this.ATTRIBUTE_URL || newPath?.includes(this.DOCUMENTDISTRIBUTION_URL) || newPath?.includes(this.NEWS_URL)  || newPath?.includes(this.SECURITY_URL) || newPath?.includes(this.VEHICLE_URL)));
    }

    isFromProduct(groupCode: string) {
        return ProductCategoryUrl.includes(groupCode);
    }

    isNavigateTransportToScheduleChange(pathStructure: string) {
        return pathStructure == 'details'
    }

    isFromPricing(groupCode: string) {
        return Object.values(PricingCategoryUrl).includes(groupCode);
    }

    isFromTransport(groupCode: string) {
        return TransportCategoryUrl.includes(groupCode);
    }

}