import { VehicleConfigurationCompartmentCommandModel } from "./vehicle-configuration-compartment/vehicle-configuration-compartment-command.model";
import { VehicleConfigurationProductCommandModel } from "./vehicle-configuration-product/vehicle-configuration-product-command.model";
import { ProductInventoryCommandModel } from "src/app/core/models/product-model/product-base-model/product-inventory";
import { VehicleConfigurationCompartmentPropertyCommandModel } from "./vehicle-configuration-compartment-property/vehicle-configuration-compartment-property-command.model";

export interface VehicleConfigurationEditCommandModel {
    vehicleConfigurationId: string;
    vehicleConfigurationCode: string;
    vehicleConfigurationName: string;
    statusCode: string;
    vehicleConfigurationCompartments: VehicleConfigurationCompartmentCommandModel[];
    vehicleConfigurationProducts: VehicleConfigurationProductCommandModel[];
    productInventories: ProductInventoryCommandModel[];   
    vehicleConfigurationCompartmentProperties: VehicleConfigurationCompartmentPropertyCommandModel[];
}