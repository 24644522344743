import { AddressModel } from "../../components/address/shared/address.model";

export interface InvoiceOrganisationDetailModel {
    organisationId: string,
    organisationCode: string,
    organisationCallName: string,
    organisationLegalName: string,
    languageTranslationSetId: string,
    providerAccountNumber: string,
    taxId: string,
    providerIataCode: string,
    providerIcaoCode: string,
    passwordExpiryDays: number,
    passwordReuseCycle: number,
    teletypeAddress: string,
    websiteAddress: string,
    locationId: string,
    locationName: string,
    usageTypeCode: string,
    usageTypeName: string,
    commitBy: string,
    commitDateTime: string,
    statusCode: string,
    organisationAddress: AddressModel
}