export interface OrderDetailIndividualAccountableDocumentCouponView {
    couponNumber: number;
    couponStatus: string;
    serviceDate: string;
    associatedCouponNumber: number;
    operatingAirlineNumber: string;
    marketingAirlineNumber: string;
    departure: string;
    arrival: string;
    requestedStatus: string;
    inventoryClass: string;
    serviceClass: string;
    fareBasis: string;
    couponAmount: string;
    notValidBefore: string;
    notValidAfter: string;
    baggageAllowance: string;
    rfisc: string;
    settlementCode: string;   
}