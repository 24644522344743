import { VehicleConfigurationCompartmentCommandModel } from "./vehicle-configuration-compartment/vehicle-configuration-compartment-command.model";
import { VehicleConfigurationProductCommandModel } from "./vehicle-configuration-product/vehicle-configuration-product-command.model";
import { ProductInventoryCommandModel } from "src/app/core/models/product-model/product-base-model/product-inventory";

export interface VehicleConfigurationAddCommandModel {
    vehicleConfigurationCode: string;
    vehicleConfigurationName: string;
    statusCode: string;
    vehicleConfigurationCompartments: VehicleConfigurationCompartmentCommandModel[];
    vehicleConfigurationProducts: VehicleConfigurationProductCommandModel[];
    productInventories: ProductInventoryCommandModel[];
}