import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { MODULE_NAME, PAGE_TITLE } from '../shared/title-constant';
import { SettingsManagementService } from 'src/app/core/services/system-services/settings-management.service';
import { LoadingSpinnerService } from 'src/app/shared/layout/loading-spinner';
import { SettingsCategoriesMapperService } from './settings-categories.mapper';
import { NavigationService } from 'src/app/shared/utils/navigation';

import { SettingsManagementModel } from 'src/app/core/models/reference-model/reference-general-model/settings-management.model';
import { OopsCategory } from '../../../../core/components/categories/oops-category.model';
import { SecurityGroupSecurityModel } from 'src/app/core/models/security-model/security-group-security.model';
import { DisplayActionId, GroupActionId, NewActionId, SearchActionId } from "./settings-context-menu";
import { Subscription, combineLatest } from 'rxjs';
import { SecurityGroupService } from 'src/app/core/services/system-services/security-group.service';
import { switchMap } from 'rxjs/operators';
import { LogCommitService } from 'src/app/core/services/system-services';
import { LogCommitModel } from 'src/app/core/models/log-commit-model/log-commit.model';
import { SettingsSecurityCodes } from './settings-categories';
import { TabService } from 'src/app/core/utils/tab.service';

@Component({
    selector: 'op-settings-categories',
    templateUrl: './settings-categories.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SettingsCategoriesMapperService]
})
export class SettingsCategoriesComponent implements OnInit, OnDestroy {

    public categoriesItems: OopsCategory[];
    private models: SettingsManagementModel[]
    private selectedItem: OopsCategory;
    private subscriptions: Subscription[]

    @Input() favoriteCategoryName: string;
    @Output() onCategorySelected = new EventEmitter<OopsCategory>();
    @Output() onOpenSearchTab = new EventEmitter();


    constructor(
        private settingsManagementService: SettingsManagementService,
        private mainSpinner: LoadingSpinnerService,
        private settingsCategoriesMapperService: SettingsCategoriesMapperService,
        private changeDetectionRef: ChangeDetectorRef,
        private navigationService: NavigationService,
        private securityGroupService: SecurityGroupService,
        private logCommitService: LogCommitService,
        private tabService: TabService) { }

    ngOnInit(): void {
        this.subscriptions = []
        this.categoriesItems = []
        this.fetchSettingsManagement();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe())
    }

    public fetchSettingsManagement() {
        this.subscriptions.push(
            this.settingsManagementService.getAll()
                .pipe(
                    switchMap((settingsManagementModels: SettingsManagementModel[]) => {
                        this.models = settingsManagementModels
                        return combineLatest([
                            this.securityGroupService.userSecurityGroupSecurity,
                            this.logCommitService.getByCode("DOCUMENTDISTRIBUTION"),
                            this.logCommitService.getByCode("EXCHANGERATE"),
                            this.logCommitService.getByCode("NEWS"),
                        ])
                    })
                )
                .subscribe(
                    ([
                        userSecurityModels,
                        logCommitDocumentDistribution,
                        logCommitExchangeRate,
                        logCommitNews
                    ]) => {
                        const logCommitMap = new Map<string, LogCommitModel>()
                        logCommitMap.set(MODULE_NAME.document_distribution, logCommitDocumentDistribution)
                        logCommitMap.set(MODULE_NAME.exchange_rate, logCommitExchangeRate)
                        logCommitMap.set(MODULE_NAME.vehicle, <LogCommitModel>{})
                        logCommitMap.set(MODULE_NAME.news, logCommitNews)
                        this.categoriesItems = this.settingsCategoriesMapperService.getCategories(this.models, userSecurityModels, logCommitMap);
                        if (this.favoriteCategoryName) {
                            this.setActiveCatagory(this.favoriteCategoryName);
                        }

                        this.mainSpinner.hide();

                        setTimeout(() => {
                            this.changeDetectionRef.markForCheck()
                        }, 200);
                    },
                    () => {
                        this.mainSpinner.hide();
                    }
                )
        )
    }

    setComitDataForDocumentDistribution(userSecurityModels: SecurityGroupSecurityModel[], logCommit: LogCommitModel) {
        let model = userSecurityModels?.find(s => s.securityCode ==  SettingsSecurityCodes.get(MODULE_NAME.document_distribution));
        
        if (!model) {
            return;
        }

        model.commitByName = logCommit.commitByName;
        model.commitDateTime = logCommit.commitDateTime;
    }

    setActiveCatagory(categoryName: string) {
        const category = this.categoriesItems.find(item => item.name.toLowerCase() == categoryName);
        if (category) {
            this.onMenuSelectedFromSetActiveCategory(category).then(() => {
                this.onOpenSearchTab.emit();
            });
        }
    }

    onContextMenuSelected(actionId: string) {
        switch (actionId) {
            case DisplayActionId:
                this.gotoNextModule();
                break;
            case GroupActionId:
                this.gotoNextModule();
                break;
            case SearchActionId:
                this.gotoSearchNextModule();
                break;
            case NewActionId:
                this.gotoNew();
                break
        }
    }

    onMenuSelected(item: OopsCategory) {
        this.selectedItem = item;
        this.onCategorySelected.emit(this.selectedItem);
    }

    private onMenuSelectedFromSetActiveCategory(item: OopsCategory): Promise<void> {
        return new Promise((resolve, reject) => {
            this.selectedItem = item;
            this.onCategorySelected.emit(this.selectedItem);
            resolve();
        });
    }

    onMunuDoubleClick(selectedItem: OopsCategory) {
        this.selectedItem = selectedItem;
        switch (selectedItem.name.toLowerCase()) {
            case MODULE_NAME.security:
                this.gotoSecurity();
                break;
            case MODULE_NAME.document_distribution:
                this.gotoSearchNextModule();
                break;
            case MODULE_NAME.attribute:
                this.gotoSearchNextModule();
                break;
            case MODULE_NAME.vehicle:
                this.gotoVehicle();
                break;
            case MODULE_NAME.exchange_rate:
                this.gotoExchangeRate();
                break;
            case MODULE_NAME.geography:
                this.gotoGeography();
                break;
            case MODULE_NAME.news:
                this.gotoSearchNextModule();
                break;      
        }
    }

    private gotoNextModule() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.vehicle:
                    this.gotoVehicle();
                    break;
                case MODULE_NAME.security:
                    this.gotoSecurity();
                    break;
                case MODULE_NAME.exchange_rate:
                    this.gotoExchangeRate();
                    break;
                case MODULE_NAME.geography:
                    this.gotoGeography();
                    break;
            }
        }
    }

    private gotoVehicle() {
        this.tabService.changeTabName(PAGE_TITLE.vehicle);
        this.navigationService.navigate(this.selectedItem.url, PAGE_TITLE.vehicle);
    }

    private gotoSecurity() {
        let params = {
            selectedTab: 'FUNCTION'
        }
        this.tabService.changeTabName(PAGE_TITLE.security);
        this.navigationService.navigate(this.selectedItem.url, PAGE_TITLE.security, false, params);
    }

    private gotoExchangeRate() {
        this.tabService.changeTabName(PAGE_TITLE.exchange_rate);
        this.navigationService.navigate(this.selectedItem.url, PAGE_TITLE.exchange_rate);
    }

    private gotoGeography() {
        this.tabService.changeTabName(PAGE_TITLE.geography);
        this.navigationService.navigate(this.selectedItem.url, PAGE_TITLE.settings);
    }

    private gotoSearchNextModule() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.attribute:
                    this.onOpenSearchTab.emit();
                    break;
                case MODULE_NAME.document_distribution:
                    this.onOpenSearchTab.emit();
                    break;    
                case MODULE_NAME.news:
                    this.onOpenSearchTab.emit();
                    break;                                    
            }
        }
    }

    private gotoNew() {
        if (this.selectedItem) {
            switch (this.selectedItem.name.toLowerCase()) {
                case MODULE_NAME.attribute:
                    this.gotoNewAttribute();
                    break;
                case MODULE_NAME.document_distribution:
                    this.gotoNewDocumentDistribution();
                    break;
                case MODULE_NAME.news:
                    this.gotoNewNews();
                    break;                    
            }
        }
    }

    private gotoNewAttribute() {
        this.tabService.changeTabName(PAGE_TITLE.attribute);
        this.navigationService.navigate(`${this.selectedItem.url}/details`, null, null, {
            id: null,
            attributeName: null,
            newFlag: true,
            userSecurity: this.selectedItem.security,
            paggingView: []
        });
    }

    private gotoNewDocumentDistribution() {
        this.tabService.changeTabName(PAGE_TITLE.document_distribution);
        this.navigationService.navigate(`${this.selectedItem.url}/details`, null, null, {
            id: null,
            newFlag: true,
            userSecurity: this.selectedItem.security,
            paggingView: [],
            selectedCategoryItem: this.selectedItem
        });
    }

    private gotoNewNews() {
        this.tabService.changeTabName(PAGE_TITLE.news);
        this.navigationService.navigate(`${this.selectedItem.url}/details`, null, null, {
            id: null,
            newFlag: true,
            userSecurity: this.selectedItem.security,
            paggingView: [],
            selectedCategoryItem: this.selectedItem
        });
    }

    public getPageTitle(selectedCategory : OopsCategory): string {
        if (this.selectedItem) {
           return this.getPegeTitleModule();
        } 
        if (!this.selectedItem && selectedCategory) {
            this.selectedItem = selectedCategory;
            return this.getPegeTitleModule();
        }
    }

    private getPegeTitleModule() : string {
        switch (this.selectedItem.name.toLowerCase()) {
            case MODULE_NAME.attribute:
                return PAGE_TITLE.attribute;
            case MODULE_NAME.document_distribution:
                return PAGE_TITLE.document_distribution;
            case MODULE_NAME.news:
                return PAGE_TITLE.news;                
            default:
                return PAGE_TITLE.settings;
        }
    }

    setActiveCatagoryItem(category: OopsCategory) {
        if (category) {
            this.onMenuSelectedFromSetActiveCategory(category).then(() => {
                this.onOpenSearchTab.emit();
            });
        }
    }

}
