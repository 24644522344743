import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { DaterangepickerModule } from 'src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module';
import { DxCheckBoxModule, DxDataGridModule, DxTreeListModule } from "devextreme-angular";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { FreezingTabModule } from 'src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module';

import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { LoadingSpinnerModule } from "src/app/shared/layout/loading-spinner";
import { OopsChoiceValueModule } from 'src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module';

import { PanelsModule } from "src/app/shared/panels/panels.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { UiModule } from 'src/app/shared/ui/ui.module';

import { OrderDetailAccountingComponent } from './order-detail-accounting';
import { OrderDetailAttributeComponent } from './order-detail-attribute';
import { OrderDetailAccountableDocumentListComponent } from './order-detail-accountable-document-list';

import { OrderDetailCommentListComponent, OrderDetailCommentListActionbarComponent, } from './order-detail-comment-list';
import { OrderDetailIndividualDocumentFormComponent, OrderDetailIndividualDocumentListComponent, OrderDetailIndividualDocumentActionbarComponent } from './order-detail-individual-document-list';
import { OrderDetailIndividualListComponent } from './order-detail-individual-list';
import { OrderDetailProductListComponent } from './order-detail-product-list';
import { OrdersDetailServiceListComponent } from './order-detail-service-list';
import { OrderDetailGuardianComponent } from './order-detail-guardian';

import { TreeModule } from 'primeng/tree';
import { CommentModule } from 'src/app/core/components/comment/comment.module';
import { StatusBadgeModule } from 'src/app/shared/ui/forms/inputs/status-badge/status-badge.module';
import { OrderDetailPhoneListComponent, PhoneListActionBarComponent } from './order-detail-phone-list';
import { CommunicationOtherListActionBarComponent, OrderDetailCommunicationOtherListComponent } from './order-detail-communication-other-list';
import { AddressListActionBarComponent, OrderDetailAddressListComponent, OrderDetailAddressFormComponent } from './order-detail-address-list';
import { PhonePrefixSelectionModule } from 'src/app/core/components/phone-prefix-selection';
import { PhoneTypeSelectionModule } from 'src/app/core/components/phone-type-selection';
import { NumberOnlyModule } from 'src/app/shared/ui/forms/inputs/number-only/number-only.module';
import { OrderDetailPhoneFormComponent } from './order-detail-phone-list/order-detail-phone-form/order-detail-phone-form.component';
import { OrderDetailCommunicationOtherFormComponent } from './order-detail-communication-other-list/order-detail-communication-other-form/order-detail-communication-other-form.component';
import { CommunicationOtherTypeSelectionModule } from 'src/app/core/components/communication-other-type-selection';
import { OopsCustomValidatorModule } from 'src/app/core/validators';
import { CountrySelectionModule } from 'src/app/core/components/country-selection/country-selection.module';
import { AddressTypeSelectionModule } from 'src/app/core/components/address-type-selection';
import { DocumentTypeSelectionModule } from 'src/app/core/components/document-type-selection/document-type-selection.module';
import { CalendarIconModule } from 'src/app/core/components/calendar-icon';
import { OrderDetailCommentDetailModule } from '../../order-detail/order-detail-comment/order-detail-comment-detail/order-detail-comment-detail.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { OrderDetailVoucherListComponent } from './order-detail-voucher-list';

@NgModule({
    declarations: [
        AddressListActionBarComponent,
        CommunicationOtherListActionBarComponent,
        OrderDetailAccountingComponent,
        OrderDetailAddressFormComponent,
        OrderDetailAddressListComponent,
        OrderDetailAttributeComponent,
        OrderDetailAccountableDocumentListComponent,
        OrderDetailCommentListActionbarComponent,
        OrderDetailCommentListComponent,
        OrderDetailCommunicationOtherFormComponent,
        OrderDetailCommunicationOtherListComponent,
        OrderDetailIndividualDocumentActionbarComponent,
        OrderDetailIndividualDocumentFormComponent,
        OrderDetailIndividualDocumentListComponent,
        OrderDetailIndividualListComponent,
        OrderDetailPhoneFormComponent,
        OrderDetailPhoneListComponent,
        OrderDetailProductListComponent,
        OrderDetailVoucherListComponent,
        OrdersDetailServiceListComponent,
        OrderDetailGuardianComponent,
        PhoneListActionBarComponent
    ],
    imports: [
        AddressTypeSelectionModule,
        CalendarIconModule,
        CommonModule,
        CommunicationOtherTypeSelectionModule,
        CountrySelectionModule,
        DaterangepickerModule,
        DocumentTypeSelectionModule,
        BsDropdownModule,
        DxCheckBoxModule,
        DxDataGridModule,
        DxTreeListModule,
        FocusingModule,
        FormsModule,
        FreezingTabModule,
        ItemsFoundModule,
        LoadingSpinnerModule,
        OopsChoiceValueModule,
        OrderDetailCommentDetailModule,
        MatIconModule,
        MatMenuModule,
        NgbNavModule,
        NumberOnlyModule,
        OopsCustomValidatorModule,
        PanelsModule,
        PhonePrefixSelectionModule,
        PhoneTypeSelectionModule,
        ReactiveFormsModule,
        Select2Module,
        TreeModule,
        UiModule,
        CommentModule,
        StatusBadgeModule
    ],
    exports: [
        AddressListActionBarComponent,
        CommunicationOtherListActionBarComponent,
        OrderDetailAccountableDocumentListComponent,
        OrderDetailAccountingComponent,
        OrderDetailAddressFormComponent,
        OrderDetailAddressListComponent,
        OrderDetailAttributeComponent,
        OrderDetailCommentListActionbarComponent,
        OrderDetailCommentListComponent,
        OrderDetailIndividualDocumentActionbarComponent,
        OrderDetailCommunicationOtherFormComponent,
        OrderDetailCommunicationOtherListComponent,
        OrderDetailGuardianComponent,
        OrderDetailIndividualDocumentFormComponent,
        OrderDetailIndividualDocumentListComponent,
        OrderDetailIndividualListComponent,
        OrderDetailVoucherListComponent,
        OrderDetailPhoneFormComponent,
        OrderDetailPhoneListComponent,
        OrderDetailProductListComponent,
        OrdersDetailServiceListComponent,
        OrderDetailProductListComponent,
        PhoneListActionBarComponent
    ]
})
export class OrdersDetailShareComponentModule { }