import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationService } from "src/app/shared/utils/navigation";

@Component({
    selector: 'op-insight',
    templateUrl: './insight-management.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InsightManagementComponent {
    constructor(private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.navigationService.navigate("/main/insight/home", null, false);
    }
}