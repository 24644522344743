export interface PriceRuleProductOptionModel {
    priceRuleProductOptionId?: string;
    priceRuleId?: string;
    productCategoryCode?: string;
    productGroupCode?: string;
    productTypeCode?: string;
    productId?: string;
    priceId?: string;
    unitCode: string;
    numberOfUnits?: number;
    displaySequence: number;
}