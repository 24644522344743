export interface OrderDetailHeaderPointOfSaleView {
    bookingReference: string;
    orderDateTime: string;
    bookingOffice: string;
    officeCode: string;
    iataCode: string;
    cityCode: string;
    provider: string;
    userType: string;
    countryCode: string;
    pointOfSaleCurrencyCode: string;
    dutyCode: string;
    orderNumberTypeCode: string;
    orderPointOfSalesId: string;
}