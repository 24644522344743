import { Injectable } from '@angular/core';
import { 
    InsightPassengerAddressModel, 
    InsightPassengerCommunicationOtherModel, 
    InsightPassengerCommunicationPhoneModel, 
    InsightPassengerModel, 
    InsightPassengerNameModel, 
    InvoiceIndividualDetailModel, 
    InvoiceIndividualSearchModel 
} from 'src/app/core/models/individual-model';

@Injectable()
export class InvoiceIndividualDetailMapperService {
    
    public InsightPassengerDetailToInvoiceIndividual(insight: InsightPassengerModel, selectedIndividual: InvoiceIndividualSearchModel): InvoiceIndividualDetailModel {
        let primaryName = this.getPrimaryName(insight.insightPassengerNames);
        let primaryPhone = this.getPrimaryPhone(insight.insightPassengerCommunicationPhones);
        let primaryCommunication = this.getPrimaryCommunicationOther(insight.insightPassengerCommunicationOthers);

        let individual: InvoiceIndividualDetailModel = {
            individualId: insight.individualId,
            firstName: primaryName.firstName,
            lastName: primaryName.lastName,
            titleCode: primaryName.individualTitleCode,
            languageCode: primaryName.languageCode,
            phoneTypeCode: primaryPhone?.communicationPhoneTypeCode,
            phonePrefix: primaryPhone?.communicationPhonePrefix,
            phoneNumber: primaryPhone?.phoneNumber,
            mailAddress: primaryCommunication?.mailAddress,
            InvoiceAddress: this.getInvoiceAddress(insight?.insightPassengerAddresses, selectedIndividual?.individualAddressId)
        }
        return individual;
    }

    private getPrimaryName(names: InsightPassengerNameModel[]): InsightPassengerNameModel {
        return names.find(name => name.primaryFlag === true);
    }

    private getPrimaryPhone(phones: InsightPassengerCommunicationPhoneModel[]): InsightPassengerCommunicationPhoneModel {
        return phones.find(phone => phone.primaryFlag === true);
    }

    private getPrimaryCommunicationOther(communications: InsightPassengerCommunicationOtherModel[]): InsightPassengerCommunicationOtherModel {
        return communications.find(communication => communication.primaryFlag === true);
    }

    private getInvoiceAddress(addresses: InsightPassengerAddressModel[], addressId: string): InsightPassengerAddressModel {
        return addresses.find(address => address.addressId === addressId);
    }
}
