import { 
    OrderDetailCommentView,
    OrderDetailIndividualAddressView,
    OrderDetailIndividualMembershipView,
    OrderDetailIndividualPhoneView 
} from '..';

export interface OrderDetailPartnerView {
    individualId: string;
    orderId: string;
    individualNameId: string;
    lastName: string;
    firstName: string;
    title: string;
    ageGroup: string;
    dateOfBirth: string;
    genderName: string;
    languageCode: string;
    languageName: string;
    nationalityCountryCode: string;
    mailAddress: string;
    phoneNumber: string;
    phoneType: string;
    membershipLevelCode: string;
    membershipLevelName: string;
    comments: OrderDetailCommentView[];
    countryPhonePrefix: string;
    orderPartnerRoleName: string;
    organisationCallName: string;
    orderIndividualPhones: OrderDetailIndividualPhoneView[];
    orderIndividualAddresses: OrderDetailIndividualAddressView[];
    orderIndividualMemberships: OrderDetailIndividualMembershipView[];
    showDetail: boolean;
}