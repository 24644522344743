import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { OopsCategory } from "src/app/core/components/categories/oops-category.model";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { SecurityGroupService } from "src/app/core/services/system-services/security-group.service";

@Component({
    selector: 'op-settings-search',
    templateUrl: './settings-search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class SettingsSearchComponent {
    private readonly ATTRIBUTE_CATEGORY = 'Attribute';
    private readonly DOCUMENTDISTRIBUTION_CATEGORY = 'Document Distribution';
    private readonly DOCUMENT_DISTRIBUTION_SECURITY_CODE = 'DOCUMENTDISTRIBUTION';
    private readonly NEWS_CATEGORY = 'News';

    @Input() selectedCategory: OopsCategory;
    @Input() favoriteCategoryName;
    @Input() executeFlag = true;
    @Input() searchCondition: any;
    @Output() cancelRequest = new EventEmitter();

    userSecurity:SecurityGroupSecurityModel[] = new Array();
    searchMode: boolean = true;
    attributeSecurity: SecurityGroupSecurityModel;
    documentDistributionSecurity: SecurityGroupSecurityModel;
    loadSecurityFinished = false;

    constructor(private securityGroupService: SecurityGroupService,
                private changeDetector: ChangeDetectorRef
    ) {  
        this.getUserSecurity(); 
    }

    private getUserSecurity() {
        this.securityGroupService.getUserSecurityGroupSecurity()
            .subscribe(
                (response: SecurityGroupSecurityModel[]) => {
                    this.userSecurity = response;
                    this.getAttributeSecurity();
                    this.getDocumentDisbutionSecurity();
                    this.loadSecurityFinished = true;
                    this.changeDetector.detectChanges();
                }
            )
    }

    public onCancelRequested() {
        this.cancelRequest.emit();
    }

    public get displayAttributeSearch() {
        return this.selectedCategory?.name == this.ATTRIBUTE_CATEGORY;
    }

    public getAttributeSecurity() {
        let index = this.userSecurity?.findIndex(item => item.securityCode == this.ATTRIBUTE_CATEGORY.toUpperCase());
        this.attributeSecurity = index >= 0 ? this.userSecurity[index] : null;
    }

    public get displayDocumentDistributionSearch() {
        return this.selectedCategory?.name == this.DOCUMENTDISTRIBUTION_CATEGORY;
    }

    public getDocumentDisbutionSecurity() {
        let index = this.userSecurity?.findIndex(item => item.securityCode == this.DOCUMENT_DISTRIBUTION_SECURITY_CODE.toUpperCase());
        this.documentDistributionSecurity = index >= 0 ? this.userSecurity[index] : null;
    }

    public get displayNewsSearch() {
        return this.selectedCategory?.name == this.NEWS_CATEGORY;
    }
}


