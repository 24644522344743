import { Injectable, Type } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RoutesRecognized } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from "rxjs/internal/operators";
import { NavigationModel } from './navigation.model';
import { RouteSnapshotModel } from './route-snapshot.model';
import { TabModel } from '../../../core/models/tab/tab.model';
import { TabService } from '../../../core/utils/tab.service';
import { NavigationItem } from 'src/app/store/navigation';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    private _preloadTabItems: NavigationItem[] = [];
    private _previousTabParam$ = new BehaviorSubject(null);
    constructor(private activatedRoute: ActivatedRoute,
        private router: Router,
        private tabService: TabService) { }

    public mainTabRoutesRecognized(): Observable<NavigationModel> {
        return new Observable((observer) => {
            this.router.events
                .pipe(filter(event => event instanceof RoutesRecognized))
                .subscribe((event: RoutesRecognized) => {

                    const navigation = this.router.getCurrentNavigation();
                    const url = navigation.extras.state ? navigation.extras.state.url : "";
                    const tab = navigation.extras.state ? navigation.extras.state.tab : false;
                    const snapshot = this.getRouteConfigSnapShot(event.state.root.children);

                    let pathStructure = this.cleanPath(url.split('/'));
                    let navigationResult: NavigationModel = {
                        component: this.getComponent(pathStructure, event.state.root.children),
                        name: navigation.extras.state ? navigation.extras.state.name : "",
                        url,
                        tab,
                        tabId: snapshot?.tabId
                    };
                    this.setCurrentTabUrl(snapshot?.tabId, url);
                    observer.next(navigationResult);
                });
        });
    }

    public navigate(url: string, name: string, tab: boolean = false, params: any = null) : Promise<boolean> {
        let snapshot = this.getRouteConfigSnapShot(this.activatedRoute.snapshot.children);
        if (snapshot && !this.tabProcess(tab) && !this.PathIdExist(url)) {
            url = url.replace(snapshot.mainPath, snapshot.mainPath + '/' + snapshot.tabId);
        }
        if (this.tabProcess(tab)) {
            this.fillPreviousTabParams(params, url);
        }
        params = params ?? this.router["lastSuccessfulNavigation"]?.extras?.state?.params;
        return this.router.navigate([url], {
            relativeTo: this.activatedRoute, skipLocationChange: false,
            state: { name, url, tab, params }
        });
    }

    private tabProcess(tab: boolean) {
        return !(tab === null || tab === false);
    }

    private PathIdExist(url: string): boolean {
        const idLength: number = 13;
        const pathParts = url.split('/');
        for (let part of pathParts) {
            if (part.length === idLength && Number(part)) {
                return true;
            }
        }
        return false;
    }

    public getParams(): any {
        return this.router.getCurrentNavigation()?.extras?.state?.params;
    }

    public clearParams() {
        let nav = this.router.getCurrentNavigation();
        nav.extras.state.params = null;
        this.router.navigate([], nav.extras);
    }

    public getQueryParams(): any {
        return this.router?.routerState?.snapshot?.root?.queryParams;
    }

    public getPreviousTabParams(): any {
        let params = this._previousTabParam$.getValue();
        this._previousTabParam$.next(null);
        return params;
    }

    public getComponent(urlElements: string[], activatedRouteSnapshots: ActivatedRouteSnapshot[]): string | Type<any> {

        for (let route of activatedRouteSnapshots) {
            if (this.searchNextLevel(urlElements, route)) {
                if (urlElements.length > 0) {
                    let remandingElement = urlElements.slice(1)
                    return this.getComponent(remandingElement, route.children);
                }
            } else {
                return route.component;
            }
        }
        return null;
    }

    private searchNextLevel(fullPaths: string[], routeSnapshot: ActivatedRouteSnapshot): boolean {

        let path = routeSnapshot.routeConfig?.path;
        if (routeSnapshot.firstChild != null
            && (path == null || path.length == 0 || path.indexOf(fullPaths[0]) >= 0)) {

            return true;
        }
        return false;
    }

    public getRouteConfigSnapShot(activatedRouteSnapshots: ActivatedRouteSnapshot[]): RouteSnapshotModel {
        for (let route of activatedRouteSnapshots) {
            if (route.params["tab"]) {
                let snapshot: RouteSnapshotModel = {
                    tabId: route.params["tab"],
                    routeConfig: route.routeConfig?.path,
                    mainPath: route.url[0].path
                };
                return snapshot;
            } else {
                return this.getRouteConfigSnapShot(route.children);
            }
        }
        return null;
    }

    public cleanPath(fullPaths: string[]) {
        return fullPaths.filter(path => path !== "");
    }

    private setCurrentTabUrl(id: string, url: string) {
        if (!id || !url || url.length === 0) {
            return;
        }
        url = this.examineUrlStructure(url);
        let tabModel: TabModel = {
            active: null,
            component: null,
            id,
            name: null,
            url
        };
        this.tabService.setChangedTabUrl(tabModel);
    }

    private examineUrlStructure(url: string): string {
        const requiredFirstUrlCharacter: string = "/";
        let firstUrlCharacter = url.substring(0, 1);
        if (requiredFirstUrlCharacter !== firstUrlCharacter) {
            return requiredFirstUrlCharacter + url;
        }
        return url;
    }
    public addPreloadTab(routerLink: string, displayName: string, params: any): void {
        this._preloadTabItems.push({
            title: displayName,
            displayName,
            routerLink,
            params
        });
    }
    public loadPreloadTab(): void {
        const timeout = 1000;
        if(!this._preloadTabItems || this._preloadTabItems.length === 0) {
            return;
        }
        let i: number = 0;
        for (let item of this._preloadTabItems) {
            setTimeout(() => {
                this.navigate(item.routerLink, item.title, true, item.params);
              }, i * timeout);
              ++i;
        }
        this._preloadTabItems = [];
    }

    private fillPreviousTabParams(params: any, url: string) {
        let tabId = this.getTabId(url);
        if (tabId) {
            this._previousTabParam$.next({
                tabId: tabId,
                params: params
            })
        }
    }

    private getTabId(url: string) {
        const idLength: number = 13;
        const pathParts = url.split('/');
        for (let part of pathParts) {
            if (part.length === idLength && Number(part)) {
                return part;
            }
        }
    }
}