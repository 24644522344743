export interface OrderDetailServiceView {
    providerCode: string;
    providerName: string;
    serviceCode: string;
    serviceName: string;
    serviceText: string;
    lastName: string;
    firstName: string;
    title: string;
    showDetail: boolean;
    orderServiceId: string;
    serviceOrderId: string;
    partnerOrderId: string;
    individualId: string;
}