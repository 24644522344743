import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { OrdersMapperService } from "../..";
import { OrderDetailProductVoucherView } from "../../views/order-detail-product-voucher.view";
import { Subject } from "rxjs";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'op-order-detail-voucher-list',
    templateUrl: './order-detail-voucher-list.component.html',
    providers: [OrdersMapperService]
})
export class OrderDetailVoucherListComponent implements OnInit, OnDestroy {
    @Input() vouchers: OrderDetailProductVoucherView[];
    @Input("hideNotExist") hideWhenNotExist: boolean = false;
    @Input("showPanelCounter") showPanelCounter: boolean = false;
    @Input("collapeWhenLoad") collapeWhenLoad: boolean = false;

    @ViewChild("panel") panel: any;

    public collapsed: boolean = false;
    public focused: boolean;

    public countNumberOfItems: number = 0;
    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor() {}

    ngOnInit(): void {
        this.checkCollape();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public hidePanel(): boolean {
        if (this.collapsed == true && this.hideWhenNotExist == true) {
            return true;
        }
        return false;
    }

    public collapsedStatus(collapsed: any) {
        this.collapsed = collapsed;
    }

    public togglePanel($event: MouseEvent) {
        this.panel.toggleCollapse($event);
    }

    public showCounter(): boolean {
        return this.showPanelCounter && this.vouchers?.length > 0;
    }

    public getUnderlineClass(rowIndex: number): string {
        if (rowIndex === 0) {
            return "";
        }
        return "order-detail-start-section";
    }

    public fillNumberOfItems(vouchers: OrderDetailProductVoucherView[]) {
        return vouchers.length;
    }

    private checkCollape(): void {
        if (!this.vouchers || this.vouchers.length == 0 || this.collapeWhenLoad) {
            this.collapsed = true;
            return;
        }
        this.collapsed = false;
    }
}