export interface PriceRuleValidityView {
    priceRuleValidityId: string;
    priceRuleAttributeGroupCode: string;
    calendarValidityCode: string;
    dateTimeDimensionCode: string;
    startDateTime: Date;
    endDateTime: Date;
    startValue: number;
    endValue: number;
    mondayFlag: boolean;
    tuesdayFlag: boolean;
    wednesdayFlag: boolean;
    thursdayFlag: boolean;
    fridayFlag: boolean;
    saturdayFlag: boolean;
    sundayFlag: boolean;
    januaryFlag: boolean;
    februaryFlag: boolean;
    marchFlag: boolean;
    aprilFlag: boolean;
    mayFlag: boolean;
    juneFlag: boolean;
    julyFlag: boolean;
    augustFlag: boolean;
    septemberFlag: boolean;
    octoberFlag: boolean;
    novemberFlag: boolean;
    decemberFlag: boolean;
    utcFlag: boolean;
    conditionCode: string;
    displaySequence: number;
}