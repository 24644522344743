export interface ExchangeRateAmountModel {
    exchangeRateId: string;
    baseCurrencyCode: string;
    exchangeCurrencyCode: string;
    factor: number;
    sellExchangeRateAmount: number;
    buyExchangeRateAmount: number;
    validFromDateTime: Date;
    commitByName: string;
    commitDateTime: Date;
}
