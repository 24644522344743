import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { SecurityGroupSecurityModel } from "src/app/core/models/security-model/security-group-security.model";
import { NewsSearchTableComponent } from "./news-search-table/news-search-table.component";
import { PagingDataView } from "src/app/core/views/pagging-data.view";
import { NewsPriorityReferenceService, NewsTypeReferenceService, StatusReferenceService } from "src/app/core/services/system-services";
import { NewsPriorityReferenceModel, NewsTypeReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NewsSearchTableView } from "./shared/views/news-search-table.view";
import { ACTION_STATUS, ActionBarHandlerModel, ActionbarService } from "src/app/shared/ui/actionbar";
import { CancelButtonModel, CopyButtonModel, NewButtonModel, RefreshButtonModel } from "src/app/shared/ui/actionbar/models";
import { takeUntil } from "rxjs/operators";
import { NavigationService } from "src/app/shared/utils/navigation";
import { OopsCategory } from "src/app/core/components/categories";
import { NewsSearchCommandModel } from "src/app/core/models/news-model/news-search-command.model";
import { NewsService } from "src/app/core/services/system-services/news-service";
import { NewsSearchViewModel } from "src/app/core/models/news-model/news-search-view.model";
import { NewsSearchConditionComponent } from "./news-search-condition/news-search-condition.component";
import { DateConverterService } from "src/app/core/utils/date-converter.service";

@Component({
    selector: 'op-news-search',
    templateUrl: './news-search.component.html',
})
export class NewsSearchComponent implements OnInit, AfterViewInit {
    private readonly RECEIVE_ORGANISTION_TYPE_CODE = ["AIRLINE", "CUSTOMS","GDS","GROUNDHANDLER", "IMMIGRATION","OTA","APIS"]
    public filters$ = new BehaviorSubject<NewsSearchCommandModel>(null);
    public pagingDataView: PagingDataView[];
    public newsTableData$ = new BehaviorSubject<NewsSearchTableView[]>(null);
    private unsubscribe$ = new Subject();
    
    public statusReference$ = new BehaviorSubject<StatusReferenceModel[]>(null);
    public newsTypeReference$ = new BehaviorSubject<NewsTypeReferenceModel[]>(null);
    public newsPriorityReference$ = new BehaviorSubject<NewsPriorityReferenceModel[]>(null);
    @Input() userSecurity: SecurityGroupSecurityModel;
    @Input() executeFlag = true;
    @Input() selectedCategory: OopsCategory;
    @Input() searchCondition: NewsSearchCommandModel = null;
    @Output() cancelRequest = new EventEmitter();

    @ViewChild(NewsSearchTableComponent) searchTable: NewsSearchTableComponent;
    @ViewChild(NewsSearchConditionComponent) searchConditionComponent: NewsSearchConditionComponent;
    
    private actionBarHandler = new ActionBarHandlerModel(
        new NewButtonModel(),
        new CopyButtonModel(),
        new CancelButtonModel(),
        new RefreshButtonModel()
    )

    public defaultFilter: NewsSearchCommandModel = {
        newsTypeCode: null,
        newsPriorityCode: null,
        validDateFrom: null,
        validDateTo: null,
        statusCode: "A",
        top: 200
    }

    constructor(private newsService: NewsService,
        private ref: ChangeDetectorRef,
        private statusReferenceService: StatusReferenceService,
        private newsTypeReferenceService: NewsTypeReferenceService,
        private newsPriorityReferenceService: NewsPriorityReferenceService,
        private navigationService: NavigationService,
        private actionbarService: ActionbarService,
        private dateConverterService: DateConverterService) {
    }

    ngAfterViewInit(): void {
        if (this.userSecurity) {
            this.setupSecurity();
        }

        this.actionbarService.updateState(this.actionBarHandler);
        this.actionbarService.action$.pipe(takeUntil(this.unsubscribe$)).subscribe(
            actionId => {
                this.actionbarClick(actionId);
            }
        )
    }

    ngOnInit(): void {
        this.getStatusReferences();
        this.getNewsTypeReferences(); 
        this.getNewsPriorityReferences();
        this.searchDefault();
    }

    private getStatusReferences() {
        this.statusReferenceService.getAll()
            .subscribe(
                (responses: StatusReferenceModel[]) => {
                    this.statusReference$.next(responses); 
                }
            )
    }

    private getNewsTypeReferences() {
        this.newsTypeReferenceService.getAll()
            .subscribe(
                (responses: NewsTypeReferenceModel[]) => {
                    this.newsTypeReference$.next(responses.filter(x => x.newsTypeCode != "SYSTEM")); 
                }
            )
    }

    private getNewsPriorityReferences() {
        this.newsPriorityReferenceService.getAll()
            .subscribe(
                (responses: NewsPriorityReferenceModel[]) => {
                    this.newsPriorityReference$.next(responses); 
                }
            )
    }

    public searchClick(filter: NewsSearchCommandModel) {
        this.search(filter);
    }

    public search(filter: NewsSearchCommandModel) {
        var dynamicDataArray = new Array();
        this.newsService.search(filter)
        .subscribe(
            (responses: NewsSearchViewModel[]) => {
            this.pagingDataView = new Array();
            let filter =  responses.filter(x => x.newsTypeCode != "SYSTEM");
            var index = 1;
            filter.forEach(doc => {
                this.addPaggingView(index, doc.newsId, doc.newsTitle);
                var temp  = {};
                temp["no"] = index;
                temp["newsId"] = doc.newsId;
                temp["newsTypeCode"] = doc.newsTypeCode;
                temp["newsPriorityCode"] = doc.newsPriorityCode;
                temp["newsTitle"] = doc.newsTitle;
                temp["newsSummary"] = doc.newsSummary;
                temp["validDateFrom"] = this.dateConverterService.toDateFormat(doc.validDateFrom);
                temp["validDateTo"] = this.dateConverterService.toDateFormat(doc.validDateTo);
                temp["commitByName"] = doc.commitByName;
                temp["commitDateTime"] = this.dateConverterService.convertDate(doc.commitDateTime);
                temp["statusCode"] = doc.statusCode;
                dynamicDataArray.push(temp);
                index += 1;
            });
            this.newsTableData$.next(dynamicDataArray);
            this.ref.detectChanges();
            }
        );
    }

    public addPaggingView(index: number, id: string, name: string) {
        let pview = new PagingDataView();
        pview.no = index;
        pview.id = id;
        pview.name = name;
        this.pagingDataView.push(pview);
    }

    public onClearFilter() {
        this.filters$.next(Object.assign({}, this.defaultFilter));
        this.newsTableData$.next(null);
        this.searchTable.dataGrid.instance.clearFilter();
    }

    actionbarClick(clickedButton: string) {
        switch (clickedButton) {
            case ACTION_STATUS.back:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.new:
                this.newItem();
                break;
            case ACTION_STATUS.copy:
                this.searchTable.copyItem();
                break;
            case ACTION_STATUS.cancel:
                this.cancelRequest.emit();
                break;
            case ACTION_STATUS.refresh:
                this.search(this.filters$.value);
                break;
        }
    }

    back() {
        this.navigationService.navigate('/main/settings/home', null);
    }

    newItem() {
        let param = {
            id: null,
            attributeName: null,
            newFlag: true,
            userSecurity: this.userSecurity,
            paggingView: [],
            searchCondition: this.filters$.value,
            selectedCategoryItem: this.selectedCategory
        }
        this.navigationService.navigate('/main/settings/news/details', null, null, param);
    }

    editItem(editItem: NewsSearchTableView) {
        if (editItem) {
            let param = {
                id: editItem.newsId,
                attributeName: null,
                newFlag: false,
                userSecurity: this.userSecurity,
                pagingDataView: this.pagingDataView,
                currentIndex: editItem.no,
                searchCondition: this.filters$.value,
                selectedCategoryItem: this.selectedCategory
            }
            this.navigationService.navigate('/main/settings/news/details', null, null, param);
        }
    }

    copyItem(editItem: NewsSearchTableView) {
        if (editItem) {
            let param = {
                id: editItem.newsId,
                attributeName: null,
                newFlag: false,
                copyFlag: true,
                userSecurity: this.userSecurity,
                pagingDataView: this.pagingDataView,
                currentIndex: editItem.no,
                searchCondition: this.filters$.value,
                selectedCategoryItem: this.selectedCategory
            }
            this.navigationService.navigate('/main/settings/news/details', null, null, param);
        }
    }

    private setupSecurity() {
        this.resetActionBarState();
        if (!this.userSecurity?.newFlag) {
            this.actionBarHandler.get(ACTION_STATUS.new).disable();
        }
        this.actionBarHandler.get(ACTION_STATUS.copy).disable();
    }
    
    private resetActionBarState() {
        this.actionBarHandler.get(ACTION_STATUS.new).enable();
        this.actionBarHandler.get(ACTION_STATUS.copy).enable();
    }

    private searchDefault() {
        if (this.searchCondition){
            this.filters$.next(this.searchCondition);
            this.search(this.searchCondition);
        } else {
            this.filters$.next(Object.assign({}, this.defaultFilter));
            this.search(this.defaultFilter);
        }
    }

    public updateStatus() {
        this.searchConditionComponent.search();
    }
}