import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AlertBarService } from 'src/app/shared/layout/alertbar';
import { ActionbarService } from 'src/app/shared/ui/actionbar';
import { ActionBarHandlerModel } from 'src/app/shared/ui/actionbar/actionbar-handler.model';
import { RefreshButtonModel } from 'src/app/shared/ui/actionbar/models';
import { NavigationService } from 'src/app/shared/utils/navigation';
import { InsightCategoriesComponent } from '../insight-categories/insight-categories.component';
import { InsightCategory } from '../insight-categories/insight-category';
import { InsightSearchComponent } from '../insight-search/insight-search.component';
import { INSIGHT_TABS } from './insight-tabs.constant';
import { Subject, Subscription } from 'rxjs';
import { ActionService } from 'src/app/core/utils/action.service';
import { InsightPassengerSearchModel } from 'src/app/core/models/individual-model';
import { ActivityStoreService } from 'src/app/core/utils/activity-store.service';
import { Router } from '@angular/router';

@Component({
    selector: 'op-insight-tabs',
    templateUrl: './insight-tabs.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ActionbarService,
        AlertBarService
    ]
})
export class InsightTabsComponent {
    readonly SETTING_ROUTE_PATH = '/main/insight/';
    readonly DEFAULT_TITLE = 'Insight Management';

    public searchFilter: InsightPassengerSearchModel;
    
    @Input() selectedTab: string = INSIGHT_TABS.categories;

    @Output() selectedTabChange = new EventEmitter<string>();
    @Output() rowSelected = new EventEmitter<InsightCategory>();

    @ViewChild("insightCategory") insightCategory: InsightCategoriesComponent;
    @ViewChild("insightSearch") insightSearch: InsightSearchComponent;

    public actionBarHandler = new ActionBarHandlerModel(
        new RefreshButtonModel()
    );

    private unsubscribe$ = new Subject();
    private _navigationSubscription: Subscription;

    public selectedCategory: InsightCategory;
    public title: String = this.DEFAULT_TITLE;

    public searchFilterId: string;
    public searchExecuteFlag: boolean = true;

    constructor(private navigateService: NavigationService,
        public alertBarService: AlertBarService,
        private actionService: ActionService,
        private activityStoreService: ActivityStoreService,
        private router: Router) {}

    ngOnInit(): void {
        let previousTabParams = this.getPreviousTabParams();
        let params = this.navigateService.getParams();
        this.navigateService.clearParams();
        let tab = params?.selectedTab ?? previousTabParams?.selectedTab;
        this.searchFilterId = params?.id;
        this.searchExecuteFlag = params?.executeFlag ?? true;
        this.searchFilter = params?.searchFilter
        
        if (tab) {
            this.selectedTab = tab;
            this.onSelectedTabChange(this.selectedTab);
        }

        if (this.searchFilterId) {
            this.navigateService.navigate('main/insight/details', "Insight", false, params);
        }
        this.activityStore();
    }

    onTabChange(activeId: string) {
        this.selectedTab = activeId;
        this.actionService.add(activeId, null, null);
        this.onSelectedTabChange(this.selectedTab);
    }

    onMenuSelected(activeId: string) {
        this.selectedTab = activeId.toUpperCase();
        this.onSelectedTabChange(this.selectedTab);
    }

    onRowSelected(item: InsightCategory) {
        this.selectedCategory = item;
    }

    createSelectedItem(name: string) : InsightCategory{
        var item = {} as InsightCategory
        item.name = name;
        item.url = this.SETTING_ROUTE_PATH + name;
        return item;
    }

    onCancelSearchRequested() {
        this.searchFilter = null;
        this.onTabChange(INSIGHT_TABS.categories);
    }

    public onSelectedTabChange(activeTabId: string) {
        if (activeTabId == INSIGHT_TABS.search) {
            if (this.selectedCategory) {
                this.title = this.selectedCategory.name + ' Management';
                return;
            }
        }
        this.title = this.DEFAULT_TITLE;
    }

    ngOnDestroy(): void {
        if (this._navigationSubscription) {
            this._navigationSubscription.unsubscribe();
        }
        this.unsubscribe$.next();
        this.unsubscribe$.unsubscribe();
    }

    private activityStore() {
        this.activityStoreService.add(
            null,
            null,
            null,
            null,
            null,
            null);
    }   
    
    private getPreviousTabParams(): any {
        const tabIdIndex = 3;
        let params = this.navigateService.getPreviousTabParams();
        let tabId = this.router.url.split('/')[tabIdIndex];
        if (params?.tabId == tabId) {
            return  params.params;
        }
        return null;
    }

    
    public displayAlertBar(message: string) {
        if (message) {
            this.alertBarService.show("", message);
        } else {
            this.alertBarService.hide();
        }
    }
}