import { Select2Data } from '../../../../shared/ui/forms/inputs/oops-select2';

export interface OrderDetailAttributeView {
    orderAttributeId: string | string[];
    orderId: string;
    attributeTypeCode: string;
    attributeTypeName: string;
    dimensionUnitCode: string;
    attributeText: string | string[];
    multipleChoiceFlag: boolean;
    showOnNewFlag: boolean;
    requiredFlag: boolean;
    overrideFlag: boolean;
    attributeChoices: Select2Data[];
    readOnlyFlag: boolean;
}