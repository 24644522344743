import { Injectable } from '@angular/core';
import { Globals } from 'src/app/app.global-variable';
import moment, { Moment } from 'moment';
import 'moment-timezone';

@Injectable({
    providedIn: 'root'
})
export class DateConverterService {
    constructor(private globals: Globals) {

    }
    public readonly DATE_FORMAT: string = "YYYY-MM-DD";
    public readonly DATETIME_FORMAT: string = "YYYY-MM-DD hh:mm A";
    public readonly DATETIME_FORMAT_24: string = "YYYY-MM-DD HH:mm";
    public readonly DATETIME_FORMAT_SECOND: string = "YYYY-MM-DD hh:mm:ss A"
    public readonly TIME_FORMAT_24: string = "HH:mm";
    public readonly DATE_TIME_LOCAL: string = "DD MMM YYYY HH:mm:ss";
    public readonly MONTH_DATE_TIME_SECOND_FORMAT: string = "MMM DD, YYYY hh:mm:ss";
    public readonly MIN_DATETIME: string = "0001-01-01T00:00:00Z";
    public readonly TIMEZONE_NAME = 'Asia/Bangkok';
    public readonly DAY_WEEK_MONTH_DATE_TIME_SECOND_FORMAT: string = "ddd, DD MMM YYYY HH:mm:ss";
    public readonly DAY_WEEK_MONTH_DATE_HOUR_MINUTE_FORMAT: string = "ddd, DD MMM YYYY HH.mm";
    public readonly SHORT_DATE_WITH_MONTH_NAME_FORMAT: string = "DDMMMYY";
    public readonly DAY_FORMAT = "ddd"
    public readonly LONG_DATE_STRING_FORMAT: string = "DD MMM YYYY"
    public readonly DAY_WEEK_MONTH_DATE_FORMAT: string = "ddd, DD MMM YYYY";
    public readonly DATE_FORMAT_YEAR_MONTH_DATE: string = "YYYY/MM/DD";
    public readonly DATE_FORMAT_DATE_MOTH_YEAR: string = "DD/MMM/YYYY";
    public readonly MONYH_FORMAT_CHECK: string = "YYYY-MM";
    public readonly DATE_FORMAT_CHECK: string = "YYYY-MM-DD";
    public readonly DATE_FORMAT_YEAR_TO_SECOND = "YYYY-MM-DD HH:mm:ss";
    public readonly DATETIME_UTC_FORMAT: string = 'YYYY-MM-DDTHH:mm:ss.fffZ'
    public readonly DATETIME_ISO_NO_MILLISEC_TIMEZONE = "YYYY-MM-DDTHH:mm:ss"

    public dateTimeToDay(date: Date) {
        if (!date) {
            return null;
        }
        return moment(date).format(this.DAY_FORMAT);
    }

    public dateTime24UTCFormat(date: Date) {
        if (!date) return ''
        return moment.utc(date).format(this.DATE_TIME_LOCAL);
    }

    public dateTime24LocalFormat(date: Date) {
        if (!date) return ''
        return moment.utc(date).utcOffset(moment().utcOffset()).format(this.DATE_TIME_LOCAL);
    }

    public dateTimeShortDateWithMonthNameFormat(date: Date) {
        if (!date) {
            return null;
        }

        return moment.utc(date).format(this.SHORT_DATE_WITH_MONTH_NAME_FORMAT);
    }

    public dateTimeToUTCString(date: Date) {
        if (!date) {
            return "";
        }
        return date.toISOString();
    }

    public toDateFormat(date: Date): string {
        if (!date) {
            return null;
        }
        return moment(date).format(this.DATE_FORMAT);
    }
    public toDateFormatFromString(date: string) {
        return this.convertDateString(new Date(date), this.DATE_FORMAT);
    }
    public toDateFormatOrNull(date: string) {
        if (!date) {
            return null;
        }
        let value = this.convertDateString(new Date(date), this.DATE_FORMAT);
        return !value ? null : value;
    }
    public convertDate(dateValue: Date) {
        if (!dateValue || dateValue.toString() == this.MIN_DATETIME) {
            return "";
        }
        return moment(dateValue).format(this.DATETIME_FORMAT);
    }

    public convertDateTime(dateValue: Date) {
        if (dateValue) {
            return moment(dateValue).format(this.DATETIME_FORMAT_SECOND);
        }
    }

    public convertDateTime24(dateValue: Date) {
        if (!dateValue || this.MIN_DATETIME.startsWith(dateValue.toString())) {
            return "";
        }
        return moment(dateValue).format(this.DATETIME_FORMAT_24);
    }

    public convertDateTime24UTC(dateValue: Date) {
        if (!dateValue || this.MIN_DATETIME.startsWith(dateValue.toString())) {
            return "";
        }
        return moment(dateValue).utc().format(this.DATETIME_FORMAT_24);
    }

    public convertTime24(dateValue: Date) {
        if (dateValue) {
            return moment(dateValue).format(this.TIME_FORMAT_24);
        }
        return "";
    }
    public convertTime24UTC(dateValue: Date) {
        if (dateValue) {
            return moment(dateValue).utc().format(this.TIME_FORMAT_24);
        }
        return "";
    }

    public convertTime24ByTimeZone(dateValue: Date, utcOffsetMinute: number) {
        if (dateValue) {
            return moment(dateValue).utcOffset(utcOffsetMinute).format(this.TIME_FORMAT_24);
        }
        return "";
    }

    public convertMinDateWithTime(timeValue: string): any {
        if (timeValue) {
            var timeValueArr = timeValue.split(':');
            var hours = parseInt(timeValueArr[0]);
            var minus = parseInt(timeValueArr[1]);
            var d = new Date(null, null, null, hours, minus, 0);
            return moment(d).format(this.DATETIME_FORMAT_24);
        }
    }

    public convertDateString(date: Date, format: string) {
        if (!date || date.toString() == '0001-01-01T00:00:00') {
            return "";
        }
        return moment(date).format(format)
    }

    public convertNumberToMinDateWithTime(time: number): Date {
        if (!time) {
            return null;
        }
        let timeArr = time.toString().split('.');
        let h = parseInt(timeArr[0]);
        let m = 0;
        if (timeArr[1]) {
            m = parseInt(timeArr[1]);
        }
        let d = new Date(1899, 11, 31);
        d.setUTCDate(31);
        d.setUTCHours(h, m, 0);
        return d;
    }

    public calculateAgeForAgeGroup(startDay: Date, endDay: Date): number {
        var startYear = startDay.getFullYear();
        var endYear = endDay.getFullYear();

        var age = endYear - startYear;

        var startMonth = startDay.getMonth();
        var endMonth = endDay.getMonth();

        if (endMonth > startMonth) {
            return age + 0.5;
        }

        if (startMonth > endMonth) {
            return age - 0.5;
        }

        var startDate = startDay.getDate();
        var endDate = endDay.getDate();

        if (endDate > startDate) {
            return age + 0.5;
        }

        if (startDate > endDate) {
            return age - 0.5;
        }

        return age;
    }

    public calculateAge(dateOfBirth: any, referenceDate: any): number {
        const mDateOfBirth = moment(dateOfBirth);
        const mReferenceDate = moment(referenceDate);
        if (!mDateOfBirth.isValid() || !mReferenceDate.isValid()) {
            return null;
        }

        mDateOfBirth.startOf('day');
        mReferenceDate.startOf('day');

        return this.calculateAgeForAgeGroup(mDateOfBirth.toDate(), mReferenceDate.toDate());
    }

    public monthDateTimeFormat(date: string) {
        return this.convertDateString(new Date(date), this.MONTH_DATE_TIME_SECOND_FORMAT);
    }
    public dayWeekMonthDateTimeFormat(date: string) {
        return this.convertDateString(new Date(date), this.DAY_WEEK_MONTH_DATE_TIME_SECOND_FORMAT);
    }
    public convertDateToFormat(datetime: Date, format: string): string {
        if (!datetime) {
            return '';
        }
        return moment(datetime).tz(this.TIMEZONE_NAME).format(format);
    }

    public convertDateStringToFormat(datetime: string, oldFormat: string, displayFormat): string {
        if (!datetime) {
            return '';
        }
        return moment(datetime, oldFormat).tz(this.TIMEZONE_NAME).format(displayFormat);
    }

    public convertToSystemTimestamp(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.TIMESTAMP);
    }

    public convertToSystemDateTime(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.DATETIME);
    }

    public convertToSystemLongDate(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.LONGDATE);
    }

    public convertToSystemLongTime(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.LONGTIME);
    }

    public convertToSystemShortDate(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.SHORTDATE);
    }

    public convertToSystemShortTime(datetime: Date) {
        return this.convertDateToFormat(datetime, this.globals.SHORTTIME);
    }

    public convertNumberMinuteToMinDateWithTime(time: number): Date {
        if (!time) {
            return null;
        }
        let timeArr = time.toString().split('.');
        let m = parseInt(timeArr[0]);
        let s = 0;
        if (timeArr[1]) {
            s = parseInt(timeArr[1]);
        }
        let d = new Date(1899, 11, 31);
        d.setUTCDate(31);
        d.setUTCHours(0, m, s);
        return d;
    }

    public convertMinDateTimeToMinute(datetime: Date): number {
        if (!datetime) {
            return null;
        }
        let startDate = new Date(1899, 11, 31);
        var diffInSeconds = Math.abs(datetime.getTime() - startDate.getTime()) / 1000;
        const d = Math.floor(diffInSeconds / 60);
        return d;
    }

    public convertDateTimeToHour(datetime: Date): number {
        if (!datetime) {
            return null;
        }
        return (Math.floor(this.convertMinDateTimeToMinute(datetime) / 60));
    }

    public convertTimeStringToMinDateWithTime(timeStr: string, timeStringFormat: string): Date {
        if (!timeStr || !timeStringFormat) {
            return null;
        }
        let m = moment(timeStr, timeStringFormat);
        let date = m.toDate();
        return new Date(Date.UTC(1899, 11, 31, date.getHours(), date.getMinutes()));
    }

    public convertToUtcDate(value: string): Date {
        if (!value) {
            return null;
        }
        let date = new Date(value);
        return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
    }

    public getDateOnly(value: Date): Date {
        return new Date(value.getFullYear(), value.getMonth(), value.getDate());
    }

    public convertLongDateString(date: Date) {
        return this.convertDateString(date, this.LONG_DATE_STRING_FORMAT);
    }
    public convertTimeZone(utcDateTime: Date, timeZoneString: string): Date {
        return moment(utcDateTime).tz(timeZoneString).toDate();
    }
    public convertTimeZoneSystemShortTime(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.globals.SHORTTIME);
    }
    public convertUtcOffsetSystemShortTime(utcDateTime: Date, offsetMinute: number) {
        return moment(utcDateTime).utcOffset(offsetMinute).format(this.globals.SHORTTIME);
    }
    public convertTimeZoneDayWeekMonthDateTime(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.DAY_WEEK_MONTH_DATE_TIME_SECOND_FORMAT);
    }
    public convertUtcOffsetDayWeekMonthDateTime(utcDateTime: Date, utcOffsetMinute: number) {
        return moment(utcDateTime).utcOffset(utcOffsetMinute).format(this.DAY_WEEK_MONTH_DATE_TIME_SECOND_FORMAT);
    }
    public convertTimeZoneDayWeekMonthDateHourMinute(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.DAY_WEEK_MONTH_DATE_HOUR_MINUTE_FORMAT);
    }
    public convertUtcOffsetDayWeekMonthDateHourMinute(utcDateTime: Date, utcOffsetMinute: number) {
        return moment(utcDateTime).utcOffset(utcOffsetMinute).format(this.DAY_WEEK_MONTH_DATE_HOUR_MINUTE_FORMAT);
    }
    public convertTimeZoneShortDate(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.globals.SHORTDATE);
    }
    public convertUtcOffsetShortDate(utcDateTime: Date, utcOffsetMinute: number) {
        return moment(utcDateTime).utcOffset(utcOffsetMinute).format(this.globals.SHORTDATE);
    }
    public convertTimeZoneShortTime(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.globals.SHORTTIME);
    }
    public convertUtcOffsetShortTime(utcDateTime: Date, utcOffsetMinute: number) {
        return moment(utcDateTime).utcOffset(utcOffsetMinute).format(this.globals.SHORTTIME);
    }
    public convertTimeZoneDayWeekMonthDate(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.DAY_WEEK_MONTH_DATE_FORMAT);
    }
    public convertUtcOffsetDayWeekMonthDate(utcDateTime: Date, utcOffsetMinute: number) {
        return moment(utcDateTime).utcOffset(utcOffsetMinute).format(this.DAY_WEEK_MONTH_DATE_FORMAT);
    }
    public convertTimeZoneDateTime24(utcDateTime: Date, timeZoneString: string) {
        return moment(utcDateTime).tz(timeZoneString).format(this.DATETIME_FORMAT_24);
    }
    public convertUtcOffsetDateTime24(utcDateTime: Date, offsetMinute: number) {
        return moment(utcDateTime).utcOffset(offsetMinute).format(this.DATETIME_FORMAT_24);
    }
    public convertOffsetDate(utcDateTime: Date, offsetMinute: number) {
        return moment(utcDateTime).utcOffset(offsetMinute).format(this.DATE_FORMAT);
    }
    public convertOffsetDateTime(utcDateTime: Date, offsetMinute: number) {
        return moment(utcDateTime).utcOffset(<any>false).add(offsetMinute, 'minutes').format(this.DATETIME_FORMAT);
    }
    public getUtcDateTime() {
        return moment().utcOffset(<any>false).format(this.DATETIME_FORMAT);
    }
    public convertDayWeekMonthDate(utcDateTime: Date) {
        return moment(utcDateTime).format(this.DAY_WEEK_MONTH_DATE_FORMAT);
    }

    public toDateFormatYearMonthDate(date: Date): string {
        if (!date) {
            return null;
        }
        return moment(date).format(this.DATE_FORMAT_YEAR_MONTH_DATE);
    }

    public toDateFormatDateMonthYear(date: Date): string {
        if (!date) {
            return null;
        }
        return moment(date).format(this.DATE_FORMAT_DATE_MOTH_YEAR);
    }
    public getDayOfWeek(value: Date): number {
        const dateValue = new Date(Date.UTC(value.getFullYear(),
            value.getMonth(),
            value.getDate()));
        return dateValue.getUTCDay();
    }
    public toDate(dateStr: string, format: string): Date {
        if (!dateStr) {
            return null;
        }
        return moment(dateStr, format).toDate();
    }

    public toDateFromUtc(value: any): Date {
        if (!value) return null;
        if (value instanceof Date) return value;
        return this.toDate(value, this.DATETIME_UTC_FORMAT);
    }

    public checkDateFormat(value: string, format: string) {
        let formatCheck = this.DATE_FORMAT_CHECK;
        if (format == 'MONTH') {
            formatCheck = this.MONYH_FORMAT_CHECK;
        }
        return moment(value, formatCheck, true).isValid();
    }

    public formatDateYearToSecond(value?: Date): string {
        if (!value) return '';
        return moment(value).format(this.DATE_FORMAT_YEAR_TO_SECOND);
    }

    public stringToMomentDate(dateString: string): Moment {
        return moment(dateString);
    }

    public toMoment24UtcDate(date: Date): Moment {
        return moment(this.dateTime24UTCFormat(date));
    }

    public dateStringToDateWithoutTime(dateString: string): Date {
        let date = new Date(dateString);
        return this.dateToDateWithoutTime(date);
    }
    public dateToDateWithoutTime(date: Date): Date {
        date.setHours(0, 0, 0, 0);
        return date
    }

    
}
