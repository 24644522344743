export interface OrderDetailProductChangeView {
    showChangeForm: boolean;
    departureAirportCode: string;
    arrivalAirportCode: string;
    departureDate: string;
    serviceCategoryCode: string;
    nextSearchDate: Date;
    calendarOption: any;
    showError: boolean;
    showChangeSeat: boolean;
}