import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { cloneDeep } from "lodash";
import { select2SearchNewsType, select2SearchNewsPriority, select2SearchStatusType, dateOption } from "../shared/select2-configuration";
import { NewsPriorityReferenceModel, NewsTypeReferenceModel, StatusReferenceModel } from "src/app/core/models/reference-model/reference-general-model";
import { NewsSearchCommandModel } from "src/app/core/models/news-model/news-search-command.model";

@Component({
    selector: 'op-news-search-condition',
    templateUrl: './news-search-condition.component.html',
})
export class NewsSearchConditionComponent {
    public blurAfterSelected: boolean = true;
    public focused: boolean;
    public collapsed: boolean;

    public newsTypeOption: any;
    public newsPriorityOption: any;
    public statusOption: any;
    public searchDateOption = cloneDeep(dateOption);
    public searchDateToOption = cloneDeep(dateOption);

    public validDateFrom: any;
    public validDateTo: any;

    @Input() filter: NewsSearchCommandModel = null;        
    @Input() newsTypeReferences: NewsTypeReferenceModel[];    
    @Input() newsPriorityReferences: NewsPriorityReferenceModel[];    
    @Input() statusReferences: StatusReferenceModel[];    
    @Output() searchClick = new EventEmitter();
    @Output() clearSearchFilter = new EventEmitter();

    constructor () {
        this.newsTypeOption = select2SearchNewsType;
        this.newsPriorityOption = select2SearchNewsPriority;
        this.statusOption = select2SearchStatusType;
    }

    public clearCondition() {
        this.validDateFrom = null;
        this.validDateTo = null;
        this.clearSearchFilter.emit();
    }

    public search() {
        this.searchClick.emit(this.filter);
    }
    
    public getConditionValue(): NewsSearchCommandModel {
        return this.filter;
    }

    public onValueChange(value: any, fieldName: string) {
        this.filter[fieldName] = value;
    }

    
    @HostListener("window:keydown", ["$event"])
    public keyDown(event) {
        const enterKeyCode = 13;
        switch (event.keyCode) {
            case enterKeyCode:
                this.search();
                break;
            default:
                break;
        }
    }

    public dateFromChange(value: string) {
        this.validDateFrom = value;
        this.filter.validDateFrom = this.validDateFrom;
        this.searchDateToOption = cloneDeep(dateOption);
        if(value){
            this.searchDateToOption.minDate = value;
        }
    }

    public dateToChange(value: string) {
        this.validDateTo = value;
        this.filter.validDateTo = this.validDateTo;
    }
}