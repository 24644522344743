import { Injectable } from '@angular/core';

declare var moment: any;
@Injectable({
    providedIn: 'root'
})
export class StringHelperService {

    public undefinedToEmpty(value: string) {
        if (!value) {
            return "";
        }
        return value;
    }

    public stringToBoolean(stringValue: string): boolean {
        try {
            return JSON.parse(stringValue);
        }
        catch (e) {
            return false;
        }
    }
    public createQueryParamFromArray(name: string, params: string[]) {
        let queryParam = "";
        if (params?.length) {
            for (let param of params) {
                queryParam += param == params[0] ? '?' : '&';
                queryParam += `${name}=${param}`;
            }
        }
        return queryParam;
    }
    public extractNumberFromString(stringValue: string): number {
        let numberString = stringValue.replace(/^.*?(\d+).*/, '$1');
        if (numberString) {
            return Number(numberString);
        }
        return null;
    }

    public NewGuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }

    public nullIfEmpty(str: string) {
        if (!str) {
            return null;
        }
        return str;
    }

    public validateNullAmountDecimal(amount: number): string {
        if (!amount) {
            return "0.00";
        }
        return this.amountString(amount);
    }

    public validateNullAmount(amount: number): string {
        if (!amount) {
            return "0";
        }
        return amount.toString();
    }

    public amountString(amount: number, numberOfDecimal: number = 2, numberSeperator: string = ',') : string {
        if (amount === undefined || amount === null) {
            return "";
        }
        return amount.toFixed(numberOfDecimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + numberSeperator);
    }

    public nullValueString(str: string) {
        if (!str) {
            return "NULL";
        }
        return str;
    }
    public getCurrencySymbol (locale, currency) {
        return (0).toLocaleString(
          locale,
          {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }
        ).replace(/\d/g, '').trim()
      }
    
    public convertAllEmptyStringToNull(obj: any): any {
        for (const key of Object.keys(obj)) {
            if (typeof obj[key] !== 'string') continue;
            obj[key] = this.nullIfEmpty(obj[key]);
        }
        return obj;
    }

    public numberStringToNumber(str: string): number {
        if (str == null) {
            return 0;
        }
        return parseFloat(str.replace(/,/g, ''));
    }
}