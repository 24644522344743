import { OrderDetailProductChangeAvailabilityDateView } from "./order-detail-product-change-availability-date.view";

export interface OrderDetailProductChangeAvailabilityView {
    departureAirportCode: string;
    departureAirportName: string;
    arrivalAirportCode: string;
    arrivalAirportName: string;
    departureDate: string;
    activeTabId: string;
    flightDateRanges: OrderDetailProductChangeAvailabilityDateView[];
    selectedOfferIds: string[];
}