export interface OrderDetailPaymentView {
    ledgerId: string;
    paymentId: string;
    formOfPaymentTypeCode: string;
    formOfPaymentTypeName: string;
    formOfPaymentCode: string;
    formOfPaymentName: string;
    ledgerTransactionCode: string;
    transactionCurrencyCode: string;
    exchangeRate: string;
    ledgerAmount: string;
    balanceAmount: string;
    documentNumber: string;
    paymentDate: string;
    paymentDateTime: string;
    paymentStatusCode: string;
    paymentStatusName: string;
    paymentPartnerOrderId: string;
    lastName: string;
    firstName: string;
    organisationCallName: string;
    paymentOrganisationCallName: string;
    transactionReference: string;
    paymentText: string;
    showDetail: boolean;  
    paymentNumberDisplay: string;
    issueDateTime: string;
    voidDateTime: string;
    commitName: string;
    individualTitleName: string;
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
    state: string;
    province: string;
    city: string;
    countryName: string;
    showDocumentPayment: boolean;
    validFromDate: string;
    validToDate: string;
    voucherNumberDisplay: string;
    productTypeName: string;
    currencyCode: string;
    voucherAmount: string;
    voucherPercent: string;
}