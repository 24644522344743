import { VehicleConfigurationCompartmentModel } from "./vehicle-configuration-compartment/vehicle-configuration-compartment.model";
import { VehicleConfigurationProductModel } from "./vehicle-configuration-product/vehicle-configuration-product.model";
import { VehicleConfigurationInventoryModel } from "./vehicle-configuration-inventory/vehicle-configuration-inventory.model";

export interface VehicleConfigurationModel {
    vehicleConfigurationId: string;
    vehicleConfigurationCode: string;
    vehicleConfigurationName: string;
    statusCode: string;
    commitByName: string;
    commitDateTime: Date;
    vehicleConfigurationCompartments: VehicleConfigurationCompartmentModel[];
    vehicleConfigurationProducts: VehicleConfigurationProductModel[];
    vehicleConfigurationInventories: VehicleConfigurationInventoryModel[];
}