import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../../authentication/auth.service";
import { ApiServiceBase } from "../../base/api-service-base";
import { NewsPriorityReferenceModel } from "../../models/reference-model/reference-general-model/news-priority-reference.model";

@Injectable({
    providedIn: 'root'
})
export class NewsPriorityReferenceService extends ApiServiceBase {
    private readonly REFERENCE_PATH = '/system/newspriorityreferences';

    constructor(
        httpClient: HttpClient,
        authService: AuthService) {
        super(httpClient, authService);
    }

    public getAll(): Observable<NewsPriorityReferenceModel[]> {
        return this.httpGet<NewsPriorityReferenceModel[]>(this.REFERENCE_PATH, null)
            .pipe(
                map(res => res.body)
            )
    }
}