import { Select2Data } from "src/app/shared/ui/forms/inputs/oops-select2"

export interface OrderSearchCriteria extends OrderStandardSearchCriteria {
    searchFilter?: string,
    publicFilterFlag?: boolean,
    myOrders?: boolean,
    filterStatusCode?: string,
    advanceSearch?: OrderAdvanceSearchCriteria | string
}

export interface OrderStandardSearchCriteria {
    orderNumber?: string
    orderDateFrom?: string | null
    serviceDateFrom?: string | null
    originLocationId?: string | null
    originLocationName?: string | null
    originLocationSave?: Select2Data[];
    destinationLocationId?: string | null
    destinationLocationName?: string
    destinationLocationSave?: Select2Data[];
    productNumber?: string
    individual?: string
    organisationId?: string | null
    groupName?: string
    orderSalesStatusCode?: string
    orderPaymentStatusCode?: string
    individualId?: string
}

export interface OrderAdvanceSearchCriteria {
    // order
    orderTypeCode: string
    orderBy: string
    orderDateFrom?: string
    orderDateTo?: string
    orderDateType: string
    orderDateCondition: string
    orderDeliveryStatusCode: string
    salesCurrencyCode: string
    numberOfUnitsFrom?: string
    numberOfUnitsTo?: string
    numberOfUnitsCondition?: string
    accountableDocumentNumber: string
    supplierSystemCode: string
    supplierSystemCountry: string
    searchUsageTypeCode: string

    // product
    productCategoryCode: string
    productGroupCode: string
    productTypeCode: string
    productName: string
    serviceDateFrom?: string
    serviceDateTo?: string
    productDateType: string
    productDateCondition: string
    productSalesStatusCode: string
    productDeliveryStatusCode: string
    productPaymentStatusCode: string
    productBookingStatusCode: string
    productProviderId: string
    productSupplierId: string

    // individual
    firstName: string
    lastName: string
    email: string
    phone: string
    identityDocumentTypeCode: string
    identityDocumentNumber: string

    // payment
    formOfPaymentType: string
    formOfPaymentId: string
    formOfPayment: string
    documentNumber: string
    paymentDateFrom?: string
    paymentDateTo?: string
    paymentDateType: string
    paymentDateCondition: string
}
