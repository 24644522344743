import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UiModule } from "src/app/shared/ui/ui.module";
import { Select2Module } from "src/app/shared/ui/forms/inputs/oops-select2/select2.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxTemplateModule } from "devextreme-angular";
import { FocusingModule } from "src/app/shared/ui/forms/inputs/focusing.module";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { PanelsModule } from "src/app/shared/panels/panels.module";
import { NavigationIndexModule } from "src/app/shared/layout/navigation-index";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ItemsFoundModule } from "src/app/core/pipes/items-found/items-found.module";
import { StatusColorDropdownModule } from "src/app/shared/ui/forms/inputs/status-color-dropdown/status-color-dropdown.module";
import { FreezingTabModule } from "src/app/shared/ui/forms/inputs/freezing-tab/freezing-tab.module";
import { CalendarIconModule } from "src/app/core/components/calendar-icon";
import { DaterangepickerModule } from "src/app/shared/ui/forms/inputs/oops-daterangepicker/daterangepicker.module";
import { NumberOnlyModule } from "src/app/shared/ui/forms/inputs/number-only/number-only.module";
import { OopsChoiceValueModule } from "src/app/shared/ui/forms/inputs/oops-choice-value/oops-choice-value.module";
import { OopsCustomValidatorModule } from "src/app/core/validators";
import { AlertBarModule } from "src/app/shared/layout/alertbar";
import { NewsContentComponent } from "./news-content.component";
import { NewsSearchComponent } from "./news-search/news-search.component";
import { NewsTabsComponent } from "./news-tabs/news-tabs.component";
import { NewsSearchTableComponent } from "./news-search/news-search-table/news-search-table.component";
import { NewsSearchConditionComponent } from "./news-search/news-search-condition/news-search-condition.component";
import { PriorityColorModule } from "src/app/shared/ui/forms/inputs/priority-color/priority-color.module";


@NgModule({
    declarations: [
        NewsContentComponent,
        NewsSearchComponent,
        NewsTabsComponent,
        NewsSearchTableComponent,
        NewsSearchConditionComponent
    ],
    imports: [
        AlertBarModule,
        BsDropdownModule,
        CalendarIconModule,
        CollapseModule,
        CommonModule,
        DaterangepickerModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxDataGridModule,
        DxTemplateModule,
        FreezingTabModule,
        FocusingModule,
        FormsModule,
        ItemsFoundModule,
        MatIconModule,
        MatMenuModule,
        NumberOnlyModule,
        NavigationIndexModule,
        NgbNavModule,
        OopsChoiceValueModule,
        OopsCustomValidatorModule,
        PanelsModule,
        PriorityColorModule,
        ReactiveFormsModule,
        Select2Module,
        StatusColorDropdownModule,
        UiModule
    ],
    providers: [],
    exports: [
        NewsContentComponent,
        NewsSearchComponent
    ]
})

export class NewsContentModule { }
