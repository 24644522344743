export interface TransportOperatingProductViewModel {
    productId: string;
    parentProductId: string;
    operatingProductSeriesId: string;
    localDateTime: Date;
    localDay: string;
    localTime: Date;
    locationIdFrom: string;
    locationCodeFrom: string;
    locationNameFrom: string;
    locationIdTo: string;
    locationCodeTo: string;
    locationNameTo: string;
    booked: number;
    avai: number;
    productOperatingStatusName: string;
    locationIdLoadFrom: string;
    locationCodeLoadFrom: string;
    locationNameLoadFrom: string;
    locationIdLoadTo: string;
    locationCodeLoadTo: string;
    locationNameLoadTo: string;
    sellCapacity: number;
}