export interface OrderShoppingCartProductDetailView {
    categoryName: string;
    productGroupName: string;
    productNumber: string;
    productName: string;
    date: string;
    departureAirport: string;
    arrivalAirport: string;
    departueTime: string;
    arrivalTime: string;
    serviceCategory: string;
    salesStatus: string;
    totalAmount: string;
}