import { OrderDetailProductChangeView } from '..';
import { OrderDetailProductCommentView } from './order-detail-product-comment.view';
import { OrderDetailProductVoucherView } from './order-detail-product-voucher.view';
export interface OrderDetailProductListView {
    orderId: string;
    productId: string;
    productCategoryCode: string;
    productCategoryName: string;
    productGroupCode: string;
    productGroupName: string;
    orderSalesStatusName: string;
    orderDeliveryStatusName: string;
    orderPaymentStatusName: string;
    serviceCategoryCode: string;
    serviceCategoryName: string;
    currencyCode: string;
    ownerProviderCode: string;
    ownerProviderNumber: string;
    providerCode: string;
    providerNumber: string;
    date: string;
    std: string;
    sta: string;
    dayChange?: number;
    departureCode: string;
    departureName: string;
    arrivalCode: string;
    arrivalName: string;
    comments: OrderDetailProductCommentView[];
    productAmount: string;
    paymentAmount: string;
    outstandingBalance: string;
    freetext: string;
    productNumber: string;
    productName: string;
    productTypeCode: string;
    productTypeName: string;
    showDetail: boolean
    seatNumber: string;
    orderSalesStatusCode: string;
    orderDeliveryStatusCode: string;
    orderPaymentStatusCode: string;
    commitDateTime: string;
    commitByName: string;
    noneTransportValue: string;
    noneTransportValuePercentage: boolean;
    noneTransportValueCurrencyCode: string;
    noneTransportValueCurrencyName: string;
    orderProductText: string;
    productItemOutstandingBalance: string;
    numberOfUnits: string;
    productChangeData: OrderDetailProductChangeView;
    individualIds: string[];
    productOptionFlag: number;
    optionProductId: string;
    vouchers: OrderDetailProductVoucherView[];
    priceRuleDisplayName: string;    
}
