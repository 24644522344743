import { PriceAmountView } from ".";

export interface PriceIndividualView {
    priceIndividualId: string;
    referencePriceIndividualId: string;
    individualAgeGroupCode: string;
    inRelationToIndividualAgeGroupCode: string;
    individualSocialTypeCode: string;
    arithmeticOperator: string;
    amount: PriceAmountView;
}